import { isPlatform } from '@ionic/core';

import axios from '@/services/axios';
import type {
  AuthFormModel,
  ResponseNetworkLinkModel,
  ResponseErrorModel,
  ResponseNetworksModel,
  TokenRequestBody,
  ResponseAuthTokenModel,
  ResponseAuthHomeCodeModel,
} from '@/types';
import { isResponseErrorGuard } from '@/helpers/guards';
import { buildAxiosError, buildErrorResponse } from '@/services/builders';
import { getAdditionalHeaders, getBaseUrl, getCommonHeaders } from '@/services/getters';
import { handleSessionTerminate } from '@/services/handlers';
import { OAuthClientTypeEnum } from '@/enums';

export class AuthApiService {
  private clientSecret: string = isPlatform('ios')
    ? import.meta.env.VITE_AXIOS_CLIENT_SECRET_IOS
    : import.meta.env.VITE_AXIOS_CLIENT_SECRET_WEB;

  private clientType = OAuthClientTypeEnum.ApiCore; // Temporary fix

  async oauth(): Promise<ResponseNetworkLinkModel | ResponseErrorModel> {
    return axios.get(`/oauth/link?clientSecret=${this.clientSecret}&type=${this.clientType}`);
  }
  async networks(params: AuthFormModel): Promise<ResponseNetworksModel | ResponseErrorModel> {
    return axios.post(`/oauth/networks`, { ...params, clientSecret: this.clientSecret });
  }
  async homeCode(params: AuthFormModel): Promise<ResponseAuthHomeCodeModel | ResponseErrorModel> {
    return axios.post('/oauth/homeCode', { ...params, clientSecret: this.clientSecret });
  }
  // async token(params: AuthTokenModel): Promise<ResponseAuthTokenModel | ResponseErrorModel> {
  //   return axios.post('/oauth/token', { ...params, clientSecret: this.clientSecret });
  // }
  async token(
    body: Omit<TokenRequestBody, 'clientSecret'>,
    silent?: boolean
  ): Promise<ResponseAuthTokenModel | ResponseErrorModel> {
    try {
      const url = `${getBaseUrl()}/oauth/token`;

      const b: TokenRequestBody = {
        clientSecret: this.clientSecret,
        ...body,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...getCommonHeaders(),
          ...getAdditionalHeaders(),
        },
        body: JSON.stringify(b),
      });

      if (!response.ok) {
        const error = await response.json();
        if (!isResponseErrorGuard(error)) {
          const message = 'Unexpected error response model';
          throw new Error(message);
        }

        const message = error.errorMessages[0].errors[0] || 'Failed to perform token fetch';
        throw new Error(message);
      }

      const responseToken = await response.json();
      return responseToken;
    } catch (e: unknown) {
      const message = e instanceof Error ? e.message : 'Error while fetching token';
      const error = buildAxiosError(e);
      await handleSessionTerminate(error, message, silent);
      return buildErrorResponse(error);
    }
  }
}

import { GroupsTypeEnum } from '@/enums';
import type {
  GroupModel,
  MediaModel,
  GroupInformation,
  GroupsIdsModel,
  GroupsIdsUserModel,
  GroupShortModel,
} from '@/types';

export const defaultGroup: GroupModel = {
  accessType: 0,
  adminIds: [],
  createdAt: '',
  createdByUserID: 0,
  description: '',
  id: 0,
  isMandant: false,
  isOfficial: false,
  mainAlias: '',
  avatar: {
    url: null,
    width: null,
    height: null,
  } as MediaModel,
  cover: {
    url: null,
    width: null,
    height: null,
  } as MediaModel,
  information: {
    text: '',
  } as GroupInformation,
  stats: { members: 0, messages: 0 },
  title: '',
  type: GroupsTypeEnum.Public,
  url: '',
  webUrl: '',
  showMembers: true,
  showInformation: true,
  enableTasks: true,
  showDocs: true,
  enableLikes: true,
  enableComments: true,
  enableShared: true,
};

export const defaultShortGroup: GroupShortModel = {
  id: 0,
  mainAlias: '',
  title: '',
  type: GroupsTypeEnum.Public,
  image: {
    url: null,
    width: null,
    height: null,
  } as MediaModel,
};

export const defaultGroupsIds: GroupsIdsModel = {
  all: {
    loadMoreUrl: null,
    ids: [],
  },
  search: {
    loadMoreUrl: null,
    ids: [],
  },
  byUser: [] as GroupsIdsUserModel[],
  canPost: {
    loadMoreUrl: null,
    ids: [],
  },
  myGroups: {
    loadMoreUrl: null,
    ids: [],
  },
  isAdmin: {
    loadMoreUrl: null,
    ids: [],
  },
  suggestion: {
    loadMoreUrl: null,
    ids: [],
  },
  hidden: {
    loadMoreUrl: null,
    ids: [],
  },
  availableProjects: {
    loadMoreUrl: null,
    ids: [],
  },
};

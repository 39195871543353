<template>
  <docs-item-view
    :id="folder.id"
    :view-mode="viewMode"
    :document-type="DocumentTypeEnum.Folder"
    :name="folderName"
    :created-at="folder.createdAt"
    :author="folderAuthor"
    :edited-at="''"
    :editor="''"
    :group="folderGroupTitle"
    :icon="folderOutline"
    @onMenuOpen="onMenuOpen"
    @onQuickAction="onQuickAction"
  />
</template>

<script lang="ts" setup>
import { folderOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { DocsItemView } from '@/components';
import { DocumentTypeEnum } from '@/enums';
import type { DataViewMode } from '@/enums';
import { useI18n } from '@/i18n';
import { useDocStore } from '@/store';
import type { FolderModel } from '@/types';

const props = defineProps({
  folder: {
    type: Object as PropType<FolderModel>,
    required: true,
  },
  viewMode: {
    type: String as PropType<DataViewMode>,
    required: true,
  },
});

const emit = defineEmits(['onQuickAction', 'onSecondaryAction']);

//#region Variables
const docStore = useDocStore();
const { t } = useI18n();
const folderGroupTitle: ComputedRef<string> = computed(() =>
  props.folder.group ? props.folder.group.title : t('network.allNetwork')
);
const folderName: ComputedRef<string> = computed(() => props.folder.name);
const folderAuthor: ComputedRef<string> = computed(() => (props.folder.author ? props.folder.author?.fullName : ''));
//#endregion

//#region Methods
const onQuickAction = async () => {
  emit('onQuickAction', props.folder);
  docStore.setSelectedDocs([]);
};

const onMenuOpen = async (ev: Event) => {
  emit('onSecondaryAction', ev);
};
//#endregion
</script>

import type { ErrorEvent } from '@sentry/types';
import { AxiosError } from 'axios';
import { isSentryInitialized, SentryVue } from '@/plugins/sentry';
import { RequirementsEnum, ResponseErrorCodesEnum } from '@/enums';
import { useRequirements, useToasts } from '@/helpers';
import { useAppStore, useMessengerStore, useNetworkStore } from '@/store';
import type { ResponseErrorModel } from '@/types';
import { buildAxiosError, buildErrorResponse } from '@/services/builders';
import { useI18n } from '@/i18n';

type HandleErrorPayload = {
  show: boolean;
  error?: AxiosError | Response | Error;
  message?: string;
};

type IUseErrors = {
  /**
   * Current error object
   *
   * @readonly
   */
  currentError: CurrentErrorEvent;
  /**
   * Handles an error by generating currentError object using buildErrorResponse helper
   * and capturing an exception event and sending it to Sentry.
   *
   * @see src/helpers/helper.ts - buildErrorResponse
   * @param show - If true, toast will be shown
   * @param error - Axios error or any
   * @param message - message to display
   */
  handleError: (payload: HandleErrorPayload) => ResponseErrorCodesEnum;
  /**
   * Resets the current error object to default value
   */
  resetCurrentError: () => void;
  /**
   * Sets the Sentry event that is provided by sentry beforeSend hook
   *
   * @param event
   * @see src/plugins/sentry.ts - initSentry - Sentry.init - beforeSend
   */
  setSentryEvent: (event: ErrorEvent) => void;
  /**
   * Handles successful feedback submission
   */
  handleSuccessfulSubmit: () => void;
};

export type CurrentErrorEvent = {
  id: string;
  message: string;
  serverDetails: ResponseErrorModel | null;
  sentryDetails: ErrorEvent | null;
  show?: boolean;
};

let instance: IUseErrors | null = null;

export function useErrors(): IUseErrors {
  if (instance) return instance;

  //#region Stores
  const networkStore = useNetworkStore();
  const appStore = useAppStore();
  const messengerStore = useMessengerStore();
  //#endregion

  //#region Helpers
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Variables
  const isLocalHost = window.location.hostname === 'localhost';
  let currentError: CurrentErrorEvent = {
    id: '',
    message: '',
    serverDetails: null,
    sentryDetails: null,
    show: false,
  };
  //#endregion

  //#region Private methods
  const _handleBadRequest = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is BadRequest or ApiNotValidModel', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleUnauthorized = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is Unauthorized', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleAccessDenied = (error: CurrentErrorEvent): void => {
    networkStore.isNetworkAvailable = false;
    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
    /** @todo Handle access denial, possibly log out the user. */
  };

  /** @deprecated */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _handleInvalidAcceptPolicy = async (error: CurrentErrorEvent): Promise<void> => {
    console.error('[ERROR] Error type is InvalidAcceptPolicy', error);

    appStore.isLoading = false;
    networkStore.isLoading = false;
    messengerStore.isLoading = false;
    await useRequirements().check(RequirementsEnum.UsageRules);
  };

  /** @deprecated */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _handleNotFound = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is NotFound', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleRequestTimeout = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is RequestTimeout', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleConflict = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is Conflict', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleInternalServerError = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is InternalServerError', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleBadGateway = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is BadGateway', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleServiceUnavailable = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is ServiceUnavailable', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleGatewayTimeout = (error: CurrentErrorEvent): void => {
    console.error('[ERROR] Error type is GatewayTimeout', error);

    showSonnerToast(error.message, false, error.id, error.sentryDetails, error.serverDetails);
  };

  const _handleAxiosError = (error: CurrentErrorEvent): void => {
    const t = (error.serverDetails?.errorType as ResponseErrorCodesEnum) || ResponseErrorCodesEnum.UNKNOWN_ERROR;
    console.error(`[ERROR] Error type is ${t}`, error);

    let message: string = error.message;
    if (t === ResponseErrorCodesEnum.UNKNOWN_ERROR && !message && error.sentryDetails) {
      const sentryMsg = error.sentryDetails?.message as any;
      message = `
          status: ${sentryMsg?.status ?? 'Unknown status'}
          statusText: ${sentryMsg?.statusText ?? 'Unknown status text'}
          event_id: ${error.sentryDetails?.event_id ?? 'Unknown event id'}
          time: ${sentryMsg?.time ?? new Date().toISOString()}
        `;
    }
    showSonnerToast(
      message || 'Internal error. Please try again later or contact support.',
      false,
      error.id,
      error.sentryDetails,
      error.serverDetails
    );
  };

  const _handleGenericError = (error: CurrentErrorEvent): void => {
    showSonnerToast(
      error.message || "An unexpected error occurred. We're working on it.",
      false,
      error.id,
      error.sentryDetails,
      error.serverDetails
    );
  };

  const _handleTurnedOffTypes = (error: CurrentErrorEvent): void => {
    console.warn(`[WARN] This type of error is currently turned off: ${error.serverDetails?.errorType}`);
  };

  const _errorHandlers: Record<ResponseErrorCodesEnum, (error: CurrentErrorEvent) => void> = Object.freeze({
    // Existing handlers
    [ResponseErrorCodesEnum.BadRequest]: _handleBadRequest,
    [ResponseErrorCodesEnum.NotValidModel]: _handleBadRequest,
    [ResponseErrorCodesEnum.Unauthorized]: _handleUnauthorized,
    [ResponseErrorCodesEnum.MethodNotAllowed]: _handleAccessDenied,
    [ResponseErrorCodesEnum.RequestTimeout]: _handleRequestTimeout,
    [ResponseErrorCodesEnum.Conflict]: _handleConflict,
    [ResponseErrorCodesEnum.InternalServerError]: _handleInternalServerError,
    [ResponseErrorCodesEnum.BadGateway]: _handleBadGateway,
    [ResponseErrorCodesEnum.ServiceUnavailable]: _handleServiceUnavailable,
    [ResponseErrorCodesEnum.GatewayTimeout]: _handleGatewayTimeout,

    // Turned off types
    [ResponseErrorCodesEnum.ClientError]: _handleTurnedOffTypes,
    [ResponseErrorCodesEnum.Forbidden]: _handleTurnedOffTypes,
    [ResponseErrorCodesEnum.NotFound]: _handleTurnedOffTypes,

    // Axios Codes
    [ResponseErrorCodesEnum.ERR_FR_TOO_MANY_REDIRECTS]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_BAD_OPTION_VALUE]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_BAD_OPTION]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_NETWORK]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_DEPRECATED]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_BAD_RESPONSE]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_BAD_REQUEST]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_NOT_SUPPORT]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_INVALID_URL]: _handleAxiosError,
    [ResponseErrorCodesEnum.ERR_CANCELED]: _handleAxiosError,
    [ResponseErrorCodesEnum.ECONNABORTED]: _handleAxiosError,
    [ResponseErrorCodesEnum.ETIMEDOUT]: _handleAxiosError,
    [ResponseErrorCodesEnum.UNKNOWN_ERROR]: _handleAxiosError,

    // New error codes assigned to _handleGenericError
    [ResponseErrorCodesEnum.ContextNull]: _handleGenericError,
    [ResponseErrorCodesEnum.AppException]: _handleGenericError,
    [ResponseErrorCodesEnum.InvalidClientSecret]: _handleGenericError,
    [ResponseErrorCodesEnum.ArgumentException]: _handleGenericError,
    [ResponseErrorCodesEnum.SolrException]: _handleGenericError,
    [ResponseErrorCodesEnum.UnknownImageFormat]: _handleGenericError,
    [ResponseErrorCodesEnum.DateInvalid]: _handleGenericError,
    [ResponseErrorCodesEnum.RetriesLimitExceededException]: _handleGenericError,
    [ResponseErrorCodesEnum.BadFileFormat]: _handleGenericError,

    // HTTP Codes not previously handled
    [ResponseErrorCodesEnum.PaymentRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.NotAcceptable]: _handleGenericError,
    [ResponseErrorCodesEnum.ProxyAuthenticationRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.Gone]: _handleGenericError,
    [ResponseErrorCodesEnum.LengthRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.PreconditionFailed]: _handleGenericError,
    [ResponseErrorCodesEnum.RequestEntityTooLarge]: _handleGenericError,
    [ResponseErrorCodesEnum.RequestUriTooLong]: _handleGenericError,
    [ResponseErrorCodesEnum.UnsupportedMediaType]: _handleGenericError,
    [ResponseErrorCodesEnum.RequestedRangeNotSatisfiable]: _handleGenericError,
    [ResponseErrorCodesEnum.ExpectationFailed]: _handleGenericError,
    [ResponseErrorCodesEnum.MisdirectedRequest]: _handleGenericError,
    [ResponseErrorCodesEnum.UnprocessableEntity]: _handleGenericError,
    [ResponseErrorCodesEnum.Locked]: _handleGenericError,
    [ResponseErrorCodesEnum.FailedDependency]: _handleGenericError,
    [ResponseErrorCodesEnum.UpgradeRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.PreconditionRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.TooManyRequests]: _handleGenericError,
    [ResponseErrorCodesEnum.RequestHeaderFieldsTooLarge]: _handleGenericError,
    [ResponseErrorCodesEnum.UnavailableForLegalReasons]: _handleGenericError,
    [ResponseErrorCodesEnum.NotImplemented]: _handleGenericError,
    [ResponseErrorCodesEnum.HttpVersionNotSupported]: _handleGenericError,
    [ResponseErrorCodesEnum.VariantAlsoNegotiates]: _handleGenericError,
    [ResponseErrorCodesEnum.InsufficientStorage]: _handleGenericError,
    [ResponseErrorCodesEnum.LoopDetected]: _handleGenericError,
    [ResponseErrorCodesEnum.NotExtended]: _handleGenericError,
    [ResponseErrorCodesEnum.NetworkAuthenticationRequired]: _handleGenericError,

    // Other custom error codes
    [ResponseErrorCodesEnum.Post]: _handleGenericError,
    [ResponseErrorCodesEnum.Message]: _handleGenericError,

    // User-related error codes
    [ResponseErrorCodesEnum.User]: _handleGenericError,
    [ResponseErrorCodesEnum.User_NotFound]: _handleGenericError,
    [ResponseErrorCodesEnum.User_IsBanned]: _handleGenericError,
    [ResponseErrorCodesEnum.User_IsDeleted]: _handleGenericError,
    [ResponseErrorCodesEnum.User_NotActive]: _handleGenericError,
    [ResponseErrorCodesEnum.User_IsBlocked]: _handleGenericError,
    [ResponseErrorCodesEnum.User_AccessDenied]: _handleGenericError,
    [ResponseErrorCodesEnum.User_MobileAppBlocked]: _handleGenericError,
    [ResponseErrorCodesEnum.User_AccessTemporaryBlocked]: _handleGenericError,
    [ResponseErrorCodesEnum.User_LoginPasswordError]: _handleGenericError,
    [ResponseErrorCodesEnum.User_NotSupportedOAuthGrantType]: _handleGenericError,
    [ResponseErrorCodesEnum.User_NotFoundInHomeNetwork]: _handleGenericError,
    [ResponseErrorCodesEnum.User_NotFoundByRowId]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InvitationEmailIsEmpty]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InvitationEmailIsNotValid]: _handleGenericError,
    [ResponseErrorCodesEnum.User_SendEmailAlreadyRegistered]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InvitationNotCreated]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InviteBadEmailSuffix]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InvitationNotExists]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InvitationAlreadyAccepted]: _handleGenericError,
    [ResponseErrorCodesEnum.User_EmailNotAllowedForRegistration]: _handleGenericError,
    [ResponseErrorCodesEnum.User_AlreadyRegistered]: _handleGenericError,
    [ResponseErrorCodesEnum.User_TokenNotValid]: _handleGenericError,
    [ResponseErrorCodesEnum.User_TokenNotSpecified]: _handleGenericError,
    [ResponseErrorCodesEnum.User_InvalidTokenFormat]: _handleGenericError,
    [ResponseErrorCodesEnum.User_ObsoleteToken]: _handleGenericError,
    [ResponseErrorCodesEnum.User_ClientSslCertificateRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.User_NotEnoughRights]: _handleGenericError,
    [ResponseErrorCodesEnum.User_PasswordNotValid]: _handleGenericError,
    [ResponseErrorCodesEnum.User_SubDivisionNotCreated]: _handleGenericError,
    [ResponseErrorCodesEnum.UserItem_NotCreated]: _handleGenericError,
    [ResponseErrorCodesEnum.UserItem_NotAllowedInGroup]: _handleGenericError,
    [ResponseErrorCodesEnum.UserItem_VoteMultipleAnswersForbidden]: _handleGenericError,
    [ResponseErrorCodesEnum.UserAttributes_NameIsEmpty]: _handleGenericError,
    [ResponseErrorCodesEnum.UserAttributes_NameAlreadyExists]: _handleGenericError,
    [ResponseErrorCodesEnum.UserAttributes_InvalidNameFormat]: _handleGenericError,
    [ResponseErrorCodesEnum.UserAttributes_UnableEditAttribute]: _handleGenericError,

    // Group-related error codes
    [ResponseErrorCodesEnum.Group]: _handleGenericError,
    [ResponseErrorCodesEnum.Group_NotFound]: _handleGenericError,
    [ResponseErrorCodesEnum.Group_NotCreated]: _handleGenericError,
    [ResponseErrorCodesEnum.Group_OnlyGroupAdminCanGroupUpdate]: _handleGenericError,
    [ResponseErrorCodesEnum.Group_OnlyAdminsCanInviteToPrivateGroups]: _handleGenericError,

    // Wiki-related error codes
    [ResponseErrorCodesEnum.Wiki]: _handleGenericError,
    [ResponseErrorCodesEnum.Wiki_RelationsNotSupported]: _handleGenericError,

    // File-related error codes
    [ResponseErrorCodesEnum.File]: _handleGenericError,
    [ResponseErrorCodesEnum.File_NotUpload]: _handleGenericError,
    [ResponseErrorCodesEnum.File_OfficeJwtTokenIsNull]: _handleGenericError,
    [ResponseErrorCodesEnum.File_OfficeCreateOnSave]: _handleGenericError,
    [ResponseErrorCodesEnum.File_OfficeContentTypeInvalid]: _handleGenericError,
    [ResponseErrorCodesEnum.File_OfficeFileStreamIsNull]: _handleGenericError,

    // Folder-related error codes
    [ResponseErrorCodesEnum.Folder]: _handleGenericError,
    [ResponseErrorCodesEnum.Folder_AccessDeniedForCreateInOfficialGroup]: _handleGenericError,

    // Task-related error codes
    [ResponseErrorCodesEnum.Task]: _handleGenericError,

    // Network-related error codes
    [ResponseErrorCodesEnum.Network]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_CompanyNotActive]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_CompanyNotFound]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_HomeCompanyNotFound]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_NotAllowedForNetworkBox]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_ExternalAuthorizationNotSupported]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_ApplicationRestrictions]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_NoUserTokenAvailableWithoutAuthentication]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_HomeCodeInvalidUID]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_HomeCodeInvalidCode]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_CompanySettingsRequiredItemType]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_ItemTypeRequiredCompanySettings]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_CoreSecretKeyBroken]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_BoxNetworkIdIsRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_FrontDomainIsRequired]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_CoreInstanceSelfIsNull]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_InviteToGroupsNotAllowed]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_CreatingInviteByEmailNotAllowed]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_RegistrationOnlyBySmsApi]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_EmailInBlackList]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_RegistrationRequestSent]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_AllowExternalEmailsTypeNotFound]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_AccessDeniedForUpdateSettings]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_BadgeLimitExceeded]: _handleGenericError,
    [ResponseErrorCodesEnum.Network_OnlySystemCanCreateDefaultContent]: _handleGenericError,

    // HTTP informational responses (1xx)
    [ResponseErrorCodesEnum.Continue]: _handleGenericError,
    [ResponseErrorCodesEnum.SwitchingProtocols]: _handleGenericError,
    [ResponseErrorCodesEnum.Processing]: _handleGenericError,
    [ResponseErrorCodesEnum.EarlyHints]: _handleGenericError,

    // HTTP success responses (2xx)
    [ResponseErrorCodesEnum.OK]: _handleGenericError,
    [ResponseErrorCodesEnum.Created]: _handleGenericError,
    [ResponseErrorCodesEnum.Accepted]: _handleGenericError,
    [ResponseErrorCodesEnum.NonAuthoritativeInformation]: _handleGenericError,
    [ResponseErrorCodesEnum.NoContent]: _handleGenericError,
    [ResponseErrorCodesEnum.ResetContent]: _handleGenericError,
    [ResponseErrorCodesEnum.PartialContent]: _handleGenericError,
    [ResponseErrorCodesEnum.MultiStatus]: _handleGenericError,
    [ResponseErrorCodesEnum.AlreadyReported]: _handleGenericError,
    [ResponseErrorCodesEnum.IMUsed]: _handleGenericError,

    // HTTP redirection messages (3xx)
    [ResponseErrorCodesEnum.Ambiguous]: _handleGenericError,
    [ResponseErrorCodesEnum.MultipleChoices]: _handleGenericError,
    [ResponseErrorCodesEnum.Moved]: _handleGenericError,
    [ResponseErrorCodesEnum.MovedPermanently]: _handleGenericError,
    [ResponseErrorCodesEnum.Found]: _handleGenericError,
    [ResponseErrorCodesEnum.Redirect]: _handleGenericError,
    [ResponseErrorCodesEnum.RedirectMethod]: _handleGenericError,
    [ResponseErrorCodesEnum.SeeOther]: _handleGenericError,
    [ResponseErrorCodesEnum.NotModified]: _handleGenericError,
    [ResponseErrorCodesEnum.UseProxy]: _handleGenericError,
    [ResponseErrorCodesEnum.Unused]: _handleGenericError,
    [ResponseErrorCodesEnum.RedirectKeepVerb]: _handleGenericError,
    [ResponseErrorCodesEnum.TemporaryRedirect]: _handleGenericError,
    [ResponseErrorCodesEnum.PermanentRedirect]: _handleGenericError,
  });

  const _showErrorEvent = (error: CurrentErrorEvent): void => {
    const errorType =
      (error.serverDetails?.errorType as ResponseErrorCodesEnum) || ResponseErrorCodesEnum.UNKNOWN_ERROR;
    const handler = _errorHandlers[errorType];

    handler ? handler(error) : _handleAxiosError(error);
  };
  //#endregion

  //#region Public methods
  const handleError = (payload: HandleErrorPayload): ResponseErrorCodesEnum => {
    const { t } = useI18n();
    const { show, error, message } = payload;

    const { statusCode, traceId, errors, type, errorType, errorMessages } = buildErrorResponse(buildAxiosError(error));

    currentError = {
      id: '',
      message: (error && errorMessages[0].errors[0]) || message || t('errorGeneric'),
      serverDetails: {
        statusCode,
        traceId,
        errors,
        type,
        errorType,
        errorMessages,
      },
      sentryDetails: null,
      show,
    };

    if (error) {
      if (isLocalHost) _showErrorEvent(currentError);
      else if (isSentryInitialized()) SentryVue.captureException(error);
    }

    return errorType;
  };

  const resetCurrentError = (): void => {
    Object.assign(currentError, {
      id: '',
      message: '',
      serverDetails: null,
      sentryDetails: null,
      showUser: false,
    });
  };

  const setSentryEvent = (event: ErrorEvent): void => {
    currentError.id = event?.event_id || 'unknown event_id';
    currentError.sentryDetails = event;

    if (currentError.show) {
      _showErrorEvent(currentError);
    }
  };

  const handleSuccessfulSubmit = (): void => {
    showSonnerToast(
      'We received your report and already working on it!' /** @todo Translate message t('apiErrors.reportReceived') */,
      true,
      currentError.id,
      currentError.sentryDetails,
      currentError.serverDetails
    );
  };
  //#endregion

  instance = {
    get currentError(): CurrentErrorEvent {
      return currentError;
    },
    handleError,
    resetCurrentError,
    setSentryEvent,
    handleSuccessfulSubmit,
  };

  return instance;
}

import { HubConnectionState } from '@microsoft/signalr';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { $api } from '@/services';
import { getServiceHost } from '@/services/getServiceHost';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  WebSocketModel,
  AuthFormModel,
  TokenRequestPayload,
  ResponseAuthTokenModel,
  ResponseAuthHomeCodeModel,
  AuthHomeCodeModel,
  AuthTokenModel,
} from '@/types';
import { useAppStore } from '@/store';
import { OAuthGrantTypeEnum } from '@/enums';

type AuthState = {
  host: string;
  grantType: OAuthGrantTypeEnum | null;

  homeAccessToken: string;
  homeValidUntil: string;

  accessToken: string;
  validUntil: string;

  coreId: string;
  companyRowId: string;

  userId: number | null;
  userRowId: string;

  webSocket: string;
  signalRConnectionId: string;
  signalRConnectionStatus: HubConnectionState;

  redirectUrl: string | null;

  errors: ErrorMessageModel[];
};

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    host: getServiceHost(),
    grantType: null,

    homeAccessToken: '',
    homeValidUntil: '',
    accessToken: '',
    validUntil: '',
    coreId: '',
    companyRowId: '',
    userId: null,
    userRowId: '',

    webSocket: import.meta.env.VITE_APP_SIGNALR_URL,
    signalRConnectionId: '',
    signalRConnectionStatus: HubConnectionState.Disconnected,

    redirectUrl: null,

    errors: [],
  }),
  getters: {
    getWebSocketModel(state): WebSocketModel | null {
      const baseUrl = `https://${this.host}`;
      if (!baseUrl || state.userId === null || state.webSocket.length === 0) {
        return null;
      }

      return {
        baseUrl,
        userId: state.userId,
        coreId: state.coreId,
        companyRowId: state.companyRowId,
        userRowId: state.userRowId,
        webSocket: state.webSocket,
        version: useAppStore().appVersion,
      };
    },
  },
  actions: {
    //#region Setters
    setGrantType(grantType: OAuthGrantTypeEnum) {
      this.grantType = grantType;
    },
    setHomeCodeData(model: AuthHomeCodeModel) {
      this.$patch((state) => {
        state.homeAccessToken = model.accessToken;
        state.homeValidUntil = model.validUntil;
        state.coreId = model.coreId;
        state.companyRowId = model.companyRowId;
        state.userId = model.userId;
        state.userRowId = model.userRowId;
      });
    },
    setTokenData(model: AuthTokenModel, isOldOauth: boolean = false) {
      this.$patch((state) => {
        state.accessToken = model.accessToken;
        state.validUntil = model.validUntil;
        state.coreId = model.coreId;
        state.companyRowId = model.companyRowId;
        state.userId = model.userId;
        state.userRowId = model.userRowId;
      });

      if (!isOldOauth) {
        this.$patch((state) => {
          state.homeAccessToken = model.homeAccessToken;
          state.homeValidUntil = model.homeValidUntil;
        });
      }
    },
    setSignalRConnectionId(id: string) {
      this.signalRConnectionId = id;
    },
    setSignalRConnectionStatus(status: HubConnectionState) {
      this.signalRConnectionStatus = status;
    },
    setUserId(userId: number) {
      this.userId = userId;
    },
    setHost(host: string) {
      this.host = host;
    },
    setRedirectUrl(url: string | null) {
      this.redirectUrl = url;
    },
    setNetwork(companyRowId: string, webSocket: string, isWaitingForCompleteLogin: boolean) {
      this.companyRowId = companyRowId;
      this.webSocket = webSocket;
      useAppStore().isWaitingForCompleteLogin = isWaitingForCompleteLogin;
    },
    //#endregion

    //#region Helpers
    isAuth(): boolean {
      const hasRequiredFields = (): boolean => {
        return (
          Boolean(this.homeAccessToken) &&
          Boolean(this.accessToken) &&
          Boolean(this.coreId) &&
          Boolean(this.companyRowId) &&
          this.userId !== null &&
          this.userId > 0 &&
          Boolean(this.userRowId) &&
          Boolean(this.grantType)
        );
      };

      return hasRequiredFields() && !this.isHomeAccessTokenExpired() && !this.isAccessTokenExpired();
    },

    isHomeAccessTokenExpired(): boolean {
      return Date.parse(this.homeValidUntil) < Date.now() || !this.homeAccessToken;
    },

    isAccessTokenExpired(): boolean {
      return Date.parse(this.validUntil) < Date.now() || !this.accessToken;
    },
    //#endregion

    //#region API
    async homeCode(data: AuthFormModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.auth.homeCode(data);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthHomeCodeModel;
        this.setHomeCodeData(model.data);
        this.setGrantType(OAuthGrantTypeEnum.HomeAuthorizationCode);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    /**
     * @note Not ideal implementation
     * @todo Refactor, especially TokenRequestPayload and $api.auth.token
     */
    async token(payload?: TokenRequestPayload): Promise<boolean> {
      const { isRedirect, isFullAuth, isOldOauth, silent, force, code, login, password } = payload || {};

      if (!this.isAccessTokenExpired() && !force && !isRedirect && !isFullAuth) {
        console.warn('[WARN] [/oauth/token]: already authorized. accessToken is OK');
        return false;
      }

      if (this.isHomeAccessTokenExpired() && !isRedirect && !isFullAuth && !isOldOauth) {
        console.warn('[WARN] [/oauth/token]: unable to authorize. homeAccessToken is EXPIRED');
        return false;
      }

      if (isRedirect && !code) {
        console.warn('[WARN] [/oauth/token]: current mode is redirect BUT code is NOT OK', payload);
        return false;
      }

      if (isFullAuth && (!login || !password)) {
        console.warn('[WARN] [/oauth/token]: current mode is fullAuth BUT login or password are NOT OK', payload);
        return false;
      }

      const passwordBody = {
        grantType: OAuthGrantTypeEnum.Password,
        login: login,
        password: password,
      };

      const redirectBody = {
        grantType: OAuthGrantTypeEnum.SignedAuthorizationCode,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        code: code!,
      };

      const classicBody = {
        grantType: isOldOauth ? OAuthGrantTypeEnum.HomeAuthorizationOld : OAuthGrantTypeEnum.HomeAuthorizationCode,
        code: this.homeAccessToken,
        uid: this.companyRowId,
      };

      const body = isRedirect ? redirectBody : isFullAuth ? passwordBody : classicBody;

      useAppStore().setIsLoading(true);
      const response = await $api.auth.token(body, silent);
      useAppStore().setIsLoading(false);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthTokenModel;
        this.setTokenData(model.data, isOldOauth);
        this.setGrantType(
          isOldOauth ? OAuthGrantTypeEnum.HomeAuthorizationOld : OAuthGrantTypeEnum.HomeAuthorizationCode
        );
        return true;
      }

      return false;
    },
    //#endregion
  },
  persist: true,
});

<template>
  <ion-searchbar
    ref="searchbarRef"
    v-if="searchbarEnabled"
    class="mb-lg"
    v-model:value="searchText"
    :placeholder="$t('search.waiting')"
    :debounce="500"
    mode="md"
    @ionInput="onSearch($event)"
  />
  <not-found-data
    v-if="allResultsIsEmpty && searchText.length && !searchIsLoading"
    :text="$t('search.notFound', { searchText })"
  />
  <not-found-data v-if="allResultsIsEmpty && !searchText.length" :text="$t('search.startSearch')" />

  <transition name="fade">
    <section v-if="suggestionsEnabled && suggestions.length">
      <ion-list-header mode="ios" class="flex">
        <ion-label>{{ $t('search.suggestions') }}</ion-label>
        <ion-button mode="md" class="clear_button" @click="resetSuggestions()">
          {{ $t('clear') }}
        </ion-button>
      </ion-list-header>
      <app-row>
        <app-col
          v-for="item in suggestions"
          :key="new Date().getTime() + item"
          class="ion-align-self-stretch"
          :size="suggestions.length === 1 || !isSMWidth ? '6' : '4'"
          @click="changeSearchText(item)"
        >
          <ion-item lines="none" :class="['search_suggestion', 'small']">
            <icons-provider
              slot="start"
              :icon-props="{
                width: '24',
                height: '24',
                strokewidth: 1.5,
                fill: 'var(--ion-color-medium)',
              }"
              :name="AppIconsEnum.Search"
            />
            <ion-label>{{ item }}</ion-label>
          </ion-item>
        </app-col>
      </app-row>
    </section>
  </transition>

  <transition name="fade">
    <section v-if="posts.length && searchText.length">
      <ion-list-header mode="ios" class="mb-md">
        <ion-label>{{ $t('search.searchView.types.posts') }}</ion-label>
      </ion-list-header>
      <div v-for="post in posts" :key="`post_${post.id}`" class="mb-lg">
        <post-preview :post="post" :feed-flag="FeedFlagEnum.SharedPost" :clickable="true" :modal-view="false" />
      </div>
    </section>
  </transition>
  <transition name="fade">
    <section v-if="users.length && searchText.length">
      <app-grid>
        <ion-list-header mode="ios" class="flex hoverable" @click="openUsersPage()">
          <ion-label>
            {{ $t('appMenu.people') }}
            <icons-provider
              :icon-props="{
                width: '18',
                height: '18',
                strokewidth: 1.5,
                fill: 'var(--ion-color-dark)',
              }"
              :name="AppIconsEnum.ArrowSmRight"
            />
          </ion-label>
        </ion-list-header>
        <app-row>
          <app-col
            v-for="item in users.slice(0, 4)"
            :key="item.id"
            class="ion-align-self-stretch"
            :size="users.length === 1 || !isSMWidth ? '12' : '6'"
            @click="openUserProfile(item.id)"
          >
            <ion-item lines="none">
              <user-list-item :user="item" with-subtitle with-contributions size="small" />
            </ion-item>
          </app-col>
        </app-row>
      </app-grid>
    </section>
  </transition>

  <transition name="fade">
    <section v-if="groups.length && searchText.length">
      <app-grid>
        <ion-list-header mode="ios" class="flex hoverable" @click="openGroupsPage()">
          <ion-label>
            {{ $t('appMenu.groups') }}
            <icons-provider
              :icon-props="{
                width: '18',
                height: '18',
                strokewidth: 1.5,
                fill: 'var(--ion-color-dark)',
              }"
              :name="AppIconsEnum.ArrowSmRight"
            />
          </ion-label>
        </ion-list-header>
        <app-row>
          <app-col
            v-for="item in groups.slice(0, 4)"
            :key="item.id"
            class="ion-align-self-stretch"
            :size="groups.length === 1 || !isSMWidth ? '12' : '6'"
            @click="openGroupPage(item.id)"
          >
            <ion-item lines="none"><group-list-item :group="item" size="small" /></ion-item>
          </app-col>
        </app-row>
      </app-grid>
    </section>
  </transition>

  <transition name="fade">
    <section v-if="(files.length || wikis.length) && searchText.length">
      <app-grid>
        <ion-list-header mode="ios" class="flex">
          <ion-label>{{ $t('appMenu.docs') }}</ion-label>
        </ion-list-header>
      </app-grid>
    </section>
  </transition>

  <transition name="fade">
    <section v-if="files.length && searchText.length">
      <app-grid>
        <ion-list-header mode="ios" class="flex small hoverable" @click="openFilesPage()">
          <ion-label>
            {{ $t('search.searchView.types.files') }}
            <icons-provider
              :icon-props="{
                width: '16',
                height: '16',
                strokewidth: 1.5,
                fill: 'var(--ion-color-dark)',
              }"
              :name="AppIconsEnum.ArrowSmRight"
            />
          </ion-label>
        </ion-list-header>
        <app-row>
          <app-col
            v-for="item in files.slice(0, 4)"
            :key="item.id"
            class="ion-align-self-stretch"
            :size="wikis.length === 1 || !isSMWidth ? '12' : '6'"
          >
            <ion-item
              lines="none"
              class="small"
              @click="item.mimeType.startsWith('image') ? previewImage(item) : previewFile(item.id)"
            >
              <icons-provider
                slot="start"
                :icon-props="{
                  width: '40',
                  height: '40',
                  strokewidth: 1.5,
                  fill: 'var(--ion-color-medium)',
                }"
                :name="getDocumentIcon(DocumentTypeEnum.File, item.name + '.' + item.type)"
              />
              <ion-label>
                {{ item.name + '.' + item.type }}
                <p>{{ formatDateHelper(item.createdAt, 'short') }}</p>
              </ion-label>
            </ion-item>
          </app-col>
        </app-row>
      </app-grid>
    </section>
  </transition>

  <transition name="fade">
    <section v-if="wikis.length && searchText.length">
      <app-grid>
        <ion-list-header mode="ios" class="flex small hoverable" @click="openWikisPage()">
          <ion-label>
            {{ $t('search.searchView.types.wikis') }}
            <icons-provider
              :icon-props="{
                width: '16',
                height: '16',
                strokewidth: 1.5,
                fill: 'var(--ion-color-dark)',
              }"
              :name="AppIconsEnum.ArrowSmRight"
            />
          </ion-label>
        </ion-list-header>
        <app-row>
          <app-col
            v-for="item in wikis.slice(0, 4)"
            :key="item.id"
            class="ion-align-self-stretch"
            :size="wikis.length === 1 || !isSMWidth ? '12' : '6'"
            @click="openWiki(item.id)"
          >
            <ion-item lines="none" class="small">
              <icons-provider
                slot="start"
                :icon-props="{
                  width: '40',
                  height: '40',
                  strokewidth: 1.5,
                  fill: 'var(--ion-color-medium)',
                }"
                :name="AppIconsEnum.Wiki"
              />
              <ion-label>
                {{ item.name }}
                <p>{{ formatDateHelper(item.editedAt, 'short') }}</p>
              </ion-label>
            </ion-item>
          </app-col>
        </app-row>
      </app-grid>
    </section>
  </transition>

  <transition name="fade">
    <section v-if="searchHistory.length && !searchIsLoading">
      <app-grid>
        <ion-list-header mode="ios" class="flex">
          <ion-label>{{ $t('recentRequests') }}</ion-label>
          <ion-button mode="md" class="clear_button" @click="resetHistory()">{{ $t('clear') }} </ion-button>
        </ion-list-header>
        <app-row>
          <app-col
            v-for="(item, index) in searchHistory.slice(0, 3)"
            :key="item + index"
            class="ion-align-self-stretch"
            :size="searchHistory.length === 1 || !isSMWidth ? '12' : '4'"
            @click="changeSearchText(item)"
          >
            <ion-item lines="none" class="small">
              <icons-provider
                slot="start"
                :icon-props="{
                  width: '24',
                  height: '24',
                  strokewidth: 1,
                  fill: 'var(--ion-color-medium)',
                }"
                :name="AppIconsEnum.UnstageAll"
              />
              <ion-label>{{ item }}</ion-label>
            </ion-item>
          </app-col>
        </app-row>
      </app-grid>
    </section>
  </transition>

  <section class="mb-xs">
    <app-grid>
      <ion-list-header mode="ios">
        {{ $t('search.advancedSearch') }}
      </ion-list-header>
      <app-row>
        <app-col class="ion-align-self-stretch" size="12">
          <ion-button class="more_button" mode="md" expand="block" @click="openAdvancedSearch()">
            {{
              searchText.length && !searchIsLoading
                ? $t('moreResults', { request: searchText })
                : $t('search.goToAdvanced')
            }}
          </ion-button>
        </app-col>
      </app-row>
    </app-grid>
  </section>

  <transition name="fade">
    <div v-if="searchIsLoading || (suggestionsEnabled && suggestIsLoading)" class="loader">
      <icons-provider
        :icon-props="{
          width: '64',
          height: '64',
          strokewidth: 1.5,
          fill: 'var(--ion-color-medium)',
        }"
        :name="AppIconsEnum.CircleAnim"
      />
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { IonItem, IonLabel, IonButton, IonListHeader, IonSearchbar } from '@ionic/vue';
import { watchDebounced } from '@vueuse/core';
import { type ComponentPublicInstance, ComputedRef, nextTick, onMounted, onUnmounted } from 'vue';
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';

import {
  UserListItem,
  GroupListItem,
  AppGrid,
  AppRow,
  AppCol,
  IconsProvider,
  NotFoundData,
  PostPreview,
} from '@/components';
import {
  DocumentTypeEnum,
  SearchType,
  UsersFilterEnum,
  AppIconsEnum,
  WikiActionEnum,
  FeedFlagEnum,
  DocBrowserModeEnum,
} from '@/enums';
import {
  formatDateHelper,
  getDocumentIcon,
  searchSuggestionsEnabled,
  useCustomScroll,
  useFileActions,
  useWiki,
} from '@/helpers';
import { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useGroupsStore,
  useUserStore,
  useSearchStore,
  useWikiStore,
  useDocStore,
  usePostStore,
} from '@/store';
import type { DocModel, FileModel, GroupModel, PostModel, UserModel, WikiModel } from '@/types';

const props = defineProps({
  searchbarEnabled: {
    type: Boolean,
    default: false,
  },
});

//#region Variables
const router = useRouter();

const userStore = useUserStore();
const postStore = usePostStore();
const appStore = useAppStore();
const groupStore = useGroupsStore();
const wikiStore = useWikiStore();
const docStore = useDocStore();
const searchStore = useSearchStore();

const customScroll = useCustomScroll();

const searchIsLoading = ref<boolean>(false);
const searchbarRef = ref<ComponentPublicInstance | null>(null);

const searchText: ComputedRef<string> = computed(() => appStore.searchText);
const searchHistory: ComputedRef<string[]> = computed(() => appStore.searchHistory);
const suggestionsEnabled: ComputedRef<boolean> = computed(() => searchSuggestionsEnabled());
const suggestions: ComputedRef<string[]> = computed(() => searchStore.suggestions);
const posts: ComputedRef<PostModel[]> = computed(() => postStore.getPostsOfSearch.data.slice(0, 3));
const users: ComputedRef<UserModel[]> = computed(() => userStore.getUsersChosen(UsersFilterEnum.UsersPage).data);
const groups: ComputedRef<GroupModel[]> = computed(() => groupStore.getSearchedGroups().data);
const wikis: ComputedRef<WikiModel[]> = computed(() => wikiStore.getSearchedWikis().data);
const suggestIsLoading: ComputedRef<boolean> = computed(() => searchStore.suggestIsLoading);
const isSMWidth: ComputedRef<boolean> = computed(() => appStore.isSMWidth);
const allResultsIsEmpty: ComputedRef<boolean> = computed(
  () => !posts.value.length && !users.value.length && !groups.value.length && !wikis.value && !files.value
);
const files = ref<FileModel[]>([]);
//#endregion

//#region Methods
const setFocus = () => {
  if (searchbarRef.value) {
    searchbarRef.value.$el.setFocus();
  }
};

const onSearch = async (ev: any) => {
  const val = ev.target.value.trim();
  if (val) {
    appStore.$patch({ searchText: val });
  } else {
    appStore.$patch({ searchText: '' });
  }
};

const searchSuggestions = async (text: string): Promise<void> => {
  if (suggestionsEnabled.value) {
    await searchStore.suggest({
      text,
      type: SearchType.Users,
    });
    await searchStore.suggest({
      text,
      type: SearchType.Groups,
    });
  }
};

const searchPosts = async (text: string): Promise<boolean> =>
  await searchStore.search({ types: [SearchType.Posts], searchText: text });
const searchUsers = async (text: string): Promise<void> =>
  await userStore.chooseUserAutocomplete(text, UsersFilterEnum.UsersPage);
const searchGroups = async (text: string): Promise<void> => await groupStore.autocomplete(text, false);
const searchWikis = async (text: string): Promise<void> => await wikiStore.autocomplete(text);
const searchDocs = async (text: string): Promise<void> => {
  const documents = (await docStore.allDocs(DocBrowserModeEnum.All, '', text, '')).data;
  files.value =
    documents
      .filter((doc: DocModel) => doc.documentType === DocumentTypeEnum.File)
      .map((doc: DocModel) => doc.data as FileModel) ?? [];
};

const search = async () => {
  if (!searchText.value) return;
  searchIsLoading.value = true;

  const text = searchText.value;

  const promises = [
    searchSuggestions(text),
    searchPosts(text),
    searchUsers(text),
    searchGroups(text),
    searchWikis(text),
    searchDocs(text),
  ];

  Promise.allSettled(promises).then(() => {
    searchIsLoading.value = false;
    updateHistory();
  });
};

const changeSearchText = (text: string) => {
  appStore.$patch({ searchText: text });
};

const resetHistory = () => {
  appStore.$patch({ searchHistory: [] });
};

const resetSuggestions = () => {
  searchStore.$patch({ suggestions: [] });
};

const updateHistory = () => {
  appStore.$patch({
    searchHistory:
      searchText.value.length > 0 ? [searchText.value, ...searchHistory.value.splice(0, 2)] : searchHistory.value,
  });
};

const openUserProfile = async (userId: number) => {
  await router.push({
    name: ROUTES_NAME.USER_BY_ID,
    params: { id: userId },
  });
};

const openUsersPage = async () => {
  await router.push({
    name: ROUTES_NAME.USERS,
    query: { searchText: searchText.value },
  });
};

const openGroupPage = async (groupId: number) => {
  await router.push({
    name: ROUTES_NAME.GROUP_BY_ID,
    params: { id: groupId },
  });
};

const openGroupsPage = async () => {
  await router.push({
    name: ROUTES_NAME.GROUPS,
    query: { searchText: searchText.value },
  });
};

const openWiki = async (id: number) => {
  await useWiki().handleAction({ type: WikiActionEnum.ToCurrent, id });
};

const openFilesPage = () => {
  router.push({
    name: ROUTES_NAME.SEARCH,
    query: { types: SearchType.Files, text: searchText.value },
  });
};

const openWikisPage = () => {
  router.push({
    name: ROUTES_NAME.SEARCH,
    query: { types: SearchType.Wikis, text: searchText.value },
  });
};

const previewImage = async (file: FileModel) => {
  useFileActions().imageView(file);
};

const previewFile = async (id: number) => {
  await router.push({
    name: ROUTES_NAME.FILE_BY_ID,
    params: { id },
  });
};

const openAdvancedSearch = () => {
  router.push({
    name: ROUTES_NAME.SEARCH,
    query: { types: SearchType.Posts, searchText: searchText.value },
  });
};

const resetSearch = () => {
  appStore.$patch({ searchText: '' });
  postStore.$patch((state) => {
    state.postsIds.search.ids = [];
  });
  userStore.$patch((state) => {
    state.usersIds.chosen.others.ids = [];
  });
  groupStore.$patch((state) => {
    state.groupsIds.search.ids = [];
  });
  wikiStore.$patch((state) => {
    state.wikisIds.search.ids = [];
  });
  files.value = [];
};
//#endregion

//#region Watchers
watchDebounced(
  searchText,
  async () => {
    if (searchText.value) await search();
  },
  {
    debounce: 500,
  }
);
//#endregion

//#region Lifecycle
onMounted(async () => {
  await customScroll.init(document);
  if (props.searchbarEnabled) {
    resetSearch();
    await nextTick();
    setFocus();
  }
});

onUnmounted(() => {
  if (props.searchbarEnabled) {
    resetSearch();
  }
});
//#endregion
</script>

<style scoped lang="scss">
.app-col {
  --ion-grid-column-padding: 0;
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }

  ion-item {
    --background: transparent;
  }
}

section {
  margin-bottom: app-padding(md);
}

ion-item {
  --inner-padding-start: 0 !important;
  --padding-start: 0 !important;
  --background: transparent;
  ion-icon {
    margin-inline-end: app-padding(md);
  }
  &.small {
    font-size: 0.8rem;
    ion-label {
      line-height: 1;
      p {
        font-size: 0.7rem;
      }
    }
  }
}
ion-list {
  background: transparent;
}
ion-list-header {
  font-size: 1.1rem;
  margin-top: 0;
  min-height: 0;
  background: transparent;
  padding-inline-start: 0 !important;
  ion-label,
  ion-button {
    margin-top: 0;
  }

  .clear_button {
    @include resetStyleFromIonicButton;
    margin-top: 0;
    height: 30px;
    @include secondaryButton;
  }
  &.flex ion-label {
    display: flex;
    align-items: center;
  }
  &.hoverable {
    ion-label:hover {
      opacity: 0.7;
      cursor: pointer;
    }
  }
  &.small {
    font-size: 0.9rem !important;
  }
}

.more_button {
  @include resetStyleFromIonicButton;
  margin-top: app-padding(lg);
  --border-radius: #{app-padding(md)};
  @include secondaryButton;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--ion-color-light-background-contrast-rgb), 0.5);
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.empty-results {
  display: flex;
  justify-content: center;
  padding: app-padding(lg);
}

.search_suggestion {
  ion-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}
ion-searchbar {
  @include resetStyleFromIonicSearchbar;
  padding: 0;
}
@include fadeEnterTransition;
</style>

import { cloneDeep, find, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { $api } from '@/services';
import type { EntityState } from '@/store';
import type { BadgeModel, ErrorMessageModel, ResponseBadgesModel, ResponseErrorModel } from '@/types';
import { markEnd, markStart } from '@/helpers/performance';

type BadgeState = EntityState<BadgeModel>;

export const useBadgesStore = defineStore({
  id: 'badges',
  state: (): BadgeState => ({
    data: [],
    errors: [],
    isLoading: false,
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getBadges: (state) => {
      return state.data;
    },
    getBadgeById:
      (state) =>
      (id: number): BadgeModel | undefined =>
        find(state.data, (badge) => badge.id === id),
  },
  actions: {
    //#region API
    async badgesAll(): Promise<void> {
      markStart('badgesAll');

      this.errors = [];
      this.isLoading = true;
      const response = await $api.badge.getBadges();

      if (response.statusCode === 200) {
        const model = response as ResponseBadgesModel;
        this.data = model.data;
      } else {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      markEnd('badgesAll');
    },
    async badgesByIds(ids: number[]): Promise<void> {
      markStart('badgesByIds');

      this.errors = [];
      this.isLoading = true;
      const response = await $api.badge.getBadgesById(ids);

      if (response.statusCode === 200) {
        const model = response as ResponseBadgesModel;
        this.data = mergeById(this.data, model.data);
      } else {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      markEnd('badgesByIds');
    },
    //#endregion
  },
  persist: true,
});

const mergeById = (a: BadgeModel[], b: BadgeModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

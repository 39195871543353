import type {
  DocModel,
  FileModel,
  UserModel,
  WikiModel,
  FolderModel,
  ResponseErrorModel,
  GroupModel,
  TopicModel,
  WikiTemplateModel,
  PostModel,
  PostModelWithLink,
  Date16,
  DateISOUTC,
  Date23UTC,
  Date11,
  DateISO,
  UserShortModel,
  GroupShortModel,
  AuthTokenModel,
} from '@/types';
import { isMatch } from 'date-fns';

/** Checks if the provided data is a FileModel */
export function isFileGuard(data: unknown): data is FileModel {
  return data !== null && typeof data === 'object' && typeof (data as FileModel).fileId === 'number';
}

/** Checks if the provided data is a WikiModel */
export function isWikiGuard(data: unknown): data is WikiModel {
  return data !== null && typeof data === 'object' && typeof (data as WikiModel).wikiContent === 'object';
}

/** Checks if the provided data is a WikiTemplateModel */
export function isWikiTemplateGuard(data: unknown): data is WikiTemplateModel {
  return data !== null && typeof data === 'object' && typeof (data as WikiTemplateModel).name === 'string';
}

/** Checks if the provided data is a FolderModel */
export function isFolderGuard(data: unknown): data is FolderModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    !('fileId' in data) &&
    !('wikiContent' in data) &&
    typeof (data as FolderModel).id === 'number'
  );
}

/** Checks if the provided data is a UserModel */
export function isUserGuard(data: unknown): data is UserModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    'fullNameShort' in data &&
    'birthDate' in data &&
    'aboutMe' in data &&
    'education' in data &&
    'hobby' in data
  );
}

/** Checks if the provided data is a UserShortModel */
export function isUserShortGuard(data: unknown): data is UserShortModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    'id' in data &&
    'fullName' in data &&
    'mainAlias' in data &&
    'isActive' in data &&
    'image' in data &&
    'badges' in data
  );
}

/** Checks if the provided data is a UserModel[] */
export function isUsersGuard(data: unknown): data is UserModel[] {
  return Array.isArray(data) && data.length > 0 && data.every((item) => isUserGuard(item));
}

/** Checks if the provided data is a UserGroupShortModel */
export function isGroupShortGuard(data: unknown): data is GroupShortModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    'id' in data &&
    'mainAlias' in data &&
    'title' in data &&
    'type' in data &&
    'image' in data &&
    !('adminIds' in data)
  );
}

/** Checks if the provided data is a GroupModel */
export function isGroupGuard(data: unknown): data is GroupModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    typeof (data as GroupModel).isMandant === 'boolean' &&
    typeof (data as GroupModel).showMembers === 'boolean'
  );
}

/** Checks if the provided data is a GroupModel[] */
export function isGroupsGuard(data: unknown): data is GroupModel[] {
  return Array.isArray(data) && data.length > 0 && data.every((item) => isGroupGuard(item));
}

/** Checks if the provided data is a PostModel */
export function isPostGuard(data: unknown): data is PostModel {
  return data !== null && typeof data === 'object' && 'ccUsers' in data;
}

/** Checks if the provided data is a PostModelWithLink */
export function isPostModelWithLinkGuard(data: unknown): data is PostModelWithLink {
  return data !== null && typeof data === 'object' && 'ccUsers' in data && 'url' in data;
}

/** Checks if the provided data is a TopicModel */
export function isTopicGuard(data: unknown): data is TopicModel {
  return data !== null && typeof data === 'object' && typeof (data as TopicModel).followersCount === 'number';
}

/** Checks if the provided data is a TopicModel[] */
export function isTopicsGuard(data: unknown): data is TopicModel[] {
  return Array.isArray(data) && data.length > 0 && data.every((item) => isTopicGuard(item));
}

/** Checks if the provided data is a DocModel */
export function isDocGuard(data: unknown): data is DocModel {
  return data !== null && typeof data === 'object' && typeof (data as DocModel).documentType === 'string';
}

/** the provided data is a DocModel[] */
export function isDocsGuard(data: unknown): data is DocModel[] {
  return Array.isArray(data) && data.length > 0 && data.every((item) => isDocGuard(item));
}

/** Checks if the provided data is a Blob */
export function isBlob(data: unknown): data is Blob {
  return (
    data instanceof Blob || (typeof Blob !== 'undefined' && Object.prototype.toString.call(data) === '[object Blob]')
  );
}

/** Checks if the provided data is a ResponseErrorModel */
export function isResponseErrorGuard(data: unknown): data is ResponseErrorModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    typeof (data as ResponseErrorModel).traceId === 'string' &&
    typeof (data as ResponseErrorModel).errors === 'object' &&
    // typeof (data as ResponseErrorModel).type === 'string' &&
    // typeof (data as ResponseErrorModel).errorType === 'string' &&
    Array.isArray((data as ResponseErrorModel).errorMessages) &&
    (data as ResponseErrorModel).errorMessages.every((m) => typeof m.key === 'string' && Array.isArray(m.errors))
  );
}

export function isDate11(value: unknown): value is Date11 {
  return typeof value === 'string' && value.length === 11 && isMatch(value, 'dd MMM yyyy');
}

export function isDate16(value: unknown): value is Date16 {
  return typeof value === 'string' && value.length === 16 && isMatch(value, 'yyyy-MM-dd HH:mm');
}

export function isDateISO(value: unknown): value is DateISO {
  return typeof value === 'string' && value.length === 19 && isMatch(value, "yyyy-MM-dd'T'HH:mm:ss");
}

export function isDateISOUTC(value: unknown): value is DateISOUTC {
  return typeof value === 'string' && value.length === 20 && isMatch(value, "yyyy-MM-dd'T'HH:mm:ss'Z'");
}

export function isDate23UTC(value: unknown): value is Date23UTC {
  return typeof value === 'string' && value.length === 23 && isMatch(value, 'yyyy-MM-dd HH:mm:ss zzz');
}

export function isAuthTokenModelGuard(data: unknown): data is AuthTokenModel {
  return (
    data !== null &&
    typeof data === 'object' &&
    typeof (data as AuthTokenModel).homeAccessToken === 'string' &&
    typeof (data as AuthTokenModel).homeValidUntil === 'string' &&
    typeof (data as AuthTokenModel).accessToken === 'string' &&
    typeof (data as AuthTokenModel).validUntil === 'string' &&
    typeof (data as AuthTokenModel).coreId === 'string' &&
    typeof (data as AuthTokenModel).companyRowId === 'string' &&
    typeof (data as AuthTokenModel).userId === 'number' &&
    typeof (data as AuthTokenModel).userRowId === 'string'
  );
}

/**
 * Checks if the provided data is a Response
 * @see `node_modules/.pnpm/undici-types@6.20.0/node_modules/undici-types/fetch.d.ts`
 */
export const isResponse = (error: unknown): error is Response => {
  return error instanceof Response;
};

/**
 * Checks if the provided data is a Error same as isNativeError
 * @see `node_modules/.pnpm/@types+node@22.10.1/node_modules/@types/node/util.d.ts`
 */
export const isNativeError = (data: unknown): data is Error => {
  return data instanceof Error;
};

import { ResponseErrorCodesEnum } from '@/enums';
import { isResponse, isResponseErrorGuard, isNativeError } from '@/helpers/guards';
import { useI18n } from '@/i18n';
import { ResponseErrorModel } from '@/types';
import { AxiosError, isAxiosError, AxiosResponse, AxiosHeaders } from 'axios';

const _errorCodesMapping = (code: unknown): ResponseErrorCodesEnum => {
  const codeString = String(code);

  const map: Record<string, ResponseErrorCodesEnum> = Object.freeze({
    '400': ResponseErrorCodesEnum.BadRequest,
    '401': ResponseErrorCodesEnum.Unauthorized,
    '402': ResponseErrorCodesEnum.PaymentRequired,
    '403': ResponseErrorCodesEnum.Forbidden,
    '404': ResponseErrorCodesEnum.NotFound,
    '405': ResponseErrorCodesEnum.MethodNotAllowed,
    '406': ResponseErrorCodesEnum.NotAcceptable,
    '407': ResponseErrorCodesEnum.ProxyAuthenticationRequired,
    '408': ResponseErrorCodesEnum.RequestTimeout,
    '409': ResponseErrorCodesEnum.Conflict,
    '410': ResponseErrorCodesEnum.Gone,
    '411': ResponseErrorCodesEnum.LengthRequired,
    '412': ResponseErrorCodesEnum.PreconditionFailed,
    '413': ResponseErrorCodesEnum.RequestEntityTooLarge,
    '414': ResponseErrorCodesEnum.RequestUriTooLong,
    '415': ResponseErrorCodesEnum.UnsupportedMediaType,
    '416': ResponseErrorCodesEnum.RequestedRangeNotSatisfiable,
    '417': ResponseErrorCodesEnum.ExpectationFailed,
    '421': ResponseErrorCodesEnum.MisdirectedRequest,
    '422': ResponseErrorCodesEnum.UnprocessableEntity,
    '423': ResponseErrorCodesEnum.Locked,
    '424': ResponseErrorCodesEnum.FailedDependency,
    '426': ResponseErrorCodesEnum.UpgradeRequired,
    '428': ResponseErrorCodesEnum.PreconditionRequired,
    '429': ResponseErrorCodesEnum.TooManyRequests,
    '431': ResponseErrorCodesEnum.RequestHeaderFieldsTooLarge,
    '451': ResponseErrorCodesEnum.UnavailableForLegalReasons,
    '500': ResponseErrorCodesEnum.InternalServerError,
    '501': ResponseErrorCodesEnum.NotImplemented,
    '502': ResponseErrorCodesEnum.BadGateway,
    '503': ResponseErrorCodesEnum.ServiceUnavailable,
    '504': ResponseErrorCodesEnum.GatewayTimeout,
    '505': ResponseErrorCodesEnum.HttpVersionNotSupported,
    '506': ResponseErrorCodesEnum.VariantAlsoNegotiates,
    '507': ResponseErrorCodesEnum.InsufficientStorage,
    '508': ResponseErrorCodesEnum.LoopDetected,
    '510': ResponseErrorCodesEnum.NotExtended,
    '511': ResponseErrorCodesEnum.NetworkAuthenticationRequired,
  });

  return map[codeString] ?? ResponseErrorCodesEnum.UNKNOWN_ERROR;
};

/**
 * Use it to build a {@link ResponseErrorModel} from an unknown error
 *
 * @note If error is {@link ResponseErrorModel}, it will be returned as is
 *
 * @note If error is AxiosError it will check if inside, response.data is {@link ResponseErrorModel}
 * - if so, it will return that {@link ResponseErrorModel}
 * - if not, it will use
 *  - `error.code` as statusCode (default 500)
 *  - `error.config.headers['X-Trace-Id']` as traceId (default new Date().toISOString())
 *  - `error.message` as message in errors {}
 *  - {@link ResponseErrorCodesEnum.UNKNOWN_ERROR} as axios errorType
 *  - `error.message` as message in errorMessages[0] {}
 *
 * @note If error is Response, it will use
 *  - `error.status` as statusCode
 *  - `error.headers.get('X-Trace-Id')` as traceId (default new Date().toISOString())
 *  - `error.statusText` as message in errors {}
 *  - {@link ResponseErrorCodesEnum.UNKNOWN_ERROR} as axios errorType
 *  - `error.statusText` as message in errorMessages[0] {}
 *
 * @note If error is native Error, it will use
 *  - 500 as statusCode
 *  - new Date().toISOString() as traceId
 *  - `t('errorGeneric')` as message in errors {}
 *  - {@link ResponseErrorCodesEnum.UNKNOWN_ERROR} as axios errorType
 *  - `t('errorGeneric')` as message in errorMessages[0] {}
 */
export const buildErrorResponse = (error: unknown): ResponseErrorModel => {
  const { t } = useI18n();

  if (isResponseErrorGuard(error)) return error;

  let responseError: ResponseErrorModel = {
    statusCode: 500,
    traceId: new Date().toISOString(),
    errors: {
      'unknown-error': [t('errorGeneric')],
    },
    type: ResponseErrorCodesEnum.UNKNOWN_ERROR,
    errorType: ResponseErrorCodesEnum.UNKNOWN_ERROR,
    errorMessages: [
      {
        key: 'unknown-error',
        errors: [t('errorGeneric')],
      },
    ],
  };

  if (isAxiosError(error)) {
    if (isResponseErrorGuard(error.response?.data)) {
      responseError = error.response.data;
    } else {
      const fromAxiosError = {
        statusCode: error.code || 500,
        traceId: error.config?.headers?.['X-Trace-Id'] || new Date().toISOString(),
        errors: {
          'unknown-error': [error.message],
        },
        type: _errorCodesMapping(error.code),
        errorType: _errorCodesMapping(error.code),
        errorMessages: [
          {
            key: 'unknown-error',
            errors: [error.message],
          },
        ],
      };
      responseError = fromAxiosError;
    }
  } else if (isResponse(error)) {
    const fromResponse = {
      statusCode: error.status,
      traceId: error.headers?.get('X-Trace-Id') || new Date().toISOString(),
      errors: {
        'unknown-error': [error.statusText],
      },
      type: _errorCodesMapping(error.status),
      errorType: _errorCodesMapping(error.status),
      errorMessages: [
        {
          key: 'unknown-error',
          errors: [error.statusText],
        },
      ],
    };
    responseError = fromResponse;
  } else if (isNativeError(error)) {
    const fromNativeError = {
      statusCode: 500,
      traceId: new Date().toISOString(),
      errors: {
        'unknown-error': [error.message],
      },
      type: ResponseErrorCodesEnum.ClientError,
      errorType: ResponseErrorCodesEnum.ClientError,
      errorMessages: [
        {
          key: 'unknown-error',
          errors: [error.message],
        },
      ],
    };
    responseError = fromNativeError;
  }

  return responseError;
};

/**
 * Use it to build an AxiosError<{@link ResponseErrorModel}> from an unknown error
 *
 * @note If error is an AxiosError, it will be returned as is
 *
 * @note If error is a Response, it will use
 * - `error.statusText` as axios error message
 * - `error.status` as axios error code
 *
 * @note If error is a native Error, it will use
 * - `error.message` as axios error message
 * - {@link ResponseErrorCodesEnum.UNKNOWN_ERROR} as axios error code
 *
 * @note If error is neither of the above, it will use
 * - `t('errorGeneric')` as axios error message
 * - {@link ResponseErrorCodesEnum.UNKNOWN_ERROR} as axios error code
 */
export const buildAxiosError = (error: unknown): AxiosError<ResponseErrorModel> => {
  const { t } = useI18n();

  if (isAxiosError(error)) return error;

  const axiosResponse: AxiosResponse<ResponseErrorModel> = {
    data: buildErrorResponse(error),
    status: Number(buildErrorResponse(error).statusCode),
    statusText: buildErrorResponse(error).errorMessages[0].errors[0],
    headers: {
      'X-Trace-Id': new Date().toISOString(),
    },
    config: {
      headers: new AxiosHeaders(),
    },
  };

  let axiosError: AxiosError<ResponseErrorModel> = new AxiosError(
    t('errorGeneric'),
    ResponseErrorCodesEnum.UNKNOWN_ERROR,
    undefined,
    undefined,
    axiosResponse
  );

  if (isResponse(error)) {
    const fromResponse: AxiosError<ResponseErrorModel> = new AxiosError(
      error.statusText,
      String(error.status),
      undefined,
      undefined,
      axiosResponse
    );
    axiosError = fromResponse;
  } else if (isNativeError(error)) {
    const fromNativeError: AxiosError<ResponseErrorModel> = new AxiosError(
      error.message,
      ResponseErrorCodesEnum.ClientError,
      undefined,
      undefined,
      axiosResponse
    );
    axiosError = fromNativeError;
  }

  return axiosError;
};

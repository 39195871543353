import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import type { RouteLocationNormalizedLoaded, RouteRecordNameGeneric, RouteRecordRaw } from 'vue-router';

import { UserRoleEnum, RouteWithSearchEnum, RequirementsEnum } from '@/enums';
import { useTaskManagement, useAiAssistant, useRequirements, useWiki, useErrors, useSession } from '@/helpers';
import { useI18n } from '@/i18n';
import {
  useUserStore,
  useAppStore,
  useGroupsStore,
  useCustomPageStore,
  useTopicStore,
  useWikiStore,
  useAuthStore,
} from '@/store';

declare module 'vue-router' {
  interface RouteMeta {
    isActive?: boolean;
  }
}

export const ROUTES_NAME: Record<string, string> = {
  HOME: 'Home',
  LOGIN: 'Login',
  REGISTRATION: 'Registration',
  ACTIVATION: 'Activation',
  MESSENGER_ACTIVE: 'Messenger',
  MESSENGER_ARCHIVE: 'MessengerArchive',
  MESSENGER_CHAT_BY_CHAIN: 'MessengerChatByChain',
  MESSENGER_CHAT_BY_USER: 'MessengerChatByUser',
  USERS: 'Users',
  USER_BY_ID: 'UserById',
  NOT_FOUND: 'NotFound',
  GROUPS: 'Groups',
  GROUP_BY_ID: 'GroupById',
  GROUP_DASHBOARD: 'GroupDashboard',
  NOTIFICATIONS: 'Notifications',
  FEED: 'Feed',
  POST_BY_ID: 'PostById',
  DOCS: 'Docs',
  FILE_BY_ID: 'FileById',
  PAGES: 'Pages',
  PAGE_BY_ID: 'PageById',
  PAGE_EDIT: 'PageEdit',
  CALENDAR: 'Calendar',
  SEARCH: 'Search',
  WIKIS: 'Wikis',
  WIKI_BY_ID: 'WikiById',
  WIKI_EDIT: 'WikiEdit',
  WIKI_CREATE: 'WikiCreate',
  WIKI_COMPARE: 'WikiCompare',
  TOPICS: 'Topics',
  TOPIC_BY_ID: 'TopicById',
  USAGE_RULES: 'UsageRules',
  PROJECT_BY_ID: 'ProjectById',
  PROJECTS: 'Projects',
  PROJECTS_STATISTICS: 'ProjectsStatistics',
  TASKS: 'Tasks',
  MILESTONES: 'Milestones',
  MILESTONE_BY_ID: 'MilestoneById',
  OFFICE: 'Office',
  AI_ASSISTANT: 'AiAssistant',
  IDEAS: 'Ideas',
  NETWORKS: 'Networks',
  LOADING: 'Loading',
  SETTINGS: 'Settings',
  ADMIN_DESIGN: 'AdminDesign',
  ADMIN_EMAIL_FOOTER: 'AdminEmailFooter',
  ADMIN_NETWORK_SETTINGS: 'AdminNetworkSettings',
  ADMIN_NETWORK_DOMAIN_LIST: 'AdminNetworkDomainList',
  ADMIN_BRANDING: 'AdminBranding',
  ADMIN_MOBILE_APPS: 'AdminMobileApps',
  ADMIN_USAGE_RULES: 'AdminUsageRules',
  ADMIN_PASSWORD_SETTINGS: 'AdminPasswordSettings',
  ADMIN_APPLICATIONS: 'AdminApplications',
  ADMIN_STATISTICS: 'AdminStatistics',
  ADMIN_BANNER: 'AdminBanner',
  ADMIN_TAGS: 'AdminTags',
  ADMIN_USER_MANAGEMENT: 'AdminUserManagement',
  ADMIN_INVITE_USER: 'AdminInviteUser',
  ADMIN_RESTORE_POST: 'AdminRestorePost',
  ADMIN_BADGES: 'AdminBadges',
  UI_KIT_ICONS: 'UiKitIcons',
  AUTH: 'Auth',
};

export const NO_AUTH_ROUTES: string[] = [ROUTES_NAME.LOGIN, ROUTES_NAME.REGISTRATION, ROUTES_NAME.ACTIVATION];

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ROUTES_NAME.HOME,
    component: () => import('@/views/HomePage.vue'),
  },
  {
    path: '/login',
    name: ROUTES_NAME.LOGIN,
    component: () => import('@/views/Auth/LoginPage.vue'),
    meta: { layout: 'auth' },
  },
  ...(import.meta.env.VITE_ENABLE_REGISTRATION !== 'false'
    ? [
        {
          path: '/registration',
          name: ROUTES_NAME.REGISTRATION,
          component: () => import('@/views/Auth/RegistrationPage.vue'),
          meta: { layout: 'auth' },
        },
        {
          path: '/activation/:id',
          name: ROUTES_NAME.ACTIVATION,
          component: () => import('@/views/Auth/RegistrationPage.vue'),
          meta: { layout: 'auth' },
        },
      ]
    : []),
  {
    path: '/messenger',
    name: ROUTES_NAME.MESSENGER_ACTIVE,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: false },
  },
  {
    path: '/messenger/archive',
    name: ROUTES_NAME.MESSENGER_ARCHIVE,
    component: () => import('@/views/Messenger/MessengerPage.vue'),
    meta: { isMessenger: true, isArchive: true },
  },
  {
    path: '/users',
    name: ROUTES_NAME.USERS,
    component: () => import(`@/views/Users/UsersPage.vue`),
    props: (route) => ({ searchText: route.query.searchText }),
  },
  {
    path: '/user/:id',
    name: ROUTES_NAME.USER_BY_ID,
    component: () => import('@/views/Users/UserPage.vue'),
  },
  {
    path: '/messenger/chain/:id',
    name: ROUTES_NAME.MESSENGER_CHAT_BY_CHAIN,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'chain', isMessenger: true },
  },
  {
    path: '/messenger/user/:id',
    name: ROUTES_NAME.MESSENGER_CHAT_BY_USER,
    component: () => import('@/views/Messenger/ChatPage.vue'),
    meta: { type: 'user', isMessenger: true },
  },
  {
    path: '/groups',
    name: ROUTES_NAME.GROUPS,
    component: () => import('@/views/Groups/GroupsPage.vue'),
    props: (route) => ({ searchText: route.query.searchText }),
  },
  {
    path: '/group/:id',
    name: ROUTES_NAME.GROUP_BY_ID,
    component: () => import('@/views/Groups/GroupPage.vue'),
  },
  {
    path: '/group/:id/dashboard',
    name: ROUTES_NAME.GROUP_DASHBOARD,
    component: () => import('@/views/Groups/GroupDashboardPage.vue'),
  },
  {
    path: '/notifications',
    name: ROUTES_NAME.NOTIFICATIONS,
    component: () => import('@/views/Notifications/NotificationsPage.vue'),
  },
  {
    path: '/feed',
    name: ROUTES_NAME.FEED,
    component: () => import('@/views/Feed/FeedPage.vue'),
  },
  {
    path: '/ideas',
    name: ROUTES_NAME.IDEAS,
    component: () => import('@/views/Ideas/IdeasPage.vue'),
  },
  {
    path: '/post/:id',
    name: ROUTES_NAME.POST_BY_ID,
    component: () => import('@/views/FeedItem/FeedItemPage.vue'),
  },
  {
    path: '/docs/:pathMatch(.*)*',
    name: ROUTES_NAME.DOCS,
    component: () => import(`@/views/Docs/DocsPage.vue`),
  },
  {
    path: '/file/:id',
    name: ROUTES_NAME.FILE_BY_ID,
    component: () => import(`@/views/File/FilePage.vue`),
  },
  {
    path: '/pages',
    name: ROUTES_NAME.PAGES,
    component: () => import(`@/views/CustomPages/CustomPages.vue`),
    meta: { requiresStandardUserRights: true },
  },
  {
    path: '/page/:id',
    name: ROUTES_NAME.PAGE_BY_ID,
    component: () => import(`@/views/CustomPages/CustomPage.vue`),
  },
  {
    path: '/page/:id/edit',
    name: ROUTES_NAME.PAGE_EDIT,
    component: () => import(`@/views/CustomPages/CustomPageEdit.vue`),
    meta: { requiresStandardUserRights: true },
  },
  {
    path: '/calendar',
    name: ROUTES_NAME.CALENDAR,
    component: () => import('@/views/Calendar/CalendarPage.vue'),
  },
  {
    path: '/wikis',
    name: ROUTES_NAME.WIKIS,
    component: () => import('@/views/Wikis/WikisPage.vue'),
  },
  {
    path: '/wiki/:id/:versionId?',
    name: ROUTES_NAME.WIKI_BY_ID,
    component: () => import('@/views/Wikis/WikiPage.vue'),
  },
  {
    path: '/wiki/edit/:id',
    name: ROUTES_NAME.WIKI_EDIT,
    component: () => import(`@/views/Wikis/WikiEditPage.vue`),
  },
  {
    path: '/wiki/create',
    name: ROUTES_NAME.WIKI_CREATE,
    component: () => import(`@/views/Wikis/WikiCreatePage.vue`),
  },
  {
    path: '/wiki/compare/:id',
    name: ROUTES_NAME.WIKI_COMPARE,
    component: () => import(`@/views/Wikis/WikiComparePage.vue`),
    props: (route) => ({ ...route.params, ...route.query }),
  },
  {
    path: '/topics',
    name: ROUTES_NAME.TOPICS,
    component: () => import('@/views/Topics/TopicsPage.vue'),
  },
  {
    path: '/topic/:id',
    name: ROUTES_NAME.TOPIC_BY_ID,
    component: () => import(`@/views/Topics/TopicPage.vue`),
  },
  {
    path: '/search',
    name: ROUTES_NAME.SEARCH,
    component: () => import('@/views/Search/SearchPage.vue'),
  },
  {
    path: '/admin/design',
    name: ROUTES_NAME.ADMIN_DESIGN,
    component: () => import('@/views/Admin/Design/AdminDesignPage.vue'),
    meta: { requiresModeratorRights: true },
  },
  {
    path: '/admin/email-footer',
    name: ROUTES_NAME.ADMIN_EMAIL_FOOTER,
    component: () => import('@/views/Admin/AdminEmailFooterPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/network-settings',
    name: ROUTES_NAME.ADMIN_NETWORK_SETTINGS,
    component: () => import('@/views/Admin/NetworkSettings/AdminNetworkSettingsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/domain-list',
    name: ROUTES_NAME.ADMIN_NETWORK_DOMAIN_LIST,
    component: () => import('@/views/Admin/NetworkSettings/AdminNetworkDomainListPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/branding',
    name: ROUTES_NAME.ADMIN_BRANDING,
    component: () => import('@/views/Admin/NetworkSettings/AdminBrandingPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/mobile-apps',
    name: ROUTES_NAME.ADMIN_MOBILE_APPS,
    component: () => import('@/views/Admin/NetworkSettings/AdminMobileAppsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/usage-rules',
    name: ROUTES_NAME.ADMIN_USAGE_RULES,
    component: () => import('@/views/Admin/AdminUsageRulesPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/password-settings',
    name: ROUTES_NAME.ADMIN_PASSWORD_SETTINGS,
    component: () => import('@/views/Admin/AdminPasswordSettingsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/apps',
    name: ROUTES_NAME.ADMIN_APPLICATIONS,
    component: () => import('@/views/Admin/AdminApplicationsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/statistics',
    name: ROUTES_NAME.ADMIN_STATISTICS,
    component: () => import('@/views/Admin/AdminStatisticsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/banner',
    name: ROUTES_NAME.ADMIN_BANNER,
    component: () => import('@/views/Admin/AdminBannerPage.vue'),
    meta: { requiresAdminRights: true },
  },

  {
    path: '/admin/tags',
    name: ROUTES_NAME.ADMIN_TAGS,
    component: () => import('@/views/Admin/AdminTagsPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/user-management',
    name: ROUTES_NAME.ADMIN_USER_MANAGEMENT,
    component: () => import('@/views/Admin/AdminUserManagementPage.vue'),
    meta: { requiresModeratorRights: true },
  },
  ...(import.meta.env.VITE_ENABLE_ADMIN_REGISTRATION === 'true' ||
  import.meta.env.VITE_ENABLE_ADMIN_INVITATION === 'true'
    ? [
        {
          path: '/admin/user-management/new',
          name: ROUTES_NAME.ADMIN_INVITE_USER,
          component: () => import('@/views/Admin/AdminInviteUserPage.vue'),
          meta: { requiresAdminRights: true },
        },
      ]
    : []),
  {
    path: '/admin/restore-post',
    name: ROUTES_NAME.ADMIN_RESTORE_POST,
    component: () => import('@/views/Admin/AdminRestorePostPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/admin/badges',
    name: ROUTES_NAME.ADMIN_BADGES,
    component: () => import('@/views/Admin/AdminBadgesPage.vue'),
    meta: { requiresAdminRights: true },
  },
  {
    path: '/usage-rules',
    name: ROUTES_NAME.USAGE_RULES,
    component: () => import('@/views/Networks/UsageRules/UsageRulesView.vue'),
  },
  {
    path: '/projects',
    name: ROUTES_NAME.PROJECTS,
    component: () => import('@/views/Projects/ProjectsPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/project/:projectId',
    name: ROUTES_NAME.PROJECT_BY_ID,
    component: () => import('@/views/Projects/ProjectPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/project/:projectId/milestones',
    name: ROUTES_NAME.MILESTONES,
    component: () => import('@/views/Projects/MilestonesPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  /* {
    path: '/projects/statistics',
    name: ROUTES_NAME.PROJECTS_STATISTICS,
    component: () => import('@/views/Projects/StatisticsPage.vue'),
    meta: { requiresProjectsPermission: true },
  }, */
  {
    path: '/tasks',
    name: ROUTES_NAME.TASKS,
    component: () => import('@/views/Projects/TasksPage.vue'),
    meta: { requiresProjectsPermission: true },
  },
  {
    path: '/account/settings',
    name: ROUTES_NAME.SETTINGS,
    component: () => import('@/views/Settings/SettingsPage.vue'),
  },
  /* {
    path: '/project/:projectId/milestone/:milestoneId',
    name: ROUTES_NAME.MILESTONE_BY_ID,
    component: () => import('@/views/Projects/MilestonePage.vue'),
  }, */
  {
    path: '/office/:payload',
    name: ROUTES_NAME.OFFICE,
    component: () => import('@/views/Office/OfficeView.vue'),
    props: (route) => ({ ...route.params, ...route.query }),
  },
  {
    path: '/ai',
    name: ROUTES_NAME.AI_ASSISTANT,
    component: () => import('@/views/AiAssistant/AiAssistantPage.vue'),
    meta: { requiresAiAssistantPermission: true },
  },

  ...(__DEV__
    ? [
        {
          path: '/networks',
          name: ROUTES_NAME.NETWORKS,
          component: () => import('@/views/Networks/NetworksPage.vue'),
        },
        {
          path: '/ui-kit/icons',
          name: ROUTES_NAME.UI_KIT_ICONS,
          component: () => import('@/views/UiKit/UiKitIconsPage.vue'),
        },
      ]
    : []),
  {
    path: '/loading',
    name: ROUTES_NAME.LOADING,
    component: () => import('@/views/Common/LoadingPage.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: ROUTES_NAME.NOT_FOUND,
    component: () => import('@/views/Common/NotFound.vue'),
  },
  {
    path: '/auth',
    name: ROUTES_NAME.AUTH,
    component: () => import('@/views/Common/LoadingPage.vue'), // TODO: Add dedicated component for this case
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

/**
 * Used for setting the keepSearchQuery state
 *
 * @private
 */
const _setKeepSearchQueryState = (toRouteName: RouteRecordNameGeneric, fromRouteName: RouteRecordNameGeneric) => {
  const routeHandlers: Record<RouteWithSearchEnum, () => void> = Object.freeze({
    [RouteWithSearchEnum.GROUPS]: () => {
      useGroupsStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.GROUP_BY_ID,
      });
    },
    [RouteWithSearchEnum.USERS]: () => {
      useUserStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.USER_BY_ID,
      });
    },
    [RouteWithSearchEnum.PAGES]: () => {
      useCustomPageStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.PAGE_BY_ID,
      });
    },
    [RouteWithSearchEnum.TOPICS]: () => {
      useTopicStore().$patch({
        keepSearchQuery: fromRouteName === ROUTES_NAME.TOPIC_BY_ID,
      });
    },
  });

  const handler = routeHandlers[toRouteName as RouteWithSearchEnum];
  if (!handler) return;
  handler();
};

/**
 * Used for handling authorization on each route
 *
 * @private
 */
async function _handleAuth(to: RouteLocationNormalizedLoaded): Promise<boolean> {
  // Auth is OK => allow navigation
  if (useAuthStore().isAuth() || NO_AUTH_ROUTES.includes(String(to.name))) {
    console.log('[INFO] Already authorized or current route does not require auth');
    return true;
  }

  // Auth is NOT OK so
  // we need to check if redirect mode
  // or get accessToken (forcefully)
  if (to.name === ROUTES_NAME.AUTH) {
    await useAuthStore().token({
      isRedirect: true,
      silent: true,
      force: true,
      code: String(to.query.code),
    });

    // Cold start setup
    await useSession().setupApp(true, true, true);

    // Final check after cold initialization
    if (!useAuthStore().isAuth()) return false;

    // Ensure user data is loaded
    const user = await useUserStore().currentUser();
    if (!user) return false;

    // Final requirements check
    const requirementsMet = await useRequirements().check(RequirementsEnum.All);
    return requirementsMet ?? false;
  } else {
    await useAuthStore().token({ silent: true, force: true });
  }

  // We need to set redirectUrl
  // to keep where user wanted to go
  // Will be used in src/helpers/useSessionHelper.ts - _toApp()
  useAuthStore().setRedirectUrl(to.fullPath);

  // Auth is OK => allow navigation
  if (useAuthStore().isAuth()) return true;

  // Auth is NOT OK => deny navigation
  return false;
}

router.beforeEach(async (to, from): Promise<boolean> => {
  const appStore = useAppStore();
  const { handleError } = useErrors();
  const isAndroid = isPlatform('android') && !isPlatform('mobileweb');

  try {
    const isAuthenticated = await _handleAuth(to);
    if (!isAuthenticated) throw new Error('Failed to authenticate. Redirecting to login page...');

    // If going TO auth page
    // check auth status
    // if ok => redirect to home page
    // if not => redirect to login page
    if (to.name === ROUTES_NAME.AUTH) {
      await router.push({ name: useAuthStore().isAuth() ? ROUTES_NAME.HOME : ROUTES_NAME.LOGIN });
      return false;
    }

    // If going TO wiki edit page
    // we need to check if we should prevent it
    // and if so => lock it
    if (to.name === ROUTES_NAME.WIKI_EDIT) {
      const preventRoute = await useWiki().preventEdit(Number(to.params.id));
      if (preventRoute) return false;
      useWikiStore().setEditMode(true);
    }

    // If going FROM wiki edit page
    // we need to unlock it
    if (from.name === ROUTES_NAME.WIKI_EDIT) {
      await useWikiStore().unlockEdit(Number(from.params.id));
      useWikiStore().setEditMode(false);
    }

    // Setting keepSearchQuery param for pages with search bar
    _setKeepSearchQueryState(to.name, from.name);

    // If middle button is clicked
    // open the link in a new tab
    // and prevent further navigation
    if (appStore.isMiddleButtonClicked) {
      appStore.setMiddleButtonClicked(false);
      window.open(`${to.fullPath}`, '_blank');
      return false;
    }

    // If going TO home page
    // check if it's the same as the homePage
    // If so AND isAndroid => exit the app
    if (to.name === ROUTES_NAME.HOME && from.name === appStore.homePage.name) {
      if (isAndroid) {
        await App.exitApp();
        return false;
      }
      await router.push(appStore.homePage);
      return true;
    }

    return true;
  } catch (e: any) {
    const defaultText = `Error while performing route navigation. Failed to navigate from ${String(from.name)} to ${String(to.name)}`;
    handleError({
      show: false,
      error: undefined,
      message: e.message || defaultText,
    });
    await router.push({ name: ROUTES_NAME.LOGIN });
    return false;
  }
});

router.beforeResolve(async (to) => {
  const { handleError } = useErrors();
  const { getAccessToTaskManagement } = useTaskManagement();
  const { getAccessToAi } = useAiAssistant();
  const userRole = useUserStore().current?.roleId ?? 0;

  if (await useRequirements().requirementsCheckInProcess()) {
    const { t } = useI18n();
    handleError({
      show: true,
      error: undefined,
      message: t('read.required'),
    });
    return false;
  }

  const requireCase1 = to.meta.requiresModeratorRights && userRole < UserRoleEnum.Moderator;
  const requireCase2 = to.meta.requiresAdminRights && userRole < UserRoleEnum.Administrator;
  const requireCase3 = to.meta.requiresStandardUserRights && userRole < UserRoleEnum.User;
  const requireCase4 = to.meta.requiresProjectsPermission && !getAccessToTaskManagement();
  const requireCase5 = to.meta.requiresAiAssistantPermission && !getAccessToAi();

  if (requireCase1 || requireCase2 || requireCase3 || requireCase4 || requireCase5) {
    router.push({ name: ROUTES_NAME.FEED });
  } else {
    return true;
  }
});

router.afterEach((failure) => {
  const { handleError } = useErrors();
  if (failure) {
    handleError({
      show: false,
      error: undefined,
      message: `${failure}`,
    });
  }
});

export default router;

<template>
  <div class="custom-calendar">
    <div v-if="isLoading" class="custom-calendar-loader">
      <icons-provider
        :icon-props="{ width: '64', height: '64', strokewidth: 1.5 }"
        :name="AppIconsEnum.CircleAnim"
        fill="var(--ion-color-medium)"
      />
    </div>

    <vue-cal
      v-if="!calendarIsSmall"
      :id="`vuecal-${customUniqueId}`"
      v-model:active-view="activeView"
      v-tooltip.bottom="{
        content: $t('calendar.action'),
        theme: 'info-tooltip',
        delay: {
          show: 5000,
          hide: 0,
        },
        disabled: isAnyMobile,
      }"
      events-count-on-year-view
      events-on-month-view="true"
      :events="type === CalendarTypeEnum.CustomPage ? customPageEvents : events"
      :time-step="30"
      :disable-views="['years']"
      :on-event-click="handleEventClick"
      :drag-to-create-event="false"
      :dblclick-to-navigate="isNativeMobile || isWebMobile ? false : true"
      :locale="language"
      :editable-events="{ drag: true }"
      :transitions="false"
      cell-contextmenu
      resize-x
      :style="{
        height: type === CalendarTypeEnum.CustomPage ? '600px' : '100%',
      }"
      @cell-contextmenu="handleDesktopContextClick($event)"
      @cell-click="handleMobileCellClick($event)"
      @view-change="updatePeriod($event)"
      @event-drop="handleMoveEvent($event)"
      @ready="calendarInit($event)"
    >
      <template #no-event>
        <div></div>
      </template>

      <template #event="{ event }">
        <calendar-event-template :event="event" />
      </template>

      <template #title="{ title }">
        <div class="custom-title" @click.stop>
          <ion-item
            button
            :disabled="isLoading"
            mode="md"
            lines="none"
            class="source-select"
            @click="openSourcePopover($event)"
          >
            {{ title + ' - ' + sourceText }}
            <icons-provider
              v-if="!isXSWidth"
              slot="end"
              :name="AppIconsEnum.ChevronDown"
              :icon-props="{
                width: '18',
                height: '18',
                fill: 'var(--ion-color-medium)',
              }"
            />
          </ion-item>
        </div>
      </template>
    </vue-cal>

    <vue-cal
      v-else-if="calendarIsSmall"
      :id="`vuecal-${customUniqueId}`"
      v-model:active-view="activeView"
      v-tooltip.bottom="{
        content: $t('calendar.action'),
        theme: 'info-tooltip',
        delay: {
          show: 5000,
          hide: 0,
        },
        disabled: isAnyMobile,
      }"
      xsmall
      :events-count-on-year-view="false"
      hide-view-selector
      :locale="language"
      :disable-views="['years', 'year', 'week']"
      :time-step="30"
      :on-event-click="handleEventClick"
      :events="type === CalendarTypeEnum.CustomPage ? customPageEvents : events"
      cell-contextmenu
      :style="{ height: calendarHeight + 'px' }"
      :class="[{ 'dash-counter': calendarHeight <= 350 }]"
      @ready="calendarInit($event)"
      @view-change="updatePeriod($event)"
      @cell-contextmenu="handleDesktopContextClick($event)"
      @cell-click="handleMobileCellClick($event)"
    >
      <template #event="{ event }">
        <calendar-event-template :event="event" />
      </template>
    </vue-cal>
  </div>
</template>

<script lang="ts" setup>
import { IonItem } from '@ionic/vue';
import { uniqueId } from 'lodash';
import type { ComputedRef, PropType, Ref } from 'vue';
import { ref, computed } from 'vue';
import VueCal from 'vue-cal';
import { useRouter } from 'vue-router';

import { CalendarEventTemplate, IconsProvider } from '@/components';
import {
  CalendarCellActionEnum,
  CalendarTypeEnum,
  EventCalendarPeriodEnum,
  PostTypeActionEnum,
  CalendarViewModeEnum,
  ActionAccessEnum,
  CustomPageWidgetsPositionEnum,
  EventCalendarSourceEnum,
  GroupsFilterEnum,
  AppIconsEnum,
  WidgetCalendarPeriodEnum,
} from '@/enums';
import {
  DateHelper,
  openPostCreateMobileModal,
  openCalendarCellPopover,
  openCalendarEventListPopover,
  isNativeMobile,
  isWebMobile,
  openGroupSelectModal,
  openCalendarSourcePopover,
  isAnyMobile,
  openCalendarSourceSheet,
  openCalendarCellSheet,
  useToasts,
  convertIsoToDate16,
  convertStringToIsoUtc,
  addMinutesToDate,
  convertIsoUtcToLocalTimezone,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useEventStore, useAppStore, usePostStore, useGroupsStore } from '@/store';
import type {
  EventModel,
  CalendarEventModel,
  RequestEventEditModel,
  RequestEventsByPeriodModel,
  GroupModel,
  ConfigType,
  VueCalEventDrop,
  VueCalCellEvent,
} from '@/types';

const props = defineProps({
  type: {
    type: String as PropType<CalendarTypeEnum>,
    required: true,
  },
  source: {
    type: Number as PropType<EventCalendarSourceEnum>,
    required: true,
  },
  period: {
    type: Number as PropType<EventCalendarPeriodEnum | WidgetCalendarPeriodEnum>,
    required: true,
  },
  widgetPosition: {
    type: String as PropType<CustomPageWidgetsPositionEnum>,
    default: CustomPageWidgetsPositionEnum.MainWidgets,
  },
  groupId: {
    type: Number,
    default: 0,
  },
  height: {
    type: [Number, null],
    default: 300,
  },
});

//#region Variables
const router = useRouter();

const appStore = useAppStore();
const eventStore = useEventStore();
const postStore = usePostStore();
const groupStore = useGroupsStore();

const { t } = useI18n();
const { showSonnerToast } = useToasts();

const selectedSource = ref<EventCalendarSourceEnum>(props.source);
const selectedGroup = ref<GroupModel | undefined>(undefined);
const selectedEvent = ref<CalendarEventModel | undefined>(undefined);
const customPageEvents = ref<CalendarEventModel[]>([]);
const editLoading = ref<boolean>(false);
const customUniqueId = ref<string>(uniqueId());

const language: ComputedRef<string> = computed(() => appStore.locale);
const isXSWidth: ComputedRef<boolean> = computed(() => appStore.isXSWidth);
const isLoading: ComputedRef<boolean> = computed(() => eventStore.isLoading || editLoading.value);
const calendarIsSmall: ComputedRef<boolean> = computed(() =>
  props.widgetPosition === CustomPageWidgetsPositionEnum.RightSidebarWidgets ||
  props.widgetPosition === CustomPageWidgetsPositionEnum.LeftSidebarWidgets
    ? true
    : false
);

const config: ComputedRef<ConfigType> = computed(() => ({
  eventDialog: { isDisabled: true },
  dayIntervals: { displayClickableInterval: true },
  defaultMode: props.period === EventCalendarPeriodEnum.Month ? CalendarViewModeEnum.Month : CalendarViewModeEnum.Week,
  isSilent: false,
  locale: language.value,
  showCurrentTime: true,
  style: {
    colorSchemes: {
      default: {
        color: '#ffffff',
        backgroundColor: 'var(--ion-color-primary)',
      },
    },
  },
}));

const activeView: Ref<CalendarViewModeEnum> = ref(
  calendarIsSmall.value ? CalendarViewModeEnum.Month : config.value.defaultMode
);

const calendarHeight: ComputedRef<number> = computed(() => {
  if (!props.height) return 300;
  if (props.height < 300) return 300;
  return props.height;
});

const sourceText: ComputedRef<string> = computed(() => {
  switch (selectedSource.value) {
    case EventCalendarSourceEnum.All:
      return t('feed.event.allEvents');

    case EventCalendarSourceEnum.My:
      return t('feed.event.myEvents');

    case EventCalendarSourceEnum.Groups:
      return selectedGroup.value?.title || t('feed.event.groupEvents');

    default:
      return '';
  }
});

const events: ComputedRef<CalendarEventModel[]> = computed(() => getFormattedEvents(eventStore.getEvents));
//#endregion

//#region Methods
/**
 * @note Transforms server events into calendar-compatible
 */
function getFormattedEvents(serverEvents: EventModel[]): CalendarEventModel[] {
  try {
    const calendarEvents: CalendarEventModel[] = [];
    if (!serverEvents.length) return calendarEvents;

    serverEvents.forEach((element: EventModel) => {
      if (!element.eventData) throw new Error('eventData is missing');

      const isEditable = element.access.includes(ActionAccessEnum.Edit);
      // server date = ??? format (iso, 16 char, 23 char, 24 char)
      // => convert to iso
      // => convert to local timezone
      // => convert to 16 char date for vue-cal
      const start = convertIsoToDate16(convertIsoUtcToLocalTimezone(convertStringToIsoUtc(element.eventData.datetime)));
      const event: CalendarEventModel = {
        title: element.eventData?.title ?? '',
        start,
        end: addMinutesToDate(start, element.eventData.durationMinutes ?? 30),
        id: element.id,
        isEditable,
        location: element.eventData?.location ?? '',
        draggable: isEditable,
        class: element.eventData?.isBlocker ? 'is-blocker' : '',
      };
      calendarEvents.push(event);
    });

    return calendarEvents;
  } catch (e) {
    console.error('Error while getting formatted events:', e);
    return [];
  }
}

/**
 * @note Fetches events from the server for a given time range and updates the store
 */
async function getEventsByPeriod(
  start: Date | string,
  end: Date | string,
  source?: EventCalendarSourceEnum,
  group?: GroupModel
): Promise<void> {
  const periodData: RequestEventsByPeriodModel = {
    source: source ?? selectedSource.value,
    period: EventCalendarPeriodEnum.Period,
    start: DateHelper.getDateInUtc(new Date(start)),
    end: DateHelper.getDateInUtc(new Date(end)),
    groupId: group?.id ?? selectedGroup.value?.id,
  };

  eventStore.setCalendarPeriod({ start: periodData.start, end: periodData.end });

  const eventsData = await eventStore.eventsByPeriod(periodData);
  if (eventsData && props.type === CalendarTypeEnum.CustomPage) {
    customPageEvents.value = getFormattedEvents(eventsData);
  }
}

/**
 * @note Opens a single event when clicked
 */
async function handleEventClick(eventData: CalendarEventModel, e: MouseEvent | TouchEvent): Promise<void> {
  if (!isNativeMobile && 'button' in e && e.button !== 0) return;
  selectedEvent.value = eventData;
  await router.push({ name: ROUTES_NAME.POST_BY_ID, params: { id: eventData.id } });
}

/**
 * @note Updates events in store based on the new calendar view dates
 */
async function updatePeriod(ev: { startDate: Date; endDate: Date; view: CalendarViewModeEnum }): Promise<void> {
  await getEventsByPeriod(ev.startDate, ev.endDate, selectedSource.value, selectedGroup.value);

  const currentDate = new Date();
  if (ev.view === CalendarViewModeEnum.Week) {
    scrollToCurrentTime(currentDate);
    return;
  }

  if (ev.view === CalendarViewModeEnum.Day) {
    const list = props.type === CalendarTypeEnum.CustomPage ? customPageEvents.value : events.value;
    const eventToScroll = list.find((item) => new Date(item.start).getDay() === new Date(ev.startDate).getDay());
    scrollToCurrentTime(eventToScroll ? new Date(eventToScroll.start) : currentDate);
  }
}

/**
 * @note Handles drag-and-drop of a calendar event to a new date
 */
async function handleMoveEvent(ev: VueCalEventDrop): Promise<void> {
  editLoading.value = true;
  const zeroUTC = DateHelper.getUtcZero(ev.newDate);
  const result = await postStore.getPostFromId(ev.event.id);

  if (result) {
    const newEvent: RequestEventEditModel = {
      title: result.eventData?.title ?? '',
      text: result.bodyHtml,
      date: DateHelper.formatDateToISO(new Date(zeroUTC)),
      duration: result.eventData?.durationMinutes ?? 0,
      place: result.eventData?.location ?? '',
    };
    const success = await postStore.eventEdit(result.id, newEvent);
    showSonnerToast(
      success ? t('feed.conversationPostMenu.edit.postEdited') : t('feed.conversationPostMenu.edit.postNotEdited'),
      success
    );
  }
  editLoading.value = false;
}

async function _showEvents(ev: VueCalCellEvent): Promise<void> {
  const allEvents = props.type === CalendarTypeEnum.CustomPage ? customPageEvents.value : events.value;
  const filteredEvents = allEvents.filter((e) =>
    DateHelper.isDateWithinInterval(ev.date, new Date(e.start), new Date(e.end), true)
  );
  if (filteredEvents.length) {
    await openCalendarEventListPopover(ev.ev, filteredEvents);
  }
}

async function _goInside(): Promise<void> {
  if (calendarIsSmall.value) {
    if (activeView.value === CalendarViewModeEnum.Month) {
      activeView.value = CalendarViewModeEnum.Day;
    }
  } else {
    switch (activeView.value) {
      case CalendarViewModeEnum.Year:
        activeView.value = CalendarViewModeEnum.Month;
        break;

      case CalendarViewModeEnum.Month:
        activeView.value = CalendarViewModeEnum.Week;
        break;

      case CalendarViewModeEnum.Week:
        activeView.value = CalendarViewModeEnum.Day;
        break;
    }
  }
}

async function _goOutside(): Promise<void> {
  if (calendarIsSmall.value) {
    if (activeView.value === CalendarViewModeEnum.Day) {
      activeView.value = CalendarViewModeEnum.Month;
    }
  } else {
    switch (activeView.value) {
      case CalendarViewModeEnum.Month:
        activeView.value = CalendarViewModeEnum.Year;
        break;

      case CalendarViewModeEnum.Week:
        activeView.value = CalendarViewModeEnum.Month;
        break;

      case CalendarViewModeEnum.Day:
        activeView.value = CalendarViewModeEnum.Week;
        break;
    }
  }
}

/**
 * @note Handles right-click on a calendar cell (desktop)
 */
async function handleDesktopContextClick(ev: VueCalCellEvent): Promise<void> {
  !isAnyMobile && openCellMenu(ev);
}

/**
 * @note Handles touch on a calendar cell (mobile)
 */
async function handleMobileCellClick(date: string): Promise<void> {
  isAnyMobile && openCellMenu({ ev: undefined, date: new Date(date) });
}

/**
 * @note Opens the context menu or sheet for a calendar cell
 */
async function openCellMenu(ev: VueCalCellEvent): Promise<void> {
  const data = isAnyMobile
    ? await openCalendarCellSheet(activeView.value, calendarIsSmall.value)
    : await openCalendarCellPopover(ev.ev, activeView.value, calendarIsSmall.value);

  const calendarActionMap: Record<CalendarCellActionEnum, (ev: VueCalCellEvent) => Promise<void>> = {
    [CalendarCellActionEnum.CreateEvent]: () =>
      openPostCreateMobileModal(PostTypeActionEnum.CalendarEvent, ev.date.toISOString()),
    [CalendarCellActionEnum.ShowEvents]: () => _showEvents(ev),
    [CalendarCellActionEnum.GoInside]: () => _goInside(),
    [CalendarCellActionEnum.GoOutside]: () => _goOutside(),
  };

  data && (await calendarActionMap[data](ev));
}

/**
 * @note Initializes calendar on load, sets up period data, and fetches initial events
 */
async function calendarInit(ev: { startDate: Date; endDate: Date }): Promise<void> {
  selectedGroup.value = props.groupId ? groupStore.getGroupById(props.groupId) : undefined;

  const periodData: RequestEventsByPeriodModel = {
    source: props.type === CalendarTypeEnum.CustomPage ? selectedSource.value : EventCalendarSourceEnum.All,
    period: EventCalendarPeriodEnum.Period,
    start: new Date(ev.startDate).toISOString(),
    end: new Date(ev.endDate).toISOString(),
    groupId: props.type === CalendarTypeEnum.CustomPage ? selectedGroup.value?.id : undefined,
  };

  eventStore.setCalendarPeriod({ start: periodData.start, end: periodData.end });

  const eventsData = await eventStore.eventsByPeriod(periodData);

  if (eventsData && props.type === CalendarTypeEnum.CustomPage) {
    customPageEvents.value = getFormattedEvents(eventsData);
  }
}

/**
 * @note Scrolls the calendar container to the approximate time based on hours
 */
function scrollToCurrentTime(firstItemDate: Date): void {
  const calendar = document.querySelector<HTMLElement>(`#vuecal-${customUniqueId.value} .vuecal__bg`);
  const hours = firstItemDate.getHours() + firstItemDate.getMinutes() / 60;
  if (calendar) {
    calendar.scrollTo({ top: hours * 80, behavior: 'smooth' });
  }
}

/**
 * @note Opens a popover or sheet to change current event source
 */
async function openSourcePopover(ev: Event): Promise<void> {
  const data = isAnyMobile ? await openCalendarSourceSheet() : await openCalendarSourcePopover(ev);
  if (data) {
    await changeSource(data);
  }
}

/**
 * @note Changes the currently selected event source and re-fetches events by period
 */
async function changeSource(source: EventCalendarSourceEnum): Promise<void> {
  selectedSource.value = source;
  if (!eventStore.calendarPeriod) return;

  switch (source) {
    case EventCalendarSourceEnum.Groups: {
      const result = await openGroupSelectModal(GroupsFilterEnum.All, false, []);
      if (result) {
        selectedGroup.value = result[0];
        await getEventsByPeriod(
          eventStore.calendarPeriod.start,
          eventStore.calendarPeriod.end,
          EventCalendarSourceEnum.Groups,
          result[0]
        );
      } else {
        selectedSource.value = EventCalendarSourceEnum.All;
      }
      break;
    }
    case EventCalendarSourceEnum.All:
      await getEventsByPeriod(
        eventStore.calendarPeriod.start,
        eventStore.calendarPeriod.end,
        EventCalendarSourceEnum.All
      );
      break;
    case EventCalendarSourceEnum.My:
      await getEventsByPeriod(
        eventStore.calendarPeriod.start,
        eventStore.calendarPeriod.end,
        EventCalendarSourceEnum.My
      );
      break;
    default:
      break;
  }
}
//#endregion
</script>

<style scoped lang="scss">
.custom-calendar {
  position: relative;
  height: 100%;
}
.custom-calendar-loader {
  position: absolute;
  background: rgba(var(--ion-color-light-background-contrast-rgb), 0.4);
  width: 100%;
  height: 100%;
  border-radius: app-radius(md);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-calendar .custom-title ion-item.source-select {
  max-width: 400px;
  font-size: 0.9rem;
  --background: var(--ion-color-light-background-contrast);
  --color: medium;
  white-space: nowrap;
}
</style>

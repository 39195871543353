<template>
  <docs-item-view
    :id="wiki.id"
    :view-mode="viewMode"
    :document-type="DocumentTypeEnum.Wiki"
    :name="wikiName"
    :created-at="wiki.createdAt"
    :author="wikiAuthor"
    :edited-at="wiki.editedAt"
    :editor="wikiEditor"
    :group="wikiGroupTitle"
    :icon="documentTextOutline"
    :is-official="wiki.isOfficial"
    @onMenuOpen="openWikiMenu"
    @onQuickAction="onQuickAction"
  />
</template>

<script lang="ts" setup>
import { documentTextOutline } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { DocsItemView } from '@/components';
import { DocumentTypeEnum } from '@/enums';
import type { DataViewMode } from '@/enums';
import { useI18n } from '@/i18n';

import type { WikiModel } from '@/types';

const props = defineProps({
  wiki: {
    type: Object as PropType<WikiModel>,
    required: true,
  },
  viewMode: {
    type: String as PropType<DataViewMode>,
    required: true,
  },
});

const emit = defineEmits(['onQuickAction', 'onSecondaryAction']);

//#region Variables
const { t } = useI18n();
const wikiName: ComputedRef<string> = computed(() => props.wiki.name);
const wikiAuthor: ComputedRef<string> = computed(() => (props.wiki.author ? props.wiki.author?.fullName : ''));
const wikiEditor: ComputedRef<string> = computed(() => (props.wiki.modifier ? props.wiki.modifier?.fullName : ''));
const wikiGroupTitle: ComputedRef<string> = computed(() =>
  props.wiki.group ? props.wiki.group.title : t('network.allNetwork')
);
//#endregion

//#region Methods
const onQuickAction = async () => {
  emit('onQuickAction');
};

const openWikiMenu = async (ev: Event) => {
  emit('onSecondaryAction', ev);
};
//#endregion
</script>

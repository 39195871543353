import { alertController } from '@ionic/vue';
import { filter } from 'lodash';

import { isFileGuard } from './guards';

import {
  AccessByRoleEnum,
  ActionAccessEnum,
  AppIconsEnum,
  DocumentTypeEnum,
  FileActionEnum,
  FileStatusEnum,
  ShareArchiveLinkType,
  ShareEntityType,
  UploadFileTypes,
} from '@/enums';
import {
  canSendArchivedLink,
  openDocsUploadFileModal,
  openFileFollowersModal,
  openFileHistoryModal,
  openFileRelationsModal,
  openImagesViewerModal,
  openShareArchiveLinkModal,
  openTitleChangeModal,
  useFilesHybrid,
  shareEntity,
  useOfficeHelper,
  openDocsMoveFileModal,
  DateHelper,
  useToasts,
  useAccess,
  canShare,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { useDocStore, useNetworkStore } from '@/store';
import type { FileModel, DocModel, DocsMenuItemModel } from '@/types';

type IUseFileActions = {
  isImage: (file: FileModel) => Promise<boolean>;
  isVideo: (file: FileModel) => boolean;
  isAudio: (file: FileModel) => boolean;
  isOffice: (file: FileModel) => boolean;
  isPDF: (file: FileModel) => boolean;
  isPreviewAvailable: (file: FileModel) => Promise<boolean>;
  isFileVersion: (file: FileModel | null) => boolean;
  checkIfOutdated: (file: FileModel | null, date?: string) => Promise<boolean>;
  imageView: (file: FileModel) => void;
  openFile: (file: FileModel) => Promise<void>;
  editFile: (file: FileModel) => Promise<boolean>;
  downloadFile: (file: FileModel, status?: FileStatusEnum) => Promise<boolean>;
  renameFile: (file: FileModel) => Promise<boolean>;
  moveFile: (file: FileModel) => Promise<boolean>;
  deleteFile: (file: FileModel) => Promise<boolean>;
  uploadNewVersion: (file: FileModel) => Promise<boolean>;
  restoreFileVersion: (file: FileModel) => void;
  goToCurrentVersion: (file: FileModel) => void;
  getActionsMenuItems: (doc: DocModel, toolbarMenu?: boolean) => DocsMenuItemModel[];
  whichActionToMake: (
    action: FileActionEnum,
    file: FileModel,
    status: FileStatusEnum | undefined
  ) => Promise<boolean | undefined>;
};

export const useFileActions = (): IUseFileActions => {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  const accessHelper = useAccess();

  const docStore = useDocStore();
  const officeHelper = useOfficeHelper();

  const isFileVersion = (file: FileModel | null): boolean => !file?.createdAt;
  const isOffice = (file: FileModel): boolean => officeHelper.isOfficeFile(file.type);
  const isVideo = (file: FileModel): boolean => file.mimeType.startsWith('video');
  const isAudio = (file: FileModel): boolean => useFilesHybrid().fileIsAudio(file);

  const isPDF = (file: FileModel): boolean => {
    const fileExtensionsNotForPdfPreview = ['mp3', 'tiff', 'pbm', 'tga', 'mp4'];
    return file.type === 'pdf' && !fileExtensionsNotForPdfPreview.includes(file.type);
  };

  const isImage = async (file: FileModel): Promise<boolean> => {
    if (!file.mimeType.startsWith('image')) return false;

    const isSupported = await useFilesHybrid().isImageFormatSupported(file.image?.url ?? '');

    if (!isSupported) {
      showSonnerToast(t('files.imageNotSupported'), false);
      return false;
    }

    return true;
  };

  const isPreviewAvailable = async (file: FileModel): Promise<boolean> =>
    (await isImage(file)) || isVideo(file) || isAudio(file) || isPDF(file) || isOffice(file);

  const imageView = async (file: FileModel) => {
    await openImagesViewerModal(0, [{ file: file, text: '' }]);
  };

  const openFile = async (file: FileModel): Promise<void> => {
    console.log('----openFile', file);
    {
      if (await isImage(file)) {
        await router.push({
          name: ROUTES_NAME.FILE_BY_ID,
          params: { id: file.id },
        });
        return;
      }

      const result = await useFilesHybrid().openFile(file);

      if (result === FileStatusEnum.Error) {
        showSonnerToast(t('errorResponse'), false);
      }
    }
  };

  const editFile = async (file: FileModel): Promise<boolean> => {
    if (isOffice(file)) {
      const result = (await officeHelper.openOfficeView(
        file.id.toString(),
        false,
        {
          title: file.name,
          publishedBy: file.author,
          group: file.group,
          type: file.type,
        },
        true,
        false,
        file.group?.id ?? null,
        file.parentFolderId ?? null
      ))
        ? FileStatusEnum.Success
        : FileStatusEnum.Error;

      if (result === FileStatusEnum.Success) {
        await docStore.updateCurrentFile(true);
        return true;
      }

      if (result === FileStatusEnum.Error) {
        showSonnerToast(t('files.fileFailedOpenForEdit'), false);
        return false;
      }
    }
    return false;
  };

  const downloadFile = async (file: FileModel, status?: FileStatusEnum): Promise<boolean> => {
    if (status) status = FileStatusEnum.Loading;

    const downloadFileResult = await useFilesHybrid().downloadFile(file);

    if (status) status = downloadFileResult;

    showSonnerToast(
      downloadFileResult === FileStatusEnum.Success ? t('files.successDownloaded') : t('files.failedDownloaded'),
      downloadFileResult === FileStatusEnum.Success
    );

    return downloadFileResult === FileStatusEnum.Success;
  };

  const renameFile = async (file: FileModel): Promise<boolean> => {
    const result = await openTitleChangeModal(null, file.name, true, file?.description || '');

    if (!result) {
      console.error('Failed to rename file');
      return false;
    }

    const renameFileResult = (await docStore.renameFile(file.id, result.title, result.text))
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    showSonnerToast(
      renameFileResult === FileStatusEnum.Success ? t('files.fileSuccessRenamed') : t('files.fileFailedRenamed'),
      renameFileResult === FileStatusEnum.Success
    );

    return renameFileResult === FileStatusEnum.Success;
  };

  const moveFile = async (file: FileModel): Promise<boolean> => {
    const result = await openDocsMoveFileModal(null);

    if (!result) {
      console.error('Failed to move file');
      return false;
    }

    const moveFileResult = (await docStore.moveFile(result.folderId, result.groupId, file.id))
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    showSonnerToast(
      moveFileResult === FileStatusEnum.Success ? t('files.fileSuccessMoved') : t('files.fileFailedMoved'),
      moveFileResult === FileStatusEnum.Success
    );

    return moveFileResult === FileStatusEnum.Success;
  };

  const deleteFile = async (file: FileModel): Promise<boolean> => {
    let deleteFileResult: FileStatusEnum | null = null;
    const alert = await alertController.create({
      message: `${t('documents.popup.deleteFile')} <strong>${file.name}?</strong>`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('yes'),
          role: 'confirm',
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            deleteFileResult = (await docStore.deleteFile(file.id)) ? FileStatusEnum.Success : FileStatusEnum.Error;
          },
        },
      ],
    });

    await alert.present();

    const dismissed = await alert.onDidDismiss();
    if (dismissed.role === 'confirm') {
      if (router.currentRoute.value.name === ROUTES_NAME.FILE_BY_ID) {
        router.back();
      }
      showSonnerToast(
        deleteFileResult === FileStatusEnum.Success ? t('files.fileSuccessDeleted') : t('files.fileFailedDeleted'),
        deleteFileResult === FileStatusEnum.Success
      );
    }

    return deleteFileResult === FileStatusEnum.Success;
  };

  const uploadNewVersion = async (file: FileModel): Promise<boolean> => {
    const result = await openDocsUploadFileModal(UploadFileTypes.SingleAnyFile, true, file, true);
    if (result === undefined) {
      return false;
    }

    const uploadNewVersionResult = result ? FileStatusEnum.Success : FileStatusEnum.Error;

    showSonnerToast(
      uploadNewVersionResult === FileStatusEnum.Success
        ? t('files.fileSuccessUploaded')
        : t('files.fileFailedUploaded'),
      uploadNewVersionResult === FileStatusEnum.Success
    );

    return uploadNewVersionResult === FileStatusEnum.Success;
  };

  const _showHistory = async (id: number): Promise<void> => {
    await openFileHistoryModal(id);
  };

  const restoreFileVersion = async (file: FileModel) => {
    if (file.fileId) {
      const result = await docStore.restoreFileVersion(file.fileId, file.id);
      if (!result) {
        showSonnerToast(t('files.fileFailedRestoreVersion'), false);
        return;
      }
      showSonnerToast(t('files.fileSuccessRestoreVersion'), true);
      await goToCurrentVersion(file);
    }
  };

  const goToCurrentVersion = async (file: FileModel) => {
    if (file.fileId) {
      docStore.setCurrentFileVersion(null);

      await docStore.getCurrentFile(file.fileId);
    }
  };

  const _showRelations = async (id: number): Promise<void> => {
    await openFileRelationsModal(id);
  };

  const _markOfficial = async (id: number): Promise<boolean> => {
    return await docStore.markOfficial(id);
  };

  const _follow = async (id: number): Promise<boolean> => {
    return await docStore.follow(id);
  };

  const _unfollow = async (id: number): Promise<boolean> => {
    return await docStore.unfollow(id);
  };

  const _showFollowers = async (id: number): Promise<void> => {
    await openFileFollowersModal(id);
  };

  const checkIfOutdated = async (doc: FileModel | null, date?: string): Promise<boolean> => {
    if (!doc) return false;

    try {
      const currentFile = docStore.current.data?.data as FileModel;
      let currentFileByDate: FileModel | undefined;
      let checkDate: number;
      let latestEditDate: number;
      const requestDate = DateHelper.addSecondsToDateInISO(
        date ? new Date(date).toISOString() : new Date().toISOString(),
        5
      );

      if (date || !currentFile) {
        currentFileByDate = await docStore.getHistoricalFileByDate(doc.fileId || doc.id, requestDate);
        if (!currentFileByDate) {
          console.error('Error during checking if outdated: no latest file');
          return false;
        }
        checkDate = new Date(currentFileByDate.editedAt).getTime();
        latestEditDate = new Date(doc.editedAt).getTime();
      } else {
        checkDate = new Date(doc.editedAt).getTime();
        latestEditDate = new Date(currentFile.editedAt).getTime();
        docStore.setIsOutdated(checkDate < latestEditDate);
      }

      return checkDate < latestEditDate;
    } catch (e) {
      console.error('Error during checking if outdated:', e);
      return false;
    }
  };

  const getActionsMenuItems = (file: DocModel, toolbarMenu = false): DocsMenuItemModel[] => {
    const { t } = useI18n();

    const networkStore = useNetworkStore();

    const isCurrentRoutePreview = router.currentRoute.value.name === ROUTES_NAME.FILE_BY_ID;

    const showFollowLists = networkStore.settings?.showFollowLists ?? false;

    const allowSeeDocHistory = networkStore.settings?.allowSeeDocHistory ?? AccessByRoleEnum.Off;

    const activeGroup = useDocStore().activeGroup;

    const isFile: boolean = file.documentType === DocumentTypeEnum.File;
    const isFolder: boolean = file.documentType === DocumentTypeEnum.Folder;
    // const isWiki: boolean = file.documentType === DocumentTypeEnum.Wiki;
    const isFileVersion: boolean = file.documentType === DocumentTypeEnum.FileVersion;

    let isOffice = false;
    let isPDF = false;
    let isImage = false;
    let isAudio = false;

    if (isFileGuard(file.data)) {
      isOffice = useOfficeHelper().isOfficeFile(file.data.type);
      isPDF = file.data.type === 'pdf';
      isImage = file.data.mimeType.startsWith('image');
      isAudio = useFilesHybrid().fileIsAudio(file.data);
    }

    const toolbarMenuItems: DocsMenuItemModel[] = [
      {
        value: FileActionEnum.Open,
        title: isAudio ? t('files.menu.listen') : t('files.menu.view'),
        icon: isAudio ? AppIconsEnum.Autoplay : AppIconsEnum.Eye,
        disabled: !(isFile || isImage),
      },
      {
        value: FileActionEnum.Download,
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        disabled: isFolder,
      },
      {
        value: FileActionEnum.Share,
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        disabled: !(isFile && file.data.access.includes(ActionAccessEnum.Share) && canShare(activeGroup)),
      },
      {
        value: FileActionEnum.ShowFollowers,
        title: t(`files.menu.showFollowers`),
        icon: AppIconsEnum.Users,
        disabled: !showFollowLists || isFolder,
      },
    ];

    const menuItems: DocsMenuItemModel[] = [
      {
        value: FileActionEnum.Open,
        title: isAudio ? t('files.menu.listen') : t('files.menu.view'),
        icon: isAudio ? AppIconsEnum.Autoplay : AppIconsEnum.Eye,
        disabled: !isFile || (isImage && isCurrentRoutePreview),
      },
      {
        value: FileActionEnum.Edit,
        title: t('files.menu.edit'),
        icon: AppIconsEnum.PencilSquare,
        disabled: !(isFile && isOffice && file.data.access.includes(ActionAccessEnum.Edit)),
      },
      {
        value: FileActionEnum.Download,
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        disabled: !isFile && !isFileVersion,
      },
      {
        value: FileActionEnum.GoToCurrentVersion,
        title: t('files.menu.goToCurrentVersion'),
        icon: AppIconsEnum.History,
        disabled: !isFileVersion,
      },
      {
        value: FileActionEnum.Restore,
        title: t('files.menu.restore'),
        icon: AppIconsEnum.Refresh,
        disabled: !(isFileVersion && file.data.access?.includes(ActionAccessEnum.Edit)),
      },
      {
        value: FileActionEnum.Share,
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        // (isCurrentRoutePreview.value && isSMWidth.value) || TODO uncomment later
        disabled: !(isFile && file.data.access.includes(ActionAccessEnum.Share) && canShare(activeGroup)),
      },
      {
        value: FileActionEnum.ShareArchiveLink,
        title: t('feed.conversationPostMenu.send.shareArchiveLink'),
        icon: AppIconsEnum.ArchiveLink,
        disabled: !(
          canShare(activeGroup) &&
          !isFileVersion &&
          file.data.access.includes(ActionAccessEnum.Share) &&
          canSendArchivedLink()
        ),
      },
      {
        value: FileActionEnum.Rename,
        title: t('files.menu.rename'),
        icon: AppIconsEnum.Letters,
        disabled: isFileVersion || !file.data.access.includes(ActionAccessEnum.Rename),
      },
      {
        value: FileActionEnum.ShowRelations,
        title: t(`files.menu.showRelations`),
        icon: AppIconsEnum.Link,
        disabled: isFileVersion || isFolder,
      },
      {
        value: FileActionEnum.Move,
        title: t('files.menu.move'),
        icon: AppIconsEnum.Move,
        disabled: isFileVersion || !file.data.access.includes(ActionAccessEnum.Move),
      },
      {
        value: FileActionEnum.ShowFollowers,
        title: t(`files.menu.showFollowers`),
        icon: AppIconsEnum.Users,
        disabled: isFileVersion || isFolder || !showFollowLists,
      },
      {
        value: isFileGuard(file.data) && file.data.isFollowed ? FileActionEnum.Unfollow : FileActionEnum.Follow,
        title: isFileGuard(file.data) && file.data.isFollowed ? t(`subscribe.unfollow`) : t(`subscribe.follow`),
        icon:
          isFileGuard(file.data) && file.data.isFollowed ? AppIconsEnum.NotificationsOff : AppIconsEnum.Notifications,
        disabled: isFileVersion || isFolder,
      },
      {
        value: FileActionEnum.MarkOfficial,
        title: isFileGuard(file.data) && file.data.isOfficial ? t(`files.menu.unOfficial`) : t(`files.menu.official`),
        icon: isFileGuard(file.data) && file.data.isOfficial ? AppIconsEnum.Star : AppIconsEnum.StarOutline,
        disabled: !(isFile && file.data.access.includes(ActionAccessEnum.MarkAsOfficial)),
      },
      {
        value: FileActionEnum.ShowHistory,
        title: t(`files.menu.showHistory`),
        icon: AppIconsEnum.DocVersions,
        disabled: !(accessHelper.checkNetworkSettingAccess(allowSeeDocHistory) && isFile && (isOffice || isPDF)),
      },
      {
        value: FileActionEnum.Delete,
        title: t('appPopoverMenu.delete.title'),
        icon: AppIconsEnum.Remove,
        disabled: isFileVersion || !file.data.access.includes(ActionAccessEnum.Delete),
      },
      {
        value: FileActionEnum.NewVersion,
        title: t('files.menu.newVersion'),
        icon: AppIconsEnum.Upload,
        disabled: !(isFile && file.data.access.includes(ActionAccessEnum.NewVersion)),
      },
    ];

    return toolbarMenu
      ? filter(toolbarMenuItems, ({ disabled }) => !disabled)
      : filter(menuItems, ({ disabled }) => !disabled);
  };

  const whichActionToMake = async (
    action: FileActionEnum,
    file: FileModel,
    status?: FileStatusEnum
  ): Promise<boolean | undefined> => {
    type FileActionHandler = (file: FileModel, status?: FileStatusEnum) => Promise<boolean | undefined>;
    const fileActionHandlers: Record<FileActionEnum, FileActionHandler> = {
      [FileActionEnum.Open]: async (file) => {
        await openFile(file);
        return undefined;
      },
      [FileActionEnum.Edit]: async (file) => await editFile(file),
      [FileActionEnum.Download]: async (file, status) => await downloadFile(file, status),
      [FileActionEnum.Rename]: async (file) => await renameFile(file),
      [FileActionEnum.Move]: async (file) => await moveFile(file),
      [FileActionEnum.Share]: async (file) => await shareEntity(file, ShareEntityType.File),
      [FileActionEnum.ShareArchiveLink]: async (file) =>
        await openShareArchiveLinkModal(file.id, ShareArchiveLinkType.File),
      [FileActionEnum.Delete]: async (file) => await deleteFile(file),
      [FileActionEnum.NewVersion]: async (file) => await uploadNewVersion(file),
      [FileActionEnum.MarkOfficial]: async (file) => await _markOfficial(file.id),
      [FileActionEnum.Follow]: async (file) => await _follow(file.id),
      [FileActionEnum.Unfollow]: async (file) => await _unfollow(file.id),
      [FileActionEnum.OpenInWindow]: async () => undefined,
      [FileActionEnum.Restore]: async (file) => {
        await restoreFileVersion(file);
        return undefined;
      },
      [FileActionEnum.GoToCurrentVersion]: async (file) => {
        await goToCurrentVersion(file);
        return undefined;
      },
      [FileActionEnum.ShowFollowers]: async (file) => {
        await _showFollowers(file.id);
        return undefined;
      },
      [FileActionEnum.ShowHistory]: async (file) => {
        await _showHistory(file.id);
        return undefined;
      },
      [FileActionEnum.ShowRelations]: async (file) => {
        await _showRelations(file.id);
        return undefined;
      },
    };

    const handler = fileActionHandlers[action];
    if (!handler) {
      console.warn(`No handler defined for action: ${action}`);
      return undefined;
    }
    return await handler(file, status);
  };

  return {
    isImage,
    isVideo,
    isAudio,
    isOffice,
    isPDF,
    isFileVersion,
    isPreviewAvailable,
    downloadFile,
    renameFile,
    moveFile,
    deleteFile,
    uploadNewVersion,
    restoreFileVersion,
    goToCurrentVersion,
    whichActionToMake,
    editFile,
    openFile,
    imageView,
    getActionsMenuItems,
    checkIfOutdated,
  };
};

import type { DocumentExtensionEnum, WikiMajorFilterEnum } from '@/enums';
import type {
  CreateWikiPayload,
  UpdateWikiPayload,
  ResponseSuccessModel,
  ResponseErrorModel,
  ResponseWikiCreateModel,
  ResponseWikiRelationsModel,
  ResponseWikiHistoryModel,
  ResponseWikiHistoryByIdModel,
  ResponseWikiModel,
  ResponseWikisModel,
  ResponseWikiFollowersModel,
  ResponseWikiTemplateModel,
  ResponseWikiTemplatesModel,
  SaveWikiTemplateModel,
  CreateWikiTemplateModel,
  ResponseLockModel,
} from '@/types';
import axios from '@/services/axios';
import { getCommonHeaders } from '@/services/getters';

export class WikiApiService {
  //#region Basic API
  async getWikiById(wikiId: number): Promise<ResponseWikiModel | ResponseErrorModel> {
    return axios.get(`/wiki/byId/${wikiId}`);
  }
  async create(payload: CreateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/create', payload);
  }
  async update(payload: UpdateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/update', payload);
  }
  async download(id: number, documentExtension: DocumentExtensionEnum): Promise<Blob | ResponseErrorModel> {
    return axios.get(`/storage/wiki/${id}?type=${documentExtension}`, {
      responseType: 'blob',
    });
  }
  async markAsOfficial(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/markAsOfficial/${id}`);
  }
  async delete(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/delete/${id}`);
  }
  async deleteWithReplace(
    id: number,
    relationWikiId: number | null = null,
    relationFileId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    if (relationWikiId) {
      return axios.delete(`/wiki/deleteWithReplace/${id}?relationWikiId=${relationWikiId}`);
    }
    return axios.delete(`/wiki/deleteWithReplace/${id}?relationFileId=${relationFileId}`);
  }
  //#endregion

  //#region Relations
  async getRelations(id: number): Promise<ResponseWikiRelationsModel | ResponseErrorModel> {
    return axios.get(`/wiki/relations/${id}`);
  }
  async addRelation(
    id: number,
    relationFileId: number | null = null,
    relationWikiId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/addRelation/${id}?relationFileId=${relationFileId}&relationWikiId=${relationWikiId}`);
  }
  async removeRelation(
    id: number,
    relationFileId: number | null = null,
    relationWikiId: number | null = null
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeRelation/${id}?relationFileId=${relationFileId}&relationWikiId=${relationWikiId}`);
  }
  //#endregion

  //#region Followers
  async getFollowers(id: number): Promise<ResponseWikiFollowersModel | ResponseErrorModel> {
    return axios.get(`/wiki/followers/${id}`);
  }
  async follow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/${id}/follow`);
  }
  async unfollow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/${id}/unfollow`);
  }
  //#endregion

  //#region History
  async getHistory(id: number): Promise<ResponseWikiHistoryModel | ResponseErrorModel> {
    return axios.get(`/wiki/history/${id}?page=1`);
  }
  async getHistoryLoadMore(loadMoreUrl: string): Promise<ResponseWikiHistoryModel | ResponseErrorModel> {
    return axios.get(loadMoreUrl);
  }
  async getHistoryById(id: number): Promise<ResponseWikiHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/wiki/historyById/${id}`);
  }
  /** @note Wiki history by date. Method returns the closest wiki history by provided date */
  async getHistoryByDate(
    id: number,
    date: string,
    majorFilter: WikiMajorFilterEnum
  ): Promise<ResponseWikiHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/wiki/historyByDate/${id}?date=${date}&majorFilter=${majorFilter}`);
  }
  /** @note Method now has a parameter isMajor which signals the saving version is major */
  async updateHistoryById(payload: UpdateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/updateHistory', payload);
  }
  async rollback(wikiId: number, historyId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/rollback/${wikiId}?historyId=${historyId}`);
  }
  /**
   * @note Not implemented yet. Marking deprecated for now
   * @todo Get info from backend team
   * @deprecated
   */
  async deleteVersion(versionId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteVersion/${versionId}`);
  }
  //#endregion

  //#region Templates
  async getTemplateById(id: number): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.get(`/wiki/templateById/${id}`);
  }
  async getTemplates(): Promise<ResponseWikiTemplatesModel | ResponseErrorModel> {
    return axios.get('/wiki/templates?page=1');
  }
  async getTemplatesLoadMore(loadMoreUrl: string): Promise<ResponseWikiTemplatesModel | ResponseErrorModel> {
    return axios.get(loadMoreUrl);
  }
  async createTemplate(data: CreateWikiTemplateModel): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.post('/wiki/createTemplate', data, {
      headers: {
        withoutToast: true,
      },
    });
  }
  async updateTemplateById(data: SaveWikiTemplateModel): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.post(`/wiki/updateTemplate`, data, {
      headers: {
        withoutToast: true,
      },
    });
  }
  async deleteTemplateById(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteTemplate/${id}`);
  }
  //#endregion

  //#region Tags
  async addTag(id: number, tagTexts: string[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/wiki/addTag', { id, tagTexts });
  }
  async removeTag(id: number, tagId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeTag/${id}?tagId=${tagId}`);
  }
  //#endregion

  //#region Lock
  async lockEdit(id: number): Promise<ResponseLockModel | ResponseErrorModel> {
    return axios.post(`/wiki/lockEdit/${id}`);
  }
  async unlockEdit(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/unlockEdit/${id}`);
  }
  /** @note Not in use at the moment */
  async checkLock(id: number): Promise<ResponseLockModel | ResponseErrorModel> {
    return axios.post(`/wiki/checkLock/${id}`);
  }
  //#endregion

  //#region Contributions
  /**
   * @note Not in use at the moment
   */
  async getContributionsByUserId(userId: number): Promise<ResponseWikiModel[] | ResponseErrorModel> {
    return axios.get(`/wiki/contributions?userId=${userId}`);
  }
  //#endregion

  //#region Search
  async autocomplete(searchText: string): Promise<ResponseWikisModel | ResponseErrorModel> {
    return axios.get(`/wiki/autocomplete?searchText=${searchText}`);
  }
  //#endregion

  //#region Compare
  /**
   * This function makes an API call to the Node.js server to get the Git diff of the two texts
   *
   * @todo Make JWT authentication for the server
   * @todo Add error handling
   * @todo Add loading handling
   * @todo Refactor the function to use the axios library
   */
  async getDiff(requestBody: { originalText: string; updatedText: string }): Promise<string | null> {
    const url = `${import.meta.env.VITE_NODE_SERVER_URL_DEV}/wiki/gitDiff/`;
    let resultString = '';

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getCommonHeaders(),
      },
      body: JSON.stringify(requestBody),
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.text().then((data) => {
            resultString = data;
          });
        } else {
          console.error('Fetch Error:', response);
          return null;
        }
      })
      .catch((error) => {
        console.error('Fetch Error:', error);
        return null;
      });

    return resultString;
  }
  //#endregion
}

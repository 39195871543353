/**
 * @deprecated
 */
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import { Capacitor } from '@capacitor/core';

const enum LogType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  DEBUG = 'DEBUG',
  ERROR = 'ERROR',
}

/**
 * Checks if the directory exists and creates it if it doesn't
 *
 * @param path - The path to check
 * @returns true if the directory exists or was created successfully, false otherwise
 * @private
 */
async function _checkDir(path: string): Promise<boolean> {
  try {
    await Filesystem.stat({
      path,
      directory: Directory.Data,
    });
    return true;
  } catch (e) {
    console.log('Failed to get stat', e);
    try {
      await Filesystem.mkdir({
        path,
        directory: Directory.Data,
        recursive: true,
      });
      return true;
    } catch (e) {
      console.error('Failed to create directory:', e);
      return false;
    }
  }
}

/**
 * Checks if the file exists and initializes it with an empty JSON array if it doesn't
 *
 * @param path - The path to check
 * @returns true if the file exists or was created successfully, false otherwise
 * @private
 */
async function _checkFile(path: string): Promise<boolean> {
  try {
    await Filesystem.stat({
      path,
      directory: Directory.Data,
    });
    return true;
  } catch (e) {
    console.log('Failed to get stat', e);
    try {
      await Filesystem.writeFile({
        path,
        directory: Directory.Data,
        data: JSON.stringify([]),
        encoding: Encoding.UTF8,
      });
      return true;
    } catch (e) {
      console.error('Failed to create file:', e);
      return false;
    }
  }
}

/**
 * Formats the current date as a string for directory paths or filenames
 *
 * @returns formatted date string (e.g., "01-11-2024")
 * @private
 */
function _today(): string {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const year = now.getFullYear();
  return `${day}-${month}-${year}`;
}

/**
 * Logs a message to a JSON file on mobile in DEV mode
 *
 * @param type - The type of the log {INFO, WARNING, VERBOSE, ERROR}
 * @param message - The message to log
 * @param trace - Optional stack trace for errors/debugging
 * @private
 */
async function _logToFile(type: LogType, message: string, trace?: string): Promise<void> {
  if (!Capacitor.isNativePlatform() || !__DEV__) {
    return;
  }

  const directoryPath = `${import.meta.env.VITE_APP_NAME}/${_today()}`;
  const filePath = `${directoryPath}/logs.json`;
  const logEntry = {
    timestamp: new Date().toISOString(),
    type,
    message,
    trace,
  };

  try {
    if (!(await _checkDir(directoryPath))) {
      throw new Error('Directory creation failed');
    }

    if (!(await _checkFile(filePath))) {
      throw new Error('File creation failed');
    }

    const existingLogs = await Filesystem.readFile({
      path: filePath,
      directory: Directory.Data,
      encoding: Encoding.UTF8,
    });

    let logsArray = [];
    try {
      logsArray = existingLogs.data ? JSON.parse(existingLogs.data as string) : [];
    } catch (parseErr) {
      console.error('Error parsing existing log file, initializing as empty array:', parseErr);
      logsArray = [];
    }

    logsArray.push(logEntry);
    await Filesystem.writeFile({
      path: filePath,
      directory: Directory.Data,
      data: JSON.stringify(logsArray, null, 2),
      encoding: Encoding.UTF8,
    });
  } catch (writeErr) {
    console.error('Failed to log to file. Reason:', writeErr);
  }
}

/**
 * Use it to log [INFO] message and write it to a json file on native mobile && DEV mode
 *
 * @param message - The message to log
 * @deprecated
 */
export function logInfo(message: string, payload?: any): void {
  console.log('[INFO]', message, payload);
  _logToFile(LogType.INFO, message);
}

/**
 * Use it to log [WARNING] message and write it to a json file on native mobile && DEV mode
 *
 * @param message - The message to log
 * @deprecated
 */
export function logWarn(message: string, payload?: any): void {
  console.warn('[WARNING]', message, payload);
  _logToFile(LogType.WARNING, message);
}

/**
 * Use it to log [ERROR] message, log stack trace and write it to a json file on native mobile && DEV mode
 *
 * @param message - The message to log
 * @param error - The error to log with its stack trace
 * @deprecated
 */
export function logErr(message: string, error: any): void {
  console.error('[ERROR]', message, error);
  _logToFile(LogType.ERROR, message, error?.stack || '');
}

/**
 * Use it to log [DEBUG] message, log stack trace and write it to a json file on native mobile && DEV mode
 *
 * @param message - The message to log
 * @deprecated
 */
export function logDebug(message: string, payload?: any): void {
  console.debug('[DEBUG]', message, payload);
  const trace = new Error().stack;
  trace && console.trace('[TRACE]', trace);
  _logToFile(LogType.DEBUG, message, trace);
}

import { DocBrowserModeEnum } from '@/enums';
import type { DocsBrowserPathModel, ExternalLinkModel } from '@/types';

export const defaultExternalLink: ExternalLinkModel = {
  title: '',
  url: '',
};

export const defaultDocsBrowserPath: DocsBrowserPathModel = {
  groupId: null,
  folderId: null,
  mode: DocBrowserModeEnum.All,
  search: '',
};

import { AccountApiService } from './api/account.service';
import { AdminApiService } from './api/admin.service';
import { AnnouncementApiService } from './api/announcement.service';
import { AuthApiService } from './api/auth.service';
import { BadgesApiService } from './api/badge.service';
import { DocApiService } from './api/doc.service';
import { EventApiService } from './api/event.service';
import { FileApiService } from './api/file.service';
import { GroupsApiService } from './api/group.service';
import { MeetApiService } from './api/meet.service';
import { MessengerApiService } from './api/messenger.service';
import { NetworksApiService } from './api/network.service';
import { NotificationApiService } from './api/notifications.service';
import { OfficeApiService } from './api/office.service';
import { CustomPageApiService } from './api/page.service';
import { PostApiService } from './api/posts.service';
import { ProjectsApiService } from './api/projects.service';
import { SearchApiService } from './api/search.service';
import { ToolApiService } from './api/tool.service';
import { TopicApiService } from './api/topic.service';
import { UserApiService } from './api/user.service';
import { WikiApiService } from './api/wiki.service';
import { MenuApiService } from './api/menu.service';

export const $api = {
  auth: new AuthApiService(),
  account: new AccountApiService(),
  user: new UserApiService(),
  meet: new MeetApiService(),
  messenger: new MessengerApiService(),
  file: new FileApiService(),
  network: new NetworksApiService(),
  admin: new AdminApiService(),
  tool: new ToolApiService(),
  post: new PostApiService(),
  group: new GroupsApiService(),
  notification: new NotificationApiService(),
  doc: new DocApiService(),
  announcement: new AnnouncementApiService(),
  page: new CustomPageApiService(),
  event: new EventApiService(),
  topic: new TopicApiService(),
  search: new SearchApiService(),
  badge: new BadgesApiService(),
  projects: new ProjectsApiService(),
  wiki: new WikiApiService(),
  office: new OfficeApiService(),
  menu: new MenuApiService(),
};

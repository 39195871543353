/**
 * This configuration file lists the hostnames for which automatic redirection is disabled.
 * If the current hostname is included here, the app will not redirect to another URI even if other conditions are met.
 * You can modify this list to control which hosts should prevent redirection.
 */
export const HOSTS_WITH_NO_REDIRECT: string[] = [
  'dev.communex.app',
  'dev2.communex.app',
  'dev3.communex.app',
  'dev4.communex.app',
  // 'dev5.communex.app', // https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1792#note_106078
  'dev6.communex.app',
];

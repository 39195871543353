<template>
  <feed-item
    v-if="postsData.length > 0"
    class="margin-bottom"
    :post="postsData[0]"
    :feed-flag="FeedFlagEnum.ForceToReadModal"
  />

  <not-found-data v-if="!isLoading && postsData.length === 0" />
  <div ref="scrolledRef" style="height: 1px" />
</template>

<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import { watchDebounced, useElementVisibility } from '@vueuse/core';
import { debounce } from 'lodash';
import type { PropType, ComponentPublicInstance, ComputedRef } from 'vue';
import { onMounted, ref, watch, computed } from 'vue';

import { FeedItem, NotFoundData } from '@/components';
import { AppLoadingTypeEnum, FeedFlagEnum } from '@/enums';
import { useCustomScroll, useLoading } from '@/helpers';
import { useAppStore, usePostStore } from '@/store';
import type { PostModel } from '@/types';

const props = defineProps({
  contentRef: {
    type: [Object, null] as PropType<ComponentPublicInstance | null>,
    default: null,
  },
});

const emit = defineEmits([
  'submit-function-provided',
  'close-function-provided',
  'update:submit-is-loading',
  'update:submit-disabled',
  'update:close-disabled',
]);

//#region Variables
const postStore = usePostStore();
const appStore = useAppStore();

const customScroll = useCustomScroll();

const scrolledRef = ref(null);
const isScrolledToEnd = useElementVisibility(scrolledRef);
const isLoading = ref<boolean>(false);

const contentRef: ComputedRef<ComponentPublicInstance | null> = computed(() => props.contentRef);
const postsData: ComputedRef<PostModel[]> = computed(() => postStore.getForceToReadPosts().data);
const submitDisabled: ComputedRef<boolean> = computed(() => isLoading.value || !isScrolledToEnd.value);
const closeDisabled: ComputedRef<boolean> = computed(() => isLoading.value || postsData.value.length !== 0);
//#endregion

//#region Methods
const toTop = debounce(async function () {
  try {
    const scroller = contentRef.value?.$el;
    if (scroller) {
      await scroller.scrollToTop(100);
    }
  } catch (e) {
    console.error('Scroll to top failed:', e);
  }
}, 100);

const readPost = async (postId: number) => {
  isLoading.value = true;
  await postStore.markRequiredPostAsRead(postId);
  await toTop();
  isLoading.value = false;
};

const submitFunction = async () => {
  await readPost(postsData.value[0].id);
};
const closeFunction = async () => {
  await modalController.dismiss(true);
};
//#endregion

//#region Watchers
watch(isLoading, (value) => emit('update:submit-is-loading', value), { immediate: true });
watch(submitDisabled, (value) => emit('update:submit-disabled', value), { immediate: true });
watch(closeDisabled, (value) => emit('update:close-disabled', value), { immediate: true });

watchDebounced(
  postsData,
  async (value) => {
    if (value.length === 0) {
      await modalController.dismiss(true);
    }
  },
  { debounce: 0, maxWait: 0 }
);
//#endregion

//#region Lifecycle
onMounted(async () => {
  await customScroll.init(document);

  appStore.setIsLoading(false);

  await useLoading().dismiss(AppLoadingTypeEnum.OtherLoading);
});
//#endregion

emit('submit-function-provided', submitFunction);
emit('close-function-provided', closeFunction);
</script>

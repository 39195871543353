<template>
  <docs-item-view
    :id="file.id"
    :view-mode="viewMode"
    :document-type="DocumentTypeEnum.File"
    :name="fileName"
    :created-at="file.createdAt"
    :edited-at="file.editedAt"
    :author="fileAuthor"
    :editor="fileEditor"
    :group="fileGroupTitle"
    :icon="fileIcon"
    :status="status"
    :file="file"
    :size="fileSize"
    :is-official="file.isOfficial"
    @onDownloadStop="stopDownload"
    @onMenuOpen="openFileMenu"
    @onQuickAction="onQuickAction"
  />
</template>

<script lang="ts" setup>
import type { ComputedRef, PropType, Ref } from 'vue';
import { computed, ref } from 'vue';

import { DocsItemView } from '@/components';
import { DocumentTypeEnum, FileStatusEnum } from '@/enums';
import type { DataViewMode } from '@/enums';
import { useToasts, useDocs, formatBytes } from '@/helpers';
import { useI18n } from '@/i18n';
import type { FileModel } from '@/types';

const props = defineProps({
  file: {
    type: Object as PropType<FileModel>,
    required: true,
  },
  viewMode: {
    type: String as PropType<DataViewMode>,
    required: true,
  },
});

const emit = defineEmits(['onLoading', 'onQuickAction', 'onSecondaryAction']);

//#region Variables
const { t } = useI18n();
const { showSonnerToast } = useToasts();
const status: Ref<FileStatusEnum> = ref<FileStatusEnum>(FileStatusEnum.Init);
const fileGroupTitle: ComputedRef<string> = computed(() =>
  props.file.group ? props.file.group.title : t('network.allNetwork')
);
const fileName: ComputedRef<string> = computed(() => props.file.name + '.' + props.file.type);
const fileAuthor: ComputedRef<string> = computed(() => (props.file.author ? props.file.author?.fullName : ''));
const fileEditor: ComputedRef<string> = computed(() => (props.file.editedBy ? props.file.editedBy?.fullName : ''));
const fileSize: ComputedRef<string> = computed(() => formatBytes(props.file.size));
const fileIcon: ComputedRef<string> = computed(() =>
  useDocs().getDocumentIcon({ documentType: DocumentTypeEnum.File, data: props.file })
);
//#endregion

//#region Methods
const stopDownload = async (id: number) => {
  status.value = FileStatusEnum.Success;
  emit('onLoading', false, id);
  showSonnerToast(t('files.downloadStopped'), true);
};

const onQuickAction = async () => {
  emit('onQuickAction');
};

const openFileMenu = async (ev: Event) => {
  emit('onSecondaryAction', ev);
};
//#endregion
</script>

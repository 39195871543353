import { DocumentExtensionEnum } from '@/enums';
import { FeedTypeEnum } from '@/enums';
import { useErrors } from '@/helpers';
import axios from '@/services/axios';
import { buildAxiosError, buildErrorResponse } from '@/services/builders';
import type { ResponseErrorModel, ResponseFileModel, ResponseSuccessModel } from '@/types';

export class FileApiService {
  async upload(file: File): Promise<ResponseFileModel | ResponseErrorModel> {
    const formData = new FormData();
    formData.append('attach', file);
    return axios.post('/storage/UploadMulti', formData);
  }
  async delete(fileId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/delete/${fileId}`);
  }
  async downloadPost(
    type: FeedTypeEnum,
    documentExtension: DocumentExtensionEnum,
    postId: number
  ): Promise<Blob | ResponseErrorModel> {
    const defaultValue: string = 'posts';
    const map: Record<FeedTypeEnum, string> = {
      [FeedTypeEnum.Text]: defaultValue,
      [FeedTypeEnum.Announcement]: 'announcements',
      [FeedTypeEnum.GroupCreated]: defaultValue,
      [FeedTypeEnum.GroupDeleted]: defaultValue,
      [FeedTypeEnum.Badge]: 'badges',
      [FeedTypeEnum.Event]: 'events',
      [FeedTypeEnum.Poll]: 'polls',
      [FeedTypeEnum.Idea]: 'ideas',
      [FeedTypeEnum.UserCreated]: defaultValue,
      [FeedTypeEnum.Task]: 'tasks',
    };
    return axios.get(`/storage/${map[type]}/${documentExtension}/${postId}`, {
      responseType: 'blob',
    });
  }
  async downloadFolder(folderId: number): Promise<Blob | ResponseErrorModel> {
    return axios.get(`/storage/document/folder/${folderId}`, {
      responseType: 'blob',
    });
  }

  async media(url: string): Promise<Blob | ResponseErrorModel> {
    const response = await fetch(url);

    if (response.status === 200) return await response.blob();

    const error = buildAxiosError(response);

    useErrors().handleError({
      show: false,
      error,
      message: undefined,
    });
    return buildErrorResponse(error);
  }
}

import { NetworkModel } from '@/types/network';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/core';
import { HOSTS_WITH_NO_REDIRECT } from './config/hostsWithNoRedirect';
import { GENERIC_HOSTS } from './config/genericHosts';
import { ENDPOINTS_WITH_NO_AUTH_CHECK } from './config/endpointsWithNoAuthCheck';

export function isGenericDomain(): boolean {
  return GENERIC_HOSTS.includes(window.location.hostname);
}

export function isGenericHost(): boolean {
  return GENERIC_HOSTS.includes(import.meta.env.VITE_APP_HOST);
}

export function isLiveHost(): boolean {
  return import.meta.env.VITE_APP_HOST === 'communex.app';
}

export function isLocalhost(): boolean {
  return window.location.hostname === 'localhost';
}

/**
 * Helper function to check if requesting a specific endpoint should NOT trigger session validation
 *
 * @param url Currently requested URL
 * @returns true if validation should be skipped, false otherwise
 */
export function skipAuthCheck(url: string): boolean {
  return ENDPOINTS_WITH_NO_AUTH_CHECK.some((e) => url.includes(e));
}

/**
 * Use it to check if session is currently on custom domain
 */
export function isCustomDomain(): boolean {
  if (Capacitor.isNativePlatform()) {
    return !isGenericHost() && !isLiveHost();
  }
  return !isGenericDomain() && !isLocalhost();
}

export function isRedirect(network: NetworkModel): boolean {
  if (window.location.hostname === network.host) {
    console.warn('[WARN] Redirection is prevented due to incoming network.host being the same as current host');
    return false;
  }

  if (isLocalhost()) {
    console.warn('[WARN] Redirection is prevented on localhost');
    return false;
  }

  if (Capacitor.isNativePlatform()) {
    console.warn('[WARN] Redirection is prevented in native environment');
    return false;
  }

  if (isPlatform('electron')) {
    console.warn('[WARN] Redirection is prevented in electron environment');
    return false;
  }

  if (HOSTS_WITH_NO_REDIRECT.includes(window.location.hostname)) {
    console.warn(
      `[WARN] Redirection is disabled for ${window.location.hostname}. Reason: current host is in NO_REDIRECT list`
    );
    return false;
  }

  // Otherwise, redirection is allowed
  return true;
}

import { HubConnectionState, type HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import {
  ChainTypeEnum,
  MeetStatusEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MessageTypeEnum,
  WikiActionEnum,
} from '@/enums';
import {
  feedTypeHelper,
  isNativeMobile,
  useToasts,
  toLastModel,
  useMeet,
  useNotifications,
  useWiki,
  toShortUserModel,
  openMeetRoomModal,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useAuthStore,
  useChatStore,
  useMeetStore,
  useMessengerStore,
  useNotificationsStore,
  useUserStore,
  useWikiStore,
} from '@/store';
import type {
  EditMessageModel,
  EventPrivateMessage,
  EventUserTyping,
  MessageChainModel,
  MessageModel,
  MessagesReadModel,
  WebSocketModel,
  WebSocketConnectModel,
  UserModel,
} from '@/types';

type IUseWebSockets = {
  typingEvents: EventUserTyping[];
  initWebSockets: (model: WebSocketModel | null) => Promise<void>;
  startWebSockets: () => Promise<boolean>;
  stopWebSockets: () => Promise<boolean>;
};

let instance: IUseWebSockets | null = null;

export function useWebSockets(): IUseWebSockets {
  if (instance) return instance;

  //#region Variables
  const { t } = useI18n();
  let connection: HubConnection | undefined;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isActive: boolean = false;
  const typingEvents: EventUserTyping[] = [];
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Private methods
  function _changeTyping(data: EventUserTyping): void {
    const index = typingEvents.findIndex(
      (f: EventUserTyping) => f.chainId === data.chainId && f.userId === data.userId
    );
    if (~index) {
      typingEvents.splice(index, 1, data);
    } else {
      typingEvents.push(data);
    }
  }

  async function _onClosed(error?: Error): Promise<void> {
    console.error('[ERROR] Connection closed', error);

    try {
      useAuthStore().setSignalRConnectionStatus(HubConnectionState.Disconnected);

      /** @note If user is on wiki page, then we need to unlock it */
      if (router.currentRoute.value.name === ROUTES_NAME.WIKI_EDIT) {
        const { id } = router.currentRoute.value.params;
        await useWikiStore().unlockEdit(Number(id));
      }
    } catch (e) {
      console.error('Failed to unlock wiki', e);
    }
  }

  async function _onReconnected(connectionId?: string | undefined): Promise<void> {
    try {
      if (!connectionId) return;

      const model = useAuthStore().getWebSocketModel;
      if (!model) return;

      if (connection) {
        const invokeModel: WebSocketConnectModel = {
          coreId: model.coreId,
          companyRowId: model.companyRowId,
          userId: model.userId,
          userRowId: model.userRowId,
          version: model.version,
        };
        await connection.invoke('connect', invokeModel);

        useAuthStore().setSignalRConnectionId(connectionId);
        useAuthStore().setSignalRConnectionStatus(connection?.state ?? HubConnectionState.Connected);

        useMeetStore().setConnectionId(connectionId);

        /** @note If user is on wiki edit page while reconnecting, then we try to lock it and redirect to wiki preview on failure */
        if (router.currentRoute.value.name === ROUTES_NAME.WIKI_EDIT) {
          const { id } = router.currentRoute.value.params;
          const lockStatus = await useWikiStore().lockEdit(Number(id));
          if (!lockStatus || lockStatus.success === false) {
            await useWiki().handleAction({ type: WikiActionEnum.ToCurrent, id: Number(id) });
          }
        }
      }

      return;
    } catch (e: any) {
      console.error('Reconnection failed', e);
      /** @note If the connection is not established, show a toast with the ability to reconnect */
      await _retryConnection();
    }
  }

  function _onTyping(data: EventUserTyping): void {
    _changeTyping({
      userId: +data.userId,
      chainId: +data.chainId,
      typing: data.typing,
    });
  }

  function _onUserConnected(userId: number): void {
    const index = useAppStore().onlineUsers.findIndex((u: number) => u === userId);
    if (index < 0) {
      useAppStore().setUserConnected(userId);
    }
  }

  function _onUserDisconnected(userId: number): void {
    useAppStore().setUserDisconnected(userId);
  }

  async function _onAddParticipant(message: MessageModel, currentUserId: number): Promise<void> {
    if (message.authorId === currentUserId) {
      return;
    }

    const userIds = message.payload ? JSON.parse(message.payload).userIds : undefined;
    if (!userIds) {
      return;
    }

    const meetRoomId = useMeetStore().getRoomIdByChainId(message.chainId);
    const activeCalls = await useMeetStore().getActiveCalls();
    const currentCallSession = activeCalls?.[message.chainId];

    await useChatStore().addParticipants(message.chainId, userIds, currentUserId);

    const existedChain = useMessengerStore().getChainById(message.chainId);
    if (!existedChain) {
      console.error('[ERROR] Pushing message failed - chain is null', existedChain, message.chainId);
      return;
    }

    if (meetRoomId) {
      for (const id of userIds) {
        let addedUser: UserModel | null = useUserStore().getUserProfile(id);
        if (addedUser.id === 0) {
          addedUser = await useUserStore().userProfileById(id);
        }
        if (addedUser === null) {
          return;
        }
        useMeetStore().addUser(toShortUserModel(addedUser), meetRoomId);
      }
    }

    /** @note if meetRoomId exists it means that current user is participating in a group call and has been added in a group chat already */
    if (currentCallSession && !meetRoomId && userIds.includes(currentUserId)) {
      const callModalResult = await useMeet().showCallModal(existedChain);
      if (callModalResult === MeetStatusEnum.Accept) {
        const roomId = await useMeetStore().callUser(existedChain);
        if (!roomId) {
          showSonnerToast(t('meet.meetRoom.tryAgain'), false);
          console.error('[ERROR] Failed to init call'); //! DEBUG
          return;
        }
        if (useMeetStore().currentRoomId === '') {
          useMeetStore().$patch({
            currentRoomId: roomId,
          });
        }
        /** @note connect to another incoming call if user is already in a call*/
        if (useMeetStore().getCurrentRoomId !== roomId) {
          await useMeet().disconnectFromRoom();
          useMeetStore().$patch({
            currentRoomId: roomId,
          });
        }
        await openMeetRoomModal(roomId);
        const requestPermissionsResult = await useMeet().requestPermissions();
        if (!requestPermissionsResult) {
          await useMeet().disconnectFromRoom(roomId);
          return;
        }
        const connectedToRoom = await useMeet().connectToRoom(false, roomId);
        if (!connectedToRoom) {
          await useMeet().disconnectFromRoom(roomId);
        }
      } else if (callModalResult === MeetStatusEnum.Reject) {
        useMeetStore().setRoom(currentCallSession.roomName, existedChain, currentCallSession.participantJwtToken);
      }
    }
  }

  async function _onRemoveParticipant(message: MessageModel, currentUserId: number): Promise<void> {
    if (message.authorId === currentUserId) {
      return;
    }

    const userIds = message.payload ? JSON.parse(message.payload).userIds : undefined;
    if (!userIds) {
      return;
    }

    const meetRoomId = useMeetStore().getRoomIdByChainId(message.chainId);

    await useChatStore().removeParticipants(message.chainId, userIds, currentUserId);

    if (meetRoomId) {
      useMeetStore().deleteUser(userIds[0], meetRoomId);
    }
  }

  async function _onRemoveYou(message: MessageModel, currentUserId: number): Promise<void> {
    if (message.authorId === currentUserId) {
      return;
    }

    const meetHelper = useMeet();
    const meetRoomId = useMeetStore().getRoomIdByChainId(message.chainId);

    await useChatStore().removeParticipants(message.chainId, [currentUserId], currentUserId);

    const existedChain = useMessengerStore().getChainById(message.chainId);
    if (!existedChain) {
      console.error('[ERROR] Pushing message failed - chain does not exist', existedChain, message.chainId);
      return;
    }

    if (meetRoomId) {
      await meetHelper.disconnectFromRoom(meetRoomId, true);
      return;
    }

    const activeCalls = await useMeetStore().getActiveCalls();
    const currentCallSession = activeCalls?.[message.chainId];

    if (currentCallSession && !meetRoomId) {
      useMeetStore().setRoom(currentCallSession.roomName, existedChain, currentCallSession.participantJwtToken);
      await meetHelper.disconnectFromRoom(currentCallSession.roomName, true);
    }
  }

  async function _onPushMessage(message: MessageModel): Promise<void> {
    const currentChainId = useChatStore().getId;
    const currentUserId = useUserStore().getId;
    const model = useAuthStore().getWebSocketModel;

    if (!model) {
      console.error('[ERROR] Pushing message failed - model is null', model);
      return;
    }

    const newMess = message.originalMessage as MessageModel;
    if (model.userId === newMess.authorId) {
      console.warn('[INFO] Message from the current user, no need to update anything');
      return;
    }

    newMess.direction = MessageDirectionTypeEnum.Incoming;

    _changeTyping({
      userId: newMess.authorId,
      chainId: newMess.chainId,
      typing: false,
    });

    let existedChain = useMessengerStore().getChainById(newMess.chainId);
    /** @note If chain is not currently in the local storage - trying to get it */
    if (!existedChain) {
      await useMessengerStore().chainById(newMess.chainId);
    }
    /** @note Trying to get chain again */
    existedChain = useMessengerStore().getChainById(newMess.chainId);
    if (!existedChain) {
      console.error('[ERROR] Pushing message failed - chain does not exist', existedChain, newMess.chainId);
      return;
    }
    /** @note If message is in archive chain, change chain type to active */
    if (existedChain && existedChain.chainType === ChainTypeEnum.Archive) {
      existedChain.chainType = ChainTypeEnum.Active;
    }

    const chainIsMuted = existedChain?.muted;
    const notificationsIsActive = useAppStore().localNotifications;

    /** @note If message is incoming, chain is not current, chain is not muted and notifications are active - playing sound */
    const shouldPlaySound =
      newMess.direction === MessageDirectionTypeEnum.Incoming &&
      (newMess.chainId !== currentChainId || newMess.chainId === null) &&
      !chainIsMuted &&
      notificationsIsActive;

    if (shouldPlaySound) {
      if (!isNativeMobile) {
        const audioFile = new Audio(useAppStore().getAppNotificationSoundPath);
        await audioFile.play();
      } else {
        await useNotifications().scheduleLocalNotification(newMess);
      }
    }

    await useChatStore().updateChain(existedChain, false);

    useMessengerStore().setLastMessage(toLastModel(newMess), model.userId !== newMess.authorId);

    /** @note If the message is in the current chain and the author is not the current user - reading the message */
    if (newMess.chainId === currentChainId && newMess.authorId !== currentUserId) {
      useChatStore().read(
        {
          authorId: newMess.authorId,
          chainId: newMess.chainId,
          messageId: newMess.id,
          status: MessageDeliveryStatusEnum.ReadAll,
          uniqueId: newMess.uniqueId,
        } as MessagesReadModel,
        false
      );
      await useChatStore().markAsRead([newMess.id]);
    }

    const pushMessageHandlers: Record<
      MessageTypeEnum,
      ((message: MessageModel, currentUserId: number) => Promise<void>) | undefined
    > = {
      [MessageTypeEnum.AddParticipant]: _onAddParticipant,
      [MessageTypeEnum.RemoveParticipant]: _onRemoveParticipant,
      [MessageTypeEnum.RemoveYou]: _onRemoveYou,
      [MessageTypeEnum.Message]: undefined,
      [MessageTypeEnum.CreateGroup]: undefined,
      [MessageTypeEnum.YouNewAdmin]: undefined,
      [MessageTypeEnum.SelfRemove]: undefined,
      [MessageTypeEnum.FileVideo]: undefined,
      [MessageTypeEnum.FileImage]: undefined,
      [MessageTypeEnum.FileDocument]: undefined,
      [MessageTypeEnum.Quote]: undefined,
      [MessageTypeEnum.Sticker]: undefined,
      [MessageTypeEnum.Init]: undefined,
      [MessageTypeEnum.Forward]: undefined,
      [MessageTypeEnum.ForwardOld]: undefined,
      [MessageTypeEnum.InitVideoCall]: undefined,
      [MessageTypeEnum.EndVideoCall]: undefined,
    };

    const handler = pushMessageHandlers[newMess.messageType];

    if (!handler) {
      console.warn('[INFO] No handler found for push message type: ', newMess.messageType);
      return;
    }

    try {
      await handler(newMess, currentUserId);
    } catch (error) {
      console.error(`[ERROR] Error handling ${newMess.messageType} message:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  function _onEditMessage(message: MessageModel): void {
    const mess = message.originalMessage;
    if (mess !== null) {
      useChatStore().redact({
        chainId: mess.chainId,
        message: toLastModel(mess),
        fileInfos: mess.attachedFiles.data,
        uniqueId: mess.uniqueId,
      } as EditMessageModel);

      useMessengerStore().setLastMessage(toLastModel(mess), false);
    }
  }

  function _onReadMessage(message: MessageModel): void {
    useChatStore().read(
      {
        authorId: message.authorId,
        chainId: message.chainId,
        messageId: message.id,
        status: message.status,
        uniqueId: message.uniqueId,
      } as MessagesReadModel,
      false
    );
  }

  function _onDeleteMessage(messageId: number, chainId: number): void {
    const currentChainId = useChatStore().chain?.chainId;
    const lastMessage = useChatStore().delete(messageId, currentChainId as number);
    const index = useMessengerStore().data.findIndex((f: MessageChainModel) => f.chainId === chainId);

    if (~index) {
      if (currentChainId === chainId) {
        useMessengerStore().setLastMessage(toLastModel(lastMessage), false);
      } else if (useMessengerStore().data[index].lastMessage.id === messageId) {
        useMessengerStore().data[index].lastMessage.text = t('messenger.chatPage.deleted');
        useMessengerStore().data[index].lastMessage.status = MessageDeliveryStatusEnum.Deleted;
      }
    }
  }

  /** @todo Handle private messages WS event */
  function _onSendPrivateMessage(data: EventPrivateMessage): void {
    console.log(`Received private message - `, data);
  }

  async function _onSendSiteNotification(): Promise<void> {
    /**
     * @note Now model - EventSiteNotification is very limited, so we can't get the full information about the notification.
     * Thus, we can only re-fetch the notifications from the server using `$api.notification.getNotifications()`
     * to update useNotificationsStore().notificationsIds, useNotificationsStore().data and useNotificationsStore().totalUnreadCount
     * @see src/store/notification.pinia.ts
     * @see src/services/notifications.service.ts
     * @link Back - https://gitlab.united-grid.com/intra/core/-/issues/846
     * @link Front - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1672
     */
    await useNotificationsStore().notifications();
    // await useNotificationsStore().unreadNotifications();
  }

  async function _onSendUserItem(): Promise<void> {
    /**
     * @note Now upon this event, we only get the userItemId, so we can't get the full information about the user item.
     * We need to re-fetch the feed using the `feedTypeHelper` method to update postStore.data and postStore.postsIds.
     * Later, we will need to implement the ability to get the full information about the userItem and just update mentioned store properties with this new userItem
     * Commented out as it will be implemented in the future as part of https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1755
     * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1755
     */
    await feedTypeHelper(ROUTES_NAME.FEED);
  }

  /** @todo Handle user item been commented WS event */
  function _onSendUserItemComment(userItemId: number, commentId: number): void {
    console.log(`User item ${userItemId} has a new comment ${commentId}`);
  }

  function _registerConnectionEvents(): void {
    if (!connection) {
      console.error('Registration of events failed - connection is null', connection);
      return;
    }

    connection.onclose(_onClosed);

    connection.onreconnected(_onReconnected);

    connection.on('typing', _onTyping);

    connection.on('usersOnline', (data: number[]) => {
      useAppStore().setOnlineUsers(data);
    });

    connection.on('userConnected', _onUserConnected);

    connection.on('userDisconnected', _onUserDisconnected);

    connection.on('pushMessage', _onPushMessage);

    connection.on('editMessage', _onEditMessage);

    connection.on('readMessage', _onReadMessage);

    connection.on('deleteMessage', _onDeleteMessage);

    connection.on('sendPrivateMessage', _onSendPrivateMessage);

    connection.on('sendSiteNotification', _onSendSiteNotification);

    connection.on('sendUserItem', _onSendUserItem);

    connection.on('sendUserItemComment', _onSendUserItemComment);
  }

  async function _retryConnection(): Promise<void> {
    try {
      const { showRetrySonnerToast } = useToasts();

      // User tries to reconnect
      const retryAction = async () => {
        await stopWebSockets();
        await initWebSockets(useAuthStore().getWebSocketModel);
      };
      showRetrySonnerToast(t('messenger.errorConnect'), t('retry'), retryAction);
    } catch (err) {
      console.error('Reconnection failed', err);
    }
  }

  async function _invokeConnection(connection: HubConnection, model: WebSocketModel): Promise<void> {
    try {
      const invokeModel: WebSocketConnectModel = {
        coreId: model.coreId,
        companyRowId: model.companyRowId,
        userId: model.userId,
        userRowId: model.userRowId,
        version: model.version,
      };
      await connection.invoke('connect', invokeModel);

      useAppStore().setIsLoading(false);
      if (!connection?.connectionId) {
        throw new Error('Connection ID is null');
      }
      useAuthStore().setSignalRConnectionId(connection.connectionId);
      useAuthStore().setSignalRConnectionStatus(connection.state);

      useMeetStore().setConnectionId(connection.connectionId);
    } catch (e) {
      console.error('Failed to invoke method on the server: ' + e);
      useAppStore().setIsLoading(false);

      await _retryConnection();
    }
  }

  async function _startConnection(connection: HubConnection): Promise<boolean> {
    try {
      useAppStore().setIsLoading(true);
      useAppStore().setIsWaitingForCompleteLogin(false);

      await connection.start();
      console.log(`[INFO] SignalR connection started. Id: ${connection.connectionId}`);
      return true;
    } catch (e) {
      console.error('[ERROR] SignalR connection error', e);
      useAppStore().setIsLoading(false);
      return false;
    }
  }

  async function _initSignal(model: WebSocketModel): Promise<void> {
    connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${model.webSocket}/events`)
      .build();

    console.log('[INFO] SignalR connection has been initialized:', connection);

    _registerConnectionEvents();

    useMeet().registerEventsVideo(connection);

    await startWebSockets();
  }
  //#endregion

  //#region Public methods
  async function initWebSockets(model: WebSocketModel | null): Promise<void> {
    if (!model) {
      console.error('Initialization of WebSockets failed - model is null', model);
      return;
    }

    if (connection) await connection.stop();

    useAuthStore().setSignalRConnectionStatus(HubConnectionState.Connecting);

    await _initSignal(model);
  }

  async function startWebSockets(): Promise<boolean> {
    let isConnectSignalR = false;

    try {
      const model = useAuthStore().getWebSocketModel;
      if (!model) {
        console.error('Initialization of WebSockets failed - model is null', model);
        return false;
      }

      //NOTE: If the connection is not initialized, initialize it
      if (!connection) {
        _registerConnectionEvents();
        if (!connection) {
          console.error('Initialization of WebSockets failed - connection is null', connection);
          return false;
        }
      }

      //NOTE: Setting WebSocket as active
      isActive = true;

      useAppStore().setIsLoading(false);

      //NOTE: Trying to start the connection
      isConnectSignalR = await _startConnection(connection);

      //NOTE: Trying to connect to the server
      if (isConnectSignalR) {
        await _invokeConnection(connection, model);
      }
      return isConnectSignalR;
    } catch (err) {
      console.error('Starting WebSockets failed', err);
      return false;
    }
  }

  async function stopWebSockets(): Promise<boolean> {
    isActive = false;
    try {
      if (!connection) {
        console.warn('Connection is already undefined, nothing to stop');
        return false;
      }

      console.log(`[INFO] Trying to stop WebSocket connection: ${connection.connectionId}...`);
      await connection.stop();
      return true;
    } catch (err) {
      console.error('Stopping WebSockets failed', err);
      return false;
    }
  }
  //#endregion

  instance = {
    get typingEvents(): EventUserTyping[] {
      return typingEvents;
    },
    initWebSockets,
    startWebSockets,
    stopWebSockets,
  };

  return instance;
}

import { toastController } from '@ionic/vue';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import { ResponseErrorCodesEnum } from '@/enums';
import { useErrors, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import { resetAllLoadingStates, useAuthStore } from '@/store';
import type { ResponseErrorModel } from '@/types';
import { getAdditionalHeaders, getBaseUrl, getCommonHeaders } from '@/services/getters';
import { skipAuthCheck } from '@/services/checkers';
import { handleAbort, handleSessionTerminate } from '@/services/handlers';
import { buildErrorResponse } from '@/services/builders';
import { Capacitor } from '@capacitor/core';

//#region Constants
const RETRY_COUNT = Number(import.meta.env.VITE_AXIOS_RETRY_COUNT);
const RETRY_DELAY = Number(import.meta.env.VITE_AXIOS_RETRY_DELAY);
//#endregion

//#region Helpers
/**
 * Checks if the error indicates that the server is unavailable
 */
function _isServerUnavailable(error: AxiosError): boolean {
  return (
    axios.isAxiosError(error) &&
    (error.code === ResponseErrorCodesEnum.ERR_NETWORK ||
      error.code === ResponseErrorCodesEnum.ECONNABORTED ||
      error.code === ResponseErrorCodesEnum.ETIMEDOUT ||
      error.message.includes('CORS') ||
      error.message.includes('cors') ||
      [502, 503, 504, 521, 522].includes(error.response?.status ?? -1))
  );
}

/**
 * Retry behavior configuration using exponential backoff
 */
function _retryDelay(retryCount: number): number {
  return Math.min(RETRY_DELAY * Math.pow(2, retryCount - 1), 30000);
}

/**
 * Checks if the error indicates that the server is unavailable
 */
function _retryCondition(error: AxiosError): boolean {
  return (
    error.request.responseType !== 'blob' &&
    !error.config?.url?.startsWith('/oauth') &&
    !error.config?.url?.startsWith('/account/registration') &&
    !_isServerUnavailable(error) &&
    !!error.status &&
    ![401, 403, 404, 405].includes(error.status)
  );
}

/**
 * Manages the toast notifications during retry attempts
 */
async function _handleRetry(retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig): Promise<void> {
  const { t } = useI18n();

  if (retryCount === 1) {
    const { showRetrySonnerToast } = useToasts();

    const retryAction = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await axiosInstance(requestConfig);
      } catch (e) {
        console.error('[ERROR] Retrying request failed:', e);
      }
    };
    showRetrySonnerToast(error.message || t('errorResponse'), t('retry'), retryAction);
  }

  if (retryCount === RETRY_COUNT) {
    const toast = await toastController.getTop();
    toast && (await toastController.dismiss());
    if (error.response?.status !== 200) {
      useErrors().handleError({
        show: true,
        error,
        message: undefined,
      });
    }
  }
}

/**
 * Helper function to add necessary headers to the request configuration
 */
function _addHeaders(config: AxiosRequestConfig, accessToken: string): void {
  if (accessToken) {
    config.headers = {
      ...config.headers,
      Authorization: `${import.meta.env.VITE_API_AUTH_KEY} ${accessToken}`,
    };
  }

  config.headers = {
    ...config.headers,
    ...getAdditionalHeaders(),
  };
}
//#endregion

//#region Axios Instance
const axiosInstance = axios.create({
  //NOTE: Use differ=true so HostApp is omitted from the common headers
  headers: { common: getCommonHeaders(true) },
});

axiosRetry(axiosInstance, {
  retries: RETRY_COUNT,
  retryDelay: _retryDelay,
  retryCondition: (error: AxiosError) => _retryCondition(error),
  onRetry: async (retryCount: number, error: AxiosError, requestConfig: AxiosRequestConfig) => {
    await _handleRetry(retryCount, error, requestConfig);
  },
});

axiosInstance.interceptors.request.use(
  async function onFulfilled(config: any): Promise<any> {
    const controller = new AbortController();
    config.signal = controller.signal;
    config.baseURL = getBaseUrl();

    //NOTE: For mobile requests, add HostApp header after the store is initialized
    if (Capacitor.isNativePlatform()) {
      config.headers.HostApp = useAuthStore().host;
    }

    if (skipAuthCheck(config.url ?? '')) {
      _addHeaders(config, useAuthStore().accessToken);
      return config;
    }

    if (!useAuthStore().isAuth()) {
      console.debug('[DEBUG] Session is NOT valid. Attempting revalidation...');
      await useAuthStore().token({ force: true });

      if (!useAuthStore().isAuth()) {
        const message = `Session revalidation failed. Redirecting to login.`;
        const error = new AxiosError(message, ResponseErrorCodesEnum.Unauthorized, config);
        await handleSessionTerminate(error, message);
        return buildErrorResponse(error);
      }
    }

    _addHeaders(config, useAuthStore().accessToken);
    return config;
  },

  function onRejected(error: AxiosError): ResponseErrorModel {
    console.log('[INFO] [Request interceptor] triggered');
    useErrors().handleError({
      show: true,
      error,
      message: undefined,
    });

    if (_isServerUnavailable(error)) {
      handleAbort();
    }

    resetAllLoadingStates();
    return buildErrorResponse(error);
  }
);

axiosInstance.interceptors.response.use(
  function onFulfilled(response: AxiosResponse): AxiosResponse {
    return Object.assign(response.data, {
      statusCode: response.status,
    });
  },

  function onRejected(error: AxiosError): ResponseErrorModel {
    console.log('[INFO] [Response interceptor] triggered');
    useErrors().handleError({
      show: true,
      error,
      message: undefined,
    });

    if (_isServerUnavailable(error)) {
      handleAbort();
    }

    resetAllLoadingStates();

    return buildErrorResponse(error);
  }
);

export default axiosInstance;
//#endregion

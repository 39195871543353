import {
  AppBarEnum,
  AppIconsEnum,
  AppMenuEnum,
  DataViewMode,
  DocBrowserEntityEnum,
  DocBrowserFilterActionEnum,
  DocBrowserModeEnum,
  DocsMenuActionEnum,
  DocsTableHeaderPropEnum,
  DocumentTypeEnum,
  EventBusEnum,
  FileActionEnum,
  FileStatusEnum,
  GroupsFilterEnum,
  PostUploadFileEnum,
  SortingTypeEnum,
  UploadFileTypes,
  WikiActionEnum,
  WikiCompareModeEnum,
  WikiDownloadOptionsEnum,
} from '@/enums';
import {
  AppBarItem,
  DocBrowserFilterModel,
  DocModel,
  DocsBrowserPathModel,
  DocsDataModeModel,
  DocsUploadFileMenuModel,
  FileModel,
  FolderModel,
  GroupModel,
  MenuItemModel,
  PostUploadFileMenuModel,
  WikiModel,
} from '@/types';
import { DocsIcon } from '@/components';
import { createCellTemplate, formatDateHelper, isAnyMobile, isNativeMobile, serializeQueryParams } from './helper';
import { v4 as uuid_v4 } from 'uuid';

import { FilterType, useAppBar } from './useAppBarHelper';
import { useDocStore, useGroupsStore, useNetworkStore } from '@/store';
import { cloneDeep } from 'lodash';
import { defaultMessageUser } from '@/models';
import router, { ROUTES_NAME } from '@/router';
import { isDocGuard, isFileGuard, isFolderGuard, isWikiGuard } from './guards';
import {
  openChooseUserModal,
  openDocsCreateFileModal,
  openDocsCreateFolderModal,
  openDocsCreateWikiModal,
  openDocsMainMenuModal,
  openDocsUploadFileModal,
  openGroupSelectModal,
} from './modalComponents';
import { useWiki } from './useWikiHelper';
import { docBrowserMainMenuSheet, openDocBrowserContextSheet } from './actionSheetComponents';
import { useOfficeHelper } from './useOfficeHelper';
import { useFileActions } from './useFileActionsHelper';
import { CellTemplateProp, ColumnRegular, HyperFunc, Order, VGridVueTemplate, VNode } from '@revolist/vue3-datagrid';
import { openDocsActionsPopover, openDocsFilterPopover } from './popoverComponents';
import { useFolderActions } from './useFolderActionsHelper';
import { useI18n } from '@/i18n';
import { useFilesHybrid } from './useFilesHybridHelper';
import { useErrors } from './useErrorsHelper';
import { useToasts } from './useToastsHelper';
import { useEventBus } from '@vueuse/core';
import { useAccess } from './useAccessHelper';

export type IDocs = {
  /** Use it to get the document icon */
  getDocumentIcon: (document: DocModel) => AppIconsEnum | string;

  /** Use it to get the document type */
  getDocumentType: (document: DocModel) => string;

  /** Use it to get the table header */
  getTableHeader: (entity: DocBrowserEntityEnum, width: number) => ColumnRegular[];

  /** Use it to get the name of the mode */
  getModeLabel: (mode: DocBrowserModeEnum, group?: GroupModel) => string;

  /** Use it to get the elements of the top bar */
  getAppBarItems: (isMobile: boolean, entity: DocBrowserEntityEnum, docBrowserMode: DocBrowserModeEnum) => AppBarItem[];

  /** Use it to get the list of documents */
  getDocuments: (
    entity: DocBrowserEntityEnum,
    path: DocsBrowserPathModel,
    sort: { direction: Order; prop: DocsTableHeaderPropEnum },
    filter: DocBrowserFilterModel
  ) => Promise<DocsDataModeModel>;

  /** Use it to load more documents */
  loadMoreDocuments: (url: string | null, documents: DocModel[]) => Promise<DocsDataModeModel>;

  /** Use it to get the start folder */
  getStartFolder: (groupId: number | null) => FolderModel;

  /** Use it to get the folder tree for display as breadcrumbs */
  prepareBreadcrumbs: (path: DocsBrowserPathModel) => Promise<FolderModel[]>;

  /** Use it when clicking on a breadcrumb
   *
   * @param breadcrumbs - List of current folders
   * @param clickedFolderId - ID of the folder clicked
   *
   * @returns List of actual folders after the click
   */
  breadcrumbClick: (breadcrumbs: FolderModel[], clickedFolderId: number) => FolderModel[];

  /** Use it to open the context menu of the document.
   *
   * @param document - The document that was clicked on
   */
  openDocumentActionsMenu: (ev: Event, document: DocModel) => Promise<boolean>;

  /** Use it to perform a quick action on a document, bypassing the menu.
   *
   * @param document - The document that was clicked on
   * @param currentPath - Current path
   */
  documentQuickAction: (
    document: DocModel,
    currentPath: DocsBrowserPathModel | null
  ) => Promise<DocsBrowserPathModel | null>;

  /** Use it to get mods to filter out documents. */
  getModeTypes: () => FilterType[];

  /** Use it to create a new folder.
   *
   * @param docs - Actual list of documents
   * @param folderName - Name of the new folder
   * @param parentFolderId - ID of the parent folder
   * @param groupId - ID of the group in which the folder will be created
   *
   * @returns Actual list of documents
   */
  createFolder: (
    docs: DocModel[],
    folderName: string,
    parentFolderId: number | null,
    groupId: number | null
  ) => Promise<DocModel[]>;

  /** Use it to rename a folder.
   *
   * @param folder - Folder to be renamed
   * @param name - New name of the folder
   *
   * @returns Actual folder model
   */
  renameFolder: (folder: FolderModel, name: string) => Promise<FolderModel>;

  /** Use it to create new documents.
   *
   * @param docs - Actual list of documents
   * @param files - List of documents to be created
   * @param folderId - ID of the folder in which the documents will be created
   * @param groupId - ID of the group in which the documents will be created
   *
   * @returns Actual list of documents
   */
  createFiles: (
    docs: DocModel[],
    files: FileModel[],
    folderId: number | null,
    groupId: number | null
  ) => Promise<DocModel[]>;

  /** Use it to rename a document.
   *
   * @param file - Document to be renamed
   * @param name - New name of the document
   * @param description - New description of the document
   *
   * @returns Actual document model
   */
  renameFile: (file: FileModel, name: string, description: string) => Promise<FileModel>;

  /** Use it to open the menu for creating a document.
   *
   * @param groupId - ID of the group in which the document will be created
   * @param folderId - ID of the folder in which the document will be created
   * @param documents - List of current documents
   */
  openCreationMenu: (groupId: number | null, folderId: number | null) => Promise<boolean>;

  docBrowserMenuItems: (isDocBrowser: boolean, groupId: number | null) => DocsUploadFileMenuModel[];

  /** Use it to get items for the menu when creating a document. */
  uploadFileMenuItems: (
    isDocBrowser: boolean,
    groupId: number | null
  ) => PostUploadFileMenuModel[] | DocsUploadFileMenuModel[];

  /** Use it to perform actions when creating a document. */
  creationMenuActions: (
    menuAction: DocsMenuActionEnum,
    groupId: number | null,
    folderId: number | null
  ) => Promise<boolean>;

  /** Use it to get the key for displaying the document */
  getDocumentKey: (document: DocModel) => string;

  /** Use it to open the menu for a file */
  openFileActionsMenu: (ev: Event, file: FileModel, status?: FileStatusEnum) => Promise<boolean>;

  /** Use it to open the menu for a folder */
  openFolderActionsMenu: (ev: Event, folder: FolderModel) => Promise<boolean>;

  /** Use it to open the menu for a wiki */
  openWikiActionsMenu: (ev: Event, wiki: WikiModel) => Promise<boolean>;

  /** Use it to form a navigation string */
  preparePath(
    groupId: number | null,
    folderId: number | null,
    mode: DocBrowserModeEnum,
    search: string
  ): DocsBrowserPathModel;

  /** Use it to open the filter popover */
  openFilterPopover: (ev: Event, entity: DocBrowserEntityEnum) => void;

  /** Use it to get items for the menu when creating a document. */
  getFilterItems(entity: DocBrowserEntityEnum): MenuItemModel<DocBrowserFilterActionEnum>[];
};

export function useDocs(): IDocs {
  //#region Variables
  const docStore = useDocStore();
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Private methods

  /** Use it to get a sorted string */
  const _getSortingString = (data: { direction: Order; prop: DocsTableHeaderPropEnum }): string => {
    const sortDirection = data.direction ? data.direction : 'asc';
    let sort = SortingTypeEnum.Name;

    const sortMap: Partial<Record<DocsTableHeaderPropEnum, SortingTypeEnum>> = {
      [DocsTableHeaderPropEnum.Name]: SortingTypeEnum.Name,
      [DocsTableHeaderPropEnum.Group]: SortingTypeEnum.GroupTitle,
      [DocsTableHeaderPropEnum.Author]: SortingTypeEnum.OwnerName,
      [DocsTableHeaderPropEnum.Date]: SortingTypeEnum.DateCreated,
      [DocsTableHeaderPropEnum.Official]: SortingTypeEnum.Official,
    };

    sort = sortMap[data.prop] ?? SortingTypeEnum.Name;

    return `sortDirection=${sortDirection}&sort=${sort}`;
  };

  /** Use it to get a filtered string */
  const _getFilterString = (data: DocBrowserFilterModel, entity: DocBrowserEntityEnum): string => {
    const filterGroupsIds = data.groups.map((group) => group.id);
    const filterUsersIds = data.users.map((user) => user.id);
    const searchEverywhere = data.searchEverywhere ? 'true' : 'false';

    const getQueryString = (withGroups: boolean) =>
      serializeQueryParams({ ...(withGroups ? { filterGroupsIds } : {}), filterUsersIds, searchEverywhere });

    const map: Record<DocBrowserEntityEnum, () => string> = Object.freeze({
      [DocBrowserEntityEnum.DocsPage]: () => getQueryString(true),
      [DocBrowserEntityEnum.AttachmentModal]: () => getQueryString(false),
      [DocBrowserEntityEnum.SearchPage]: () => '',
      [DocBrowserEntityEnum.GroupPage]: () => getQueryString(false),
      [DocBrowserEntityEnum.FolderModal]: () => getQueryString(false),
    });

    return map[entity]();
  };
  //#endregion

  //#region Public methods

  const getDocumentIcon = (document: DocModel): AppIconsEnum | string => {
    const icons = Object.values(AppIconsEnum);

    const _getTypeIcon = (): AppIconsEnum | string => {
      if (isFileGuard(document.data)) {
        const type = document.data?.type;
        return icons.find((icon) => icon.includes(type)) || AppIconsEnum.Document;
      }
      return AppIconsEnum.Document;
    };
    const map: Record<DocumentTypeEnum, string> = Object.freeze({
      [DocumentTypeEnum.Wiki]: AppIconsEnum.Wiki,
      [DocumentTypeEnum.Folder]: AppIconsEnum.FolderYellowClose,
      [DocumentTypeEnum.ExternalLink]: AppIconsEnum.Link,
      [DocumentTypeEnum.File]: _getTypeIcon(),
      [DocumentTypeEnum.FileVersion]: AppIconsEnum.Document,
    });

    return map[document.documentType] ?? AppIconsEnum.Document;
  };

  const getDocumentType = (document: DocModel): string => {
    if (isFileGuard(document.data)) {
      const type = document.data?.type;
      return type ? `.${type}` : '';
    }

    if (isFolderGuard(document.data) || isWikiGuard(document.data)) {
      return '';
    }

    return '';
  };

  const getModeLabel = (mode: DocBrowserModeEnum, group?: GroupModel): string => {
    const { t } = useI18n();
    const map: Partial<Record<DocBrowserModeEnum, string>> = {
      [DocBrowserModeEnum.All]: t('files.allFiles'),
      [DocBrowserModeEnum.Follow]: t('files.following'),
      [DocBrowserModeEnum.Recent]: t('files.recentlyFiles'),
      [DocBrowserModeEnum.Uploaded]: t('files.uploadedFiles'),
      [DocBrowserModeEnum.Groups]: group?.title ? group.title : t('files.byGroupFiles'),
    };

    return map[mode] ?? '';
  };

  const getTableHeader = (entity: DocBrowserEntityEnum, width: number): ColumnRegular[] => {
    const { t } = useI18n();
    const enableSort = entity !== DocBrowserEntityEnum.SearchPage;
    const enableGroupColumn = entity !== DocBrowserEntityEnum.GroupPage;

    const _columnWidths = {
      icon: 48,
      group: 200,
      author: 200,
      date: 140,
    };

    /** Use it to render the document name */
    const _nameRenderer = (data: CellTemplateProp): string => {
      const document = data.model;
      if (isDocGuard(document)) {
        const name = document.data.name + getDocumentType(document);
        return name ? name : '';
      }
      return '';
    };

    /** Use it to render the group name */
    const _groupRenderer = (data: CellTemplateProp): string => {
      const document = data.model;
      const group = document.data.group;
      return group ? group.title : '';
    };

    /** Use it to render the author name */
    const _authorRenderer = (data: CellTemplateProp): string => {
      const document = data.model;
      const author = document.data.author;
      return author?.fullName ? author.fullName : '';
    };

    /** Use it to render the date when the document was created or edited. */
    const _createdAtRenderer = (data: CellTemplateProp): string => {
      const document = data.model;
      const editedAt = formatDateHelper(document.data?.editedAt ? document.data.editedAt : '', 'short');
      const createdAt = formatDateHelper(document.data.createdAt, 'short');
      return editedAt || createdAt;
    };

    /** Use it to make the name-width adaptive */
    const _nameSize = () => {
      const minSize = 280;
      const groupWidth = enableGroupColumn ? _columnWidths.group : 0;
      const calculatedSize = width - _columnWidths.icon - _columnWidths.author - _columnWidths.date - groupWidth - 40;

      return calculatedSize < minSize ? minSize : calculatedSize;
    };

    return [
      {
        prop: DocsTableHeaderPropEnum.Icon,
        name: '',
        size: _columnWidths.icon,
        cellTemplate: (createElement: HyperFunc<VNode>, props: CellTemplateProp) => {
          const document = props.model as DocModel;

          return VGridVueTemplate(DocsIcon, {
            name: getDocumentIcon(document),
            class: 'flex jc-center ai-center',
            iconProps: {
              width: '26',
              height: '26',
              fill: 'var(--ion-color-medium)',
            },
            isOfficial: isWikiGuard(document.data) || isFileGuard(document.data) ? document.data.isOfficial : false,
          })(createElement, props);
        },
      },
      {
        prop: DocsTableHeaderPropEnum.Name,
        name: t('table.title'),
        size: _nameSize(),
        sortable: enableSort,
        cellTemplate: createCellTemplate(_nameRenderer),
      },
      /* {
        prop: DocsTableHeaderPropEnum.Official,
        name: t('table.official'),
        size: _columnWidths.official,
        sortable: enableSort,
        cellTemplate: (createElement: HyperFunc<VNode>, props: CellTemplateProp) => {
          if (!_isOfficial(props)) return '-';

          return VGridVueTemplate(IconsProvider, {
            name: AppIconsEnum.Star,
            class: 'flex jc-center ai-center',
            iconProps: {
              width: '14',
              height: '14',
              fill: 'var(--ion-color-medium)',
            },
          })(createElement, props);
        },
      }, */
      ...(enableGroupColumn
        ? [
            {
              prop: DocsTableHeaderPropEnum.Group,
              name: t('table.group'),
              size: _columnWidths.group,
              sortable: enableSort,
              cellTemplate: createCellTemplate(_groupRenderer),
            },
          ]
        : []),
      {
        prop: DocsTableHeaderPropEnum.Author,
        name: t('table.author'),
        size: _columnWidths.author,
        sortable: enableSort,
        cellTemplate: createCellTemplate(_authorRenderer),
      },
      {
        prop: DocsTableHeaderPropEnum.Date,
        name: t('table.dateCreated'),
        size: _columnWidths.date,
        autoSize: true,
        sortable: enableSort,
        cellTemplate: createCellTemplate(_createdAtRenderer),
      },
    ];
  };

  const getAppBarItems = (
    isMobile: boolean,
    entity: DocBrowserEntityEnum,
    docBrowserMode: DocBrowserModeEnum
  ): AppBarItem[] => {
    const { t } = useI18n();
    const networkStore = useNetworkStore();
    const activeFolder = docStore.activeFolder;

    const creationIsDisabled =
      entity === DocBrowserEntityEnum.AttachmentModal ||
      entity === DocBrowserEntityEnum.FolderModal ||
      isMobile ||
      !useAccess().canCreateDocument(docStore.activeGroup, networkStore.settings?.allowPostToFeed ?? false);

    const modeIsDisabled =
      entity === DocBrowserEntityEnum.GroupPage ||
      entity === DocBrowserEntityEnum.FolderModal ||
      isMobile ||
      activeFolder !== null;

    return [
      {
        title: t('update'),
        subTitle: '',
        value: AppBarEnum.Refresh,
        icon: AppIconsEnum.Refresh,
        disabled: false,
      },
      {
        title: t('search.waiting'),
        subTitle: '',
        value: AppBarEnum.Search,
        icon: AppIconsEnum.SearchSm,
        disabled: false,
      },
      {
        title: t('viewType'),
        subTitle: '',
        value: AppBarEnum.ViewMode,
        icon:
          useAppBar().selectedViewMode(AppMenuEnum.Docs) === DataViewMode.Grid
            ? AppIconsEnum.Table2
            : AppIconsEnum.List,
        disabled: isMobile,
      },
      {
        title: t('files.mode'),
        subTitle: getModeLabel(docBrowserMode),
        value: AppBarEnum.FilterType,
        icon: AppIconsEnum.Eye,
        disabled: modeIsDisabled,
      },
      {
        title: t('filter'),
        subTitle: '',
        value: AppBarEnum.DocsFilter,
        icon: AppIconsEnum.Filter,
        disabled: isMobile,
      },
      {
        title: t('files.newDocument'),
        subTitle: '',
        value: AppBarEnum.Create,
        icon: AppIconsEnum.Add,
        disabled: creationIsDisabled,
      },
      {
        title: '',
        subTitle: '',
        value: AppBarEnum.Menu,
        icon: AppIconsEnum.MenuDotsVertical,
        disabled: !isMobile,
      },
    ].filter(({ disabled }) => !disabled);
  };

  const getDocuments = async (
    entity: DocBrowserEntityEnum,
    path: DocsBrowserPathModel,
    sort: { direction: Order; prop: DocsTableHeaderPropEnum },
    filter: DocBrowserFilterModel
  ): Promise<DocsDataModeModel> => {
    const sortString = _getSortingString(sort);
    const filterString = _getFilterString(filter, entity);

    const _getByFolderOrGroup = async (folderId: number | null, groupId: number | null): Promise<DocsDataModeModel> => {
      /** Get documents from folder, if folderId is provided */
      if (folderId) return await docStore.allDocsFromFolderId(folderId, sortString, path.search, filterString);

      /** Get documents from group, if groupId is provided */
      if (groupId) return await docStore.allDocsFromGroupId(groupId, sortString, path.search, filterString);

      return {
        data: [],
        loadMoreUrl: null,
      };
    };

    /** Get documents in docs browser page */
    const _byDocsPage = async (): Promise<DocsDataModeModel> => {
      if (path.folderId || path.groupId) return await _getByFolderOrGroup(path.folderId, path.groupId);

      /** Get documents from all, if no folderId or groupId is provided */
      return await docStore.allDocs(path.mode, sortString, path.search, filterString);
    };

    /** Get documents in group page */
    const _byGroupPage = async (): Promise<DocsDataModeModel> => {
      if (path.groupId) return await _getByFolderOrGroup(path.folderId, path.groupId);

      return {
        data: [],
        loadMoreUrl: null,
      };
    };

    /** Get documents in attachment modal */
    const _byAttachmentModal = async (): Promise<DocsDataModeModel> => {
      if (path.folderId || path.groupId) return await _getByFolderOrGroup(path.folderId, path.groupId);

      /** Use it to get documents only from the network (which do not have a group)*/
      return await docStore.allDocsFromNetworkOnly(sortString, path.search, filterString);
    };

    /** Get documents in folder preview modal */
    const _byFolderModal = async (): Promise<DocsDataModeModel> => {
      if (path.folderId || path.groupId) return await _getByFolderOrGroup(path.folderId, path.groupId);

      return {
        data: [],
        loadMoreUrl: null,
      };
    };

    /** Get documents in global search page */
    const _bySearchPage = async (): Promise<DocsDataModeModel> => {
      return await docStore.getGlobalSearchDocs;
    };

    const map: Record<DocBrowserEntityEnum, () => Promise<DocsDataModeModel>> = Object.freeze({
      [DocBrowserEntityEnum.DocsPage]: _byDocsPage,
      [DocBrowserEntityEnum.AttachmentModal]: _byAttachmentModal,
      [DocBrowserEntityEnum.SearchPage]: _bySearchPage,
      [DocBrowserEntityEnum.GroupPage]: _byGroupPage,
      [DocBrowserEntityEnum.FolderModal]: _byFolderModal,
    });

    return await map[entity]();
  };

  const loadMoreDocuments = async (url: string | null, documents: DocModel[]): Promise<DocsDataModeModel> => {
    if (!url) return { data: documents, loadMoreUrl: null };
    const response = await docStore.loadMore(url);
    return { data: [...documents, ...response.data], loadMoreUrl: response.loadMoreUrl };
  };

  const getStartFolder = (groupId: number | null): FolderModel => {
    const { t } = useI18n();
    const folder = {
      id: 0,
      access: [],
      name: t('files.allFiles'),
      author: cloneDeep(defaultMessageUser),
      createdAt: '',
      group: null,
      parentFolderId: null,
      description: null,
    } as FolderModel;

    if (groupId) {
      const groupStore = useGroupsStore();
      const group = groupStore.getGroupById(groupId);
      folder.id = 0;
      folder.name = t('table.group') + `: ${group.title}`;
      return folder;
    }

    return folder;
  };

  const prepareBreadcrumbs = async (path: DocsBrowserPathModel): Promise<FolderModel[]> => {
    const { t } = useI18n();
    const startFolder = getStartFolder(path.groupId);

    let breadcrumbs: FolderModel[] = [];

    if (!path.folderId) {
      docStore.setActiveFolder(null);
    } else {
      /**  We receive data only for the last folder in the path */
      try {
        const fullPathFolder = await docStore.getFolderPath(path.folderId);
        /**  If the path does not exist, we return the start folder */
        if (!fullPathFolder) {
          showSonnerToast(t('files.folderPathFailed'), false);
          console.warn('[WARN] Failed to get folder path');

          /** If we are on the docs page, we redirect to the docs page with reset params */
          const isDocsPage = router.currentRoute.value.name === ROUTES_NAME.DOCS;
          if (isDocsPage) {
            await router.push({
              name: ROUTES_NAME.DOCS,
            });
            docStore.setActiveFolder(null);
          }
          return [startFolder];
        }

        /**  Creating breadcrumbs using data from the received path */
        breadcrumbs = fullPathFolder.map((folder) => {
          return {
            id: folder.id,
            name: folder.name || `Folder ${folder.id}`,
            parentFolderId: folder.parentId || null,
            access: [],
            author: cloneDeep(defaultMessageUser),
            createdAt: '',
            group: null,
            description: null,
          };
        });
        docStore.setActiveFolder(breadcrumbs[breadcrumbs.length - 1] ?? null);
      } catch (e) {
        console.warn('[WARN] Failed when mapping breadcrumbs', e);
      }
    }

    return [startFolder, ...breadcrumbs];
  };

  const breadcrumbClick = (breadcrumbs: FolderModel[], clickedFolderId: number): FolderModel[] => {
    let newBreadcrumbs = breadcrumbs;

    if (clickedFolderId === 0) {
      newBreadcrumbs = [breadcrumbs[0]];
      docStore.setActiveFolder(null);
    } else {
      const index = breadcrumbs.findIndex((folder) => folder.id === clickedFolderId);
      if (~index) {
        newBreadcrumbs = breadcrumbs.slice(0, index + 1);
        docStore.setActiveFolder(newBreadcrumbs[newBreadcrumbs.length - 1]);
      }
    }

    return newBreadcrumbs;
  };

  const documentQuickAction = async (
    document: DocModel,
    currentPath: DocsBrowserPathModel | null
  ): Promise<DocsBrowserPathModel | null> => {
    const { t } = useI18n();
    const { handleError } = useErrors();

    const _openFolder = async (folder: FolderModel): Promise<DocsBrowserPathModel> => {
      return {
        groupId: currentPath?.groupId ?? null,
        folderId: folder.id,
        mode: currentPath?.mode || DocBrowserModeEnum.All,
        search: currentPath?.search || '',
      };
    };

    if (isFolderGuard(document.data)) {
      docStore.setActiveFolder(document.data);
      return await _openFolder(document.data);
    }

    if (isFileGuard(document.data)) {
      const isImage =
        document.data.mimeType.startsWith('image') &&
        !useFilesHybrid().unsupportedImageFormats.includes(document.data.type);

      try {
        if (isImage) {
          useFileActions().imageView(document.data);
          return null;
        }

        //NOTE: #1658 If file is audio, open it directly
        if (useFilesHybrid().fileIsAudio(document.data)) {
          useFilesHybrid().openFile(document.data);
          return null;
        }

        if (await useFileActions().isPreviewAvailable(document.data)) {
          router.push({
            name: ROUTES_NAME.FILE_BY_ID,
            params: { id: document.data.id },
          });
          return null;
        }

        // Download
        await useFileActions().downloadFile(document.data);
      } catch (e: any) {
        handleError({
          show: true,
          error: e,
          message: t('errorResponse'),
        });
        return null;
      }
    }

    if (isWikiGuard(document.data)) {
      /** Open wiki */
      await useWiki().handleAction({ type: WikiActionEnum.ToCurrent, id: document.data.id });
    }

    return null;
  };

  const getModeTypes = (group?: GroupModel): FilterType[] => {
    return [
      {
        title: getModeLabel(DocBrowserModeEnum.All),
        value: DocBrowserModeEnum.All,
        enabled: true,
      },
      {
        title: getModeLabel(DocBrowserModeEnum.Follow),
        value: DocBrowserModeEnum.Follow,
        enabled: true,
      },
      {
        title: getModeLabel(DocBrowserModeEnum.Recent),
        value: DocBrowserModeEnum.Recent,
        enabled: true,
      },
      {
        title: getModeLabel(DocBrowserModeEnum.Uploaded),
        value: DocBrowserModeEnum.Uploaded,
        enabled: true,
      },
      {
        title: getModeLabel(DocBrowserModeEnum.Groups, group),
        value: DocBrowserModeEnum.Groups,
        enabled: true,
      },
    ].filter((n) => n.enabled);
  };

  const createFolder = async (
    docs: DocModel[],
    folderName: string,
    parentFolderId: number | null,
    groupId: number | null
  ): Promise<DocModel[]> => {
    const createdFolder = await docStore.createFolder(folderName, parentFolderId, groupId);
    if (!createdFolder) return docs;
    return [createdFolder, ...docs];
  };

  const renameFolder = async (folder: FolderModel, name: string): Promise<FolderModel> => {
    const renamedFolder = await docStore.renameFolder(folder.id, name);
    if (!renamedFolder) return folder;
    return { ...folder, name };
  };

  const createFiles = async (
    docs: DocModel[],
    files: FileModel[],
    folderId: number | null,
    groupId: number | null
  ): Promise<DocModel[]> => {
    const createdFiles = await docStore.createFiles(files, folderId, groupId);
    if (!createdFiles) return docs;
    return [...createdFiles, ...docs];
  };

  const renameFile = async (file: FileModel, name: string, description: string): Promise<FileModel> => {
    const renamedFile = await docStore.renameFile(file.id, name, description);
    if (!renamedFile) return file;
    return { ...file, name, description };
  };

  const uploadFileMenuItems = (
    isDocBrowser: boolean,
    groupId: number | null
  ): PostUploadFileMenuModel[] | DocsUploadFileMenuModel[] => {
    const wikiHelper = useWiki();
    const { t } = useI18n();
    const items = [
      {
        title: t('files.createDocument'),
        icon: AppIconsEnum.Doc,
        value: isDocBrowser ? DocsMenuActionEnum.CreateFile : PostUploadFileEnum.CreateDocument,
        disabled: !useOfficeHelper().isUserGrantedForActions.value,
      },
      {
        title: t('createWiki'),
        icon: AppIconsEnum.Wiki,
        value: isDocBrowser ? DocsMenuActionEnum.CreateWiki : PostUploadFileEnum.CreateWiki,
        disabled: !wikiHelper.getCreateAccess(groupId),
      },
      {
        title: t('files.menu.uploadFromDevice'),
        icon: AppIconsEnum.Upload,
        value: isDocBrowser ? DocsMenuActionEnum.UploadFile : PostUploadFileEnum.UploadFromDevice,
        disabled: false,
      },
      ...(!isDocBrowser
        ? [
            {
              title: t('files.menu.selectFromNetwork'),
              value: PostUploadFileEnum.UploadFromGroup,
              icon: AppIconsEnum.FolderYellowAdd,
              disabled: false,
            },
          ]
        : [
            {
              icon: AppIconsEnum.FolderYellowAdd,
              disabled: false,
              value: DocsMenuActionEnum.CreateFolder,
              title: t('files.createFolder.title'),
            },
            {
              icon: AppIconsEnum.Upload,
              disabled: true,
              value: DocsMenuActionEnum.UploadFolder,
              title: t('files.uploadFolder.title'),
            },
          ]),
    ];

    return items.filter(({ disabled }) => !disabled);
  };

  const docBrowserMenuItems = (isDocBrowser: boolean, groupId: number | null): DocsUploadFileMenuModel[] => {
    return uploadFileMenuItems(isDocBrowser, groupId) as DocsUploadFileMenuModel[];
  };

  const openCreationMenu = async (groupId: number | null, folderId: number | null): Promise<boolean> => {
    const result = isAnyMobile ? await docBrowserMainMenuSheet(groupId) : await openDocsMainMenuModal(groupId);

    if (result) {
      const creationResult = await creationMenuActions(result, groupId, folderId);
      if (creationResult) return true;
    }

    return false;
  };

  const creationMenuActions = async (
    menuAction: DocsMenuActionEnum,
    groupId: number | null,
    folderId: number | null
  ): Promise<boolean> => {
    const groupStore = useGroupsStore();
    let group: GroupModel | undefined = undefined;

    if (groupId && groupId > 0) {
      group = groupStore.getGroupById(Number(groupId));
    }

    const actions: Record<DocsMenuActionEnum, () => Promise<boolean>> = {
      [DocsMenuActionEnum.UploadFile]: async () =>
        !!(await openDocsUploadFileModal(UploadFileTypes.ManyDifferentFiles, undefined, undefined, undefined, group)),
      [DocsMenuActionEnum.UploadFolder]: async () => {
        //TODO: await componentDocsUploadFolder();
        return false;
      },
      [DocsMenuActionEnum.CreateFile]: async () => {
        await openDocsCreateFileModal(true, group?.id || undefined, folderId || undefined);
        return false;
      },
      [DocsMenuActionEnum.CreateFolder]: async () => !!(await openDocsCreateFolderModal(group)),
      [DocsMenuActionEnum.CreateWiki]: async () => {
        const result = await openDocsCreateWikiModal(group?.id, folderId);
        if (!result) {
          console.warn('[WARN] No action for wiki');
          return false;
        }
        await useWiki().handleAction({ type: WikiActionEnum.Create, id: 0 });
        return false;
      },
    };

    if (actions[menuAction]) {
      return await actions[menuAction]();
    }

    return false;
  };

  const getDocumentKey = (document: DocModel): string => {
    if (isWikiGuard(document.data)) return `wiki_${document.data.id}`;

    if (isFileGuard(document.data)) return `file_${document.data.id}`;

    if (isFolderGuard(document.data)) return `folder_${document.data.id}`;

    return `document_${uuid_v4()}`;
  };

  const openDocumentActionsMenu = async (ev: Event, document: DocModel, status?: FileStatusEnum): Promise<boolean> => {
    if (isFileGuard(document.data)) {
      return await openFileActionsMenu(ev, document.data, status);
    }

    if (isFolderGuard(document.data)) {
      return await openFolderActionsMenu(ev, document.data);
    }

    if (isWikiGuard(document.data)) {
      return await openWikiActionsMenu(ev, document.data);
    }

    return false;
  };

  const openFileActionsMenu = async (ev: Event, file: FileModel, status?: FileStatusEnum): Promise<boolean> => {
    const isFileVersion = useFileActions().isFileVersion(file);

    let data;
    if (isNativeMobile) {
      data = await openDocBrowserContextSheet({
        documentType: isFileVersion ? DocumentTypeEnum.FileVersion : DocumentTypeEnum.File,
        data: file,
      });
    } else {
      data = await openDocsActionsPopover(ev, {
        documentType: isFileVersion ? DocumentTypeEnum.FileVersion : DocumentTypeEnum.File,
        data: file,
      });
    }

    if (!data) {
      console.warn('[WARN] Failed to open file actions menu');
      return false;
    }

    return (await useFileActions().whichActionToMake(data as FileActionEnum, file, status)) ?? false;
  };

  const openFolderActionsMenu = async (ev: Event, folder: FolderModel): Promise<boolean> => {
    let data;
    if (isNativeMobile) {
      data = await openDocBrowserContextSheet({
        documentType: DocumentTypeEnum.Folder,
        data: folder,
      });
    } else {
      data = await openDocsActionsPopover(ev, {
        documentType: DocumentTypeEnum.Folder,
        data: folder,
      });
    }

    if (!data) {
      console.warn('[WARN] Failed to open folder actions menu');
      return false;
    }

    return (await useFolderActions().whichActionToMake(data as FileActionEnum, folder)) ?? false;
  };

  const openWikiActionsMenu = async (ev: Event, wiki: WikiModel): Promise<boolean> => {
    let data: FileActionEnum | WikiActionEnum | WikiDownloadOptionsEnum | WikiCompareModeEnum | undefined;
    if (isNativeMobile) {
      data = await openDocBrowserContextSheet({
        documentType: DocumentTypeEnum.Wiki,
        data: wiki,
      });
    } else {
      data = await openDocsActionsPopover(ev, {
        documentType: DocumentTypeEnum.Wiki,
        data: wiki,
      });
    }

    if (!data) {
      console.warn('[WARN] Failed to open wiki actions menu');
      return false;
    }

    const result = await useWiki().handleAction({
      type: data as WikiActionEnum,
      id: wiki.id,
      model: wiki,
      ev,
    });

    return result === true ? true : false;
  };

  const preparePath = (
    groupId: number | null,
    folderId: number | null,
    mode: DocBrowserModeEnum,
    search: string
  ): DocsBrowserPathModel => ({
    groupId: groupId ?? null,
    folderId: folderId ?? null,
    mode,
    search,
  });

  const getFilterItems = (entity: DocBrowserEntityEnum): MenuItemModel<DocBrowserFilterActionEnum>[] => {
    const filterUsers = docStore.filter.users;
    const filterGroups = docStore.filter.groups;
    const filterIsEmpty = !filterUsers.length && !filterGroups.length;

    const { t } = useI18n();

    return [
      {
        value: DocBrowserFilterActionEnum.SelectGroup,
        active: entity === DocBrowserEntityEnum.DocsPage,
        icon: AppIconsEnum.Users,
        title: t('appMenu.groups'),
        subTitle: filterGroups.map((n) => n.title).join(', '),
      },
      {
        value: DocBrowserFilterActionEnum.SelectUser,
        active: true,
        icon: AppIconsEnum.User,
        title: t('table.author'),
        subTitle: filterUsers.map((n) => n.fullName).join(', '),
      },
      {
        value: DocBrowserFilterActionEnum.ResetFilter,
        active: !filterIsEmpty,
        icon: AppIconsEnum.Close,
        title: t('files.resetFilter'),
      },
    ].filter((item) => item.active);
  };

  const openFilterPopover = async (ev: Event, entity: DocBrowserEntityEnum) => {
    const { t } = useI18n();
    const searchEverywhere = docStore.filter?.searchEverywhere ?? false;
    const filterUsers = docStore.filter.users;
    const filterGroups = docStore.filter.groups;
    let result: DocBrowserFilterActionEnum | undefined = undefined;

    result = (await openDocsFilterPopover(ev, entity)).data;

    const { emit: changeFilter } = useEventBus<string>(EventBusEnum.DocBrowserChangeFilter + `_${entity}`);

    const actions: Record<DocBrowserFilterActionEnum, () => Promise<void>> = {
      [DocBrowserFilterActionEnum.SelectGroup]: async () => {
        const groups = await openGroupSelectModal(
          GroupsFilterEnum.All,
          false,
          filterGroups,
          undefined,
          undefined,
          true
        );

        if (groups) {
          docStore.setFilterGroups(groups);
          changeFilter();
        }
      },
      [DocBrowserFilterActionEnum.SelectUser]: async () => {
        const users = await openChooseUserModal([], t('appMenu.people'), false, filterUsers);

        if (users) {
          docStore.setFilterUsers(users);
          changeFilter();
        }
      },
      [DocBrowserFilterActionEnum.ResetFilter]: async () => {
        docStore.setFilterUsers([]);
        docStore.setFilterGroups([]);
        changeFilter();
      },
      [DocBrowserFilterActionEnum.EnableSearchEverywhere]: async () => {
        docStore.setSearchEverywhere(!searchEverywhere);
        changeFilter();
      },
    };

    if (result && actions[result]) {
      return await actions[result]();
    }
  };

  //#endregion

  return {
    getDocumentIcon,
    getDocumentType,
    getTableHeader,
    getAppBarItems,
    getDocuments,
    loadMoreDocuments,
    getStartFolder,
    prepareBreadcrumbs,
    breadcrumbClick,
    documentQuickAction,
    getModeTypes,
    createFolder,
    renameFolder,
    createFiles,
    renameFile,
    creationMenuActions,
    openCreationMenu,
    docBrowserMenuItems,
    getDocumentKey,
    uploadFileMenuItems,
    getModeLabel,
    openDocumentActionsMenu,
    openFileActionsMenu,
    openFolderActionsMenu,
    openWikiActionsMenu,
    preparePath,
    openFilterPopover,
    getFilterItems,
  };
}

import { DEFAULT_LOCALE, SUPPORT_LOCALES } from '@/i18n';
import { useAuthStore } from '@/store';
import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/core';

/**
 * Returns common headers.
 *
 * @param differ - If true, omit adding the HostApp header (used by axios)
 * @returns Object with common headers (Accept-Language, and conditionally HostApp)
 */
export function getCommonHeaders(differ = false): Record<string, string> {
  const isMobile = Capacitor.isNativePlatform();
  const language = window.navigator.language.substring(0, 2).toLowerCase();
  const common: Record<string, string> = {
    'Accept-Language': SUPPORT_LOCALES.includes(language) ? language : DEFAULT_LOCALE,
  };

  //NOTE: Only add HostApp header if not using the differ mode
  if (isMobile && !differ) {
    common.HostApp = useAuthStore().host;
  }

  return common;
}

/**
 * Use it to get additional headers for the request
 *
 * @returns ApiVersion, BrowserPlatforms, CapacitorPlatform, IsNativePlatform
 */
export function getAdditionalHeaders(): Record<string, string | string[]> {
  const additional: Record<string, string | string[]> = {
    ApiVersion: import.meta.env.VITE_API_VERSION,
    BrowserPlatforms: getPlatforms(),
    CapacitorPlatform: Capacitor.getPlatform(),
    IsNativePlatform: Capacitor.isNativePlatform() ? 'True' : 'False',
  };

  return additional;
}

/**
 * Use it to get the baseUrl for your request
 */
export function getBaseUrl(): string {
  return `${`https://${useAuthStore().host}`}${import.meta.env.VITE_API_URL}`;
}

/**
 * Use it to get the fullURL from provided uri
 *
 * Consists of:
 * - Protocol: `https://`
 * - Host: `[subdomain].[environment].[domain].[tld]`
 * - Path: `/net_home`
 *
 * @example https://hamstersnet.dev3.communex.app/net_home
 * @example https://vdsi.intrakommuna.net/net_home
 * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1792
 */
export function getFullURL(host: string): string {
  try {
    // Clean and normalize the host string
    const cleanHost = host
      .replace(/^https?:\/\//, '') // Remove any existing protocol
      .trim()
      .toLowerCase();

    // Always use https:// protocol
    return `https://${cleanHost}/net_home`;
  } catch (error) {
    console.error('[ERROR] Invalid host structure', error);
    return '';
  }
}

import { ShareArchiveLinkType, DocBrowserModeEnum } from '@/enums';
import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseDocsModel,
  ResponseTreeFolderModel,
  ResponseDocsCreateFolderModel,
  ResponseDocsCreateFileModel,
  ResponseSuccessModel,
  FileModel,
  ResponsePostModel,
  ResponseDocModel,
  ResponseDocsHistoryByIdModel,
  ResponseDocsHistoryModel,
  ResponseDocsFileTagsModel,
  ResponseDocsRelationsFileModel,
  ResponseDocsFileFollowersModel,
} from '@/types';

export class DocApiService {
  async loadMore(url: string): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async getDocById(fileId: number): Promise<ResponseDocModel | ResponseErrorModel> {
    return axios.get(`/files/byId/${fileId}`);
  }
  async getAllDocs(
    mode: DocBrowserModeEnum,
    sort: string,
    search: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?mode=${mode}&${sort}&search=${search}&${filter}`);
  }
  async getAllDocsFromNetworkOnly(
    sort: string,
    search: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?mode=${DocBrowserModeEnum.Groups}&${sort}&search=${search}&${filter}`);
  }
  async getAllDocsFromGroupId(
    groupId: number,
    sort: string,
    search: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(
      `/files/all?groupId=${groupId}&mode=${DocBrowserModeEnum.Groups}&${sort}&search=${search}&${filter}`
    );
  }
  async getAllDocsFromFolderId(
    folderId: number,
    sort: string,
    search: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?parentFolderId=${folderId}&${sort}&search=${search}&${filter}`);
  }
  /* async getAllDocsByText(search: string, mode: DocBrowserModeEnum): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?search=${search}&mode=${mode}&searchAllFolders=true`);
  } */
  async getAllDocsByQuery(
    search: string,
    mode: DocBrowserModeEnum,
    query: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?search=${search}&mode=${mode}&searchAllFolders=true&${query}`);
  }
  async getAllDocsFromGroupIdByText(
    search: string,
    mode: DocBrowserModeEnum,
    groupId: number
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?search=${search}&mode=${mode}&groupId=${groupId}&searchAllFolders=true`);
  }
  async getAllDocsFromFolderIdByText(
    search: string,
    mode: DocBrowserModeEnum, //! search in folder should always be in 'All' mode
    folderId: number
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?search=${search}&mode=All&parentFolderId=${folderId}&searchAllFolders=true`);
  }
  async getFoldersTree(): Promise<ResponseTreeFolderModel | ResponseErrorModel> {
    return axios.get(`/folders/tree`);
  }
  async getFoldersTreeByGroupId(groupId: number): Promise<ResponseTreeFolderModel | ResponseErrorModel> {
    return axios.get(`/folders/tree?groupId=${groupId}`);
  }
  async createFolder(
    folderName: string,
    parentFolderId: number | null = null,
    groupId: number | null = null
  ): Promise<ResponseDocsCreateFolderModel | ResponseErrorModel> {
    return axios.post('/folders/create', {
      folderName,
      parentFolderId,
      groupId,
    });
  }
  async createFiles(
    entities: any,
    folderId: number | null = null,
    groupId: number | null = null
  ): Promise<ResponseDocsCreateFileModel | ResponseErrorModel> {
    const files = entities.map(
      (f: any) =>
        ({
          key: f.key,
          name: f.name,
          type: f.type,
          size: f.size,
        }) as any
    );
    return axios.post('/files/filesCreate', { files, folderId, groupId });
  }
  async renameFolder(folderId: number, name: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/folders/editInfo/${folderId}`, { name });
  }
  async deleteFolder(folderId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/folders/delete/${folderId}`);
  }
  async renameFile(
    fileId: number,
    name: string,
    description: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/editFileInfo/${fileId}`, { name, description });
  }
  async getHistory(id: number): Promise<ResponseDocsHistoryModel | ResponseErrorModel> {
    return axios.get(`/files/history/${id}`);
  }
  async getHistoricalFileById(id: number): Promise<ResponseDocsHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/files/historyById/${id}`);
  }
  async getHistoricalFileByDate(id: number, date: string): Promise<ResponseDocsHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/files/historyByDate/${id}?date=${date}`);
  }
  async restoreFileVersion(fileId: number, historyId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/rollback/${fileId}?historyId=${historyId}`);
  }
  async uploadNewVersion(fileId: number, fileInfo: FileModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/uploadNewVersion/${fileId}`, {
      key: fileInfo.key,
      name: fileInfo.name,
      type: fileInfo.type,
      size: fileInfo.size,
    });
  }
  async moveFile(
    toFolderId: number | null,
    toGroupId: number | null,
    fileId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/files/move', { toFolderId, toGroupId, fileId });
  }
  async moveFolder(
    toFolderId: number | null,
    toGroupId: number | null,
    folderId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/folders/move', { toFolderId, toGroupId, folderId });
  }
  async moveWiki(
    toFolderId: number | null,
    toGroupId: number | null,
    wikiId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/wiki/move', { toFolderId, toGroupId, wikiId });
  }
  async getRelations(id: number): Promise<ResponseDocsRelationsFileModel | ResponseErrorModel> {
    return axios.get(`/files/relations/${id}`);
  }
  async addRelation(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/addRelation/${id}`, { id });
  }
  async removeRelation(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/removeRelation/${id}`);
  }
  async getTags(id: number): Promise<ResponseDocsFileTagsModel | ResponseErrorModel> {
    return axios.get(`/files/${id}/tags`);
  }
  async addTag(id: number, tagTexts: string[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/files/addTag', { id, tagTexts });
  }
  async removeTag(id: number, tagId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/removeTag/${id}?tagId=${tagId}`);
  }
  async markAsOfficial(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/${id}/official`);
  }
  async getFollowers(id: number): Promise<ResponseDocsFileFollowersModel | ResponseErrorModel> {
    return axios.get(`/files/followers/${id}`);
  }
  async follow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/${id}/follow`);
  }
  async unfollow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/${id}/unfollow`);
  }
  async shareFile(fileId: number, text: string): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/files/shareFile', { id: fileId, text });
  }
  async shareFileToGroup(
    fileId: number,
    text: string,
    groupId: number
  ): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/files/shareFile', { id: fileId, groupId, text });
  }
  async shareDocumentArchiveLink(
    id: number,
    emails: string[],
    type: ShareArchiveLinkType
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/sendArchive/`, {
      id,
      emails,
      type,
    });
  }
  async getFolderPath(folderId: number): Promise<ResponseTreeFolderModel | ResponseErrorModel> {
    return axios.get(`/folders/path/${folderId}`);
  }
}

<template>
  <div class="documents">
    <div v-for="item in combineAttachments" :key="item.data.id" class="document-item">
      <docs-item
        :doc="item"
        :view-mode="DataViewMode.List"
        :file-menu-is-disabled="true"
        @onQuickAction="onQuickAction"
      />
      <ion-button
        v-if="withControls"
        class="document-delete-btn"
        color="dark"
        fill="clear"
        @click="removeConfirm(item.data.id)"
      >
        <ion-icon slot="icon-only" :icon="icons.delete" color="danger" />
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonIcon, alertController } from '@ionic/vue';
import { closeOutline, pencilOutline, arrowBackOutline, trashOutline, checkmarkOutline } from 'ionicons/icons';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { DocsItem } from '@/components';
import { DataViewMode, DocumentTypeEnum } from '@/enums';
import { useDocs } from '@/helpers';
import { useI18n } from '@/i18n';

import { useProjectsStore } from '@/store';
import type { TaskManagementTaskModel, DocModel } from '@/types';

const props = defineProps({
  taskId: {
    type: Number,
    required: true,
  },
  withControls: {
    type: Boolean,
    required: true,
  },
});
const icons = {
  close: closeOutline,
  pencil: pencilOutline,
  arrow: arrowBackOutline,
  delete: trashOutline,
  checkmark: checkmarkOutline,
};

const { t } = useI18n();
const projectsStore = useProjectsStore();
const useDocsHelper = useDocs();

const taskData: ComputedRef<TaskManagementTaskModel | undefined> = computed(() =>
  projectsStore.getTaskById(props.taskId)
);

const combineAttachments: ComputedRef<DocModel[]> = computed(() => {
  if (taskData.value) {
    return [
      ...taskData.value.files.data.map((file) => ({
        documentType: DocumentTypeEnum.File,
        data: file,
      })),
      ...taskData.value.wikis.data.map((wiki) => ({
        documentType: DocumentTypeEnum.Wiki,
        data: wiki,
      })),
    ];
  } else {
    return [];
  }
});

const removeConfirm = async (fileId: number) => {
  const alert = await alertController.create({
    message: t('files.deleteConfirm'),
    buttons: [
      {
        text: t('no'),
        role: 'cancel',
        cssClass: 'custom-alert-buttons',
      },
      {
        text: t('yes'),
        cssClass: 'custom-alert-buttons',
        handler: async () => {
          await removeFile(fileId);
        },
      },
    ],
  });

  await alert.present();
};

const removeFile = async (fileId: number) => {
  await projectsStore.taskRemoveFiles(props.taskId, [fileId]);
};

const onQuickAction = async (document: DocModel) => {
  await useDocsHelper.documentQuickAction(document, null);
};
</script>

<style scoped lang="scss">
.documents {
  max-height: 45vh;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0;
  }

  .document-item {
    display: flex;
    .document-delete-btn {
      position: relative;
      margin: 0;
      --border-radius: 0;
      height: unset;
      min-width: 54px;
      ion-icon {
        font-size: 1.2rem;
      }
    }
  }
}
</style>

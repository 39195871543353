import { AxiosError } from 'axios';

import { useErrors, useSession } from '@/helpers';
import router, { ROUTES_NAME } from '@/router';

/**
 * Aborts the requests
 */
export function handleAbort(): AbortSignal {
  const controller = new AbortController();
  controller.abort();
  return controller.signal;
}

/**
 *  Handles the session termination
 *
 *  @param error The error object
 *  @param message The message to display
 *  @param silent If true, the error will handled without showing the toast
 *
 *  Calls the handleAbort function to abort the requests
 *  @see src/services/axios.ts - handleAbort
 *
 *  Calls the logOut function to log out the user
 *  @see src/helpers/useUserFlowHelper.ts - logOut
 *
 *  Calls the router.replace function to redirect the user to the login page
 *  Calls the handleError function to handle the error
 *  @see src/helpers/useErrorsHelper.ts - handleError
 */
export async function handleSessionTerminate(
  error: AxiosError,
  message = 'Session terminated',
  silent = false
): Promise<void> {
  handleAbort();
  await Promise.all([useSession().logOut(), router.replace({ name: ROUTES_NAME.LOGIN })]);
  useErrors().handleError({
    show: !silent,
    error,
    message,
  });
}

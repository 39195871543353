import { alertController } from '@ionic/vue';
import { Sentry, isSentryInitialized } from '@/plugins/sentry';
import { PagesBackgroundEnum, PostsModeEnum, RequirementsEnum } from '@/enums';
import {
  isNativeMobile,
  useWebSockets,
  useTheme,
  HomePageHelper,
  openNetworkChooseModal,
  updateApp,
  changeFavicon,
  ColorGenerator,
  useRequirements,
  useAiAssistant,
  useImages,
  useNotifications,
  isPushInitialized,
} from '@/helpers';
import { loadLocaleMessages } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import type { AppState } from '@/store';
import {
  useAuthStore,
  useAppStore,
  useMessengerStore,
  useNetworkStore,
  useGroupsStore,
  useUserStore,
  useNotificationsStore,
  usePostStore,
  useMenuStore,
  resetAllStores,
  useAiAssistantStore,
  useBadgesStore,
} from '@/store';
import type { AuthFormModel, NetworkModel, UserCurrentModel } from '@/types';
import { isRedirect } from '@/services/checkers';
import { markEnd, markStart } from '@/helpers/performance';

type IUseSession = {
  /**
   * The current user of the app
   *
   * @see src/plugins/sentry.ts
   * @readonly
   */
  localUser: UserCurrentModel | null;
  /**
   * General setup function for the app
   *
   * @summary Setup network, user, profile settings, locales, check required conditions, to app + init WebSockets and/or Push Notifications.
   * Accepts newWebSocket and newPush as optional parameters for _checkRequiredConditions => _toApp.

   * @param newWebSocket boolean
   * @description If newWebSocket is false, then it will NOT re-initialize WebSockets connection.
   * @default true
   * @see src/helpers/useWebSockets.ts

   * @param newPush boolean
   * @description If newPush is false, then it will NOT re-initialize Push Notifications. It will hoverer if isPushInitialized is false.
   * @default true
   * @see src/helpers/useNotificationsHelper.ts
   * 
   * @param cold boolean
   * @description If cold is true, then it will NOT perform requirements check.
   * @default false
   */
  setupApp: (newWebSocket?: boolean, newPush?: boolean, cold?: boolean) => Promise<void>;
  /**
   * Sets the network for the user
   *
   * @param network
   * @param data
   * @returns
   */
  setNetwork: (network: NetworkModel, data?: AuthFormModel) => Promise<void>;
  /**
   * Changes the network for the user.
   * Can handle both single NetworkModel and NetworkModel[]:
   * - If single NetworkModel - `setNetwork()` is called with it
   * - If NetworkModel[] - modal is opened to select a network and `setNetwork()` is called with it
   */
  changeNetwork: (userNetworks: NetworkModel[], data?: AuthFormModel) => Promise<void>;
  /**
   * Logs out the user from the application:
   *  - Stops WebSockets
   *  - resets badge count
   *  - deletes ai assistant
   *  - cancels push notifications
   *  - clears custom menu timer
   *  - resets all stores
   *  - changes favicon
   *  - clears theme
   *  - clears images cache
   */
  logOut: () => Promise<void>;
  /**
   * Sets the custom menu timer
   *
   * @deprecated
   * @todo Refactor
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2052
   */
  setCustomMenuTimer: () => void;
  /**
   * Clears the custom menu timer
   *
   * @deprecated
   * @todo Refactor
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2052
   */
  clearCustomMenuTimer: () => void;

  /** Use it for check Registration is enabled
   *
   * @readonly
   */
  readonly registrationEnabled: boolean;
};

export function useSession(): IUseSession {
  //#region Public variables
  let localUser: UserCurrentModel | null = null;
  const registrationEnabled = import.meta.env.VITE_ENABLE_REGISTRATION === 'true';
  //#endregion

  //#region Private methods
  async function _setupNetwork(): Promise<boolean> {
    if (!useNetworkStore().network.id) {
      await useNetworkStore().networkForUser(); //?: Are responses different?
    }

    if (useNetworkStore().data.length <= 1) {
      await useNetworkStore().networksByUser(); //?: Are responses different?
    }

    const settings = await useNetworkStore().getSettings();

    if (!settings) {
      console.warn('[WARN] Failed to get settings');
      return false;
    }

    const color = useTheme().getAppColor(settings?.headBackgroundColor);
    await useTheme().setTheme(color);
    changeFavicon();
    return true;
  }

  async function _setupUser(): Promise<UserCurrentModel | null> {
    localUser = await useUserStore().currentUser();

    if (!localUser) {
      return null;
    }

    useAuthStore().setUserId(localUser.id);

    return localUser;
  }

  async function _handleSetupAppError(): Promise<void> {
    useAppStore().setIsLoading(false);

    await router?.replace(ROUTES_NAME.LOGIN);
    const networkErrors = useNetworkStore().getErrors('default');
    const userErrors = useUserStore().getErrors('default');
    const errors = userErrors.length && !networkErrors.length ? userErrors : networkErrors;

    isSentryInitialized() && Sentry?.setUser(null);

    const alert = await alertController.create({
      message: errors.join(' '),
    });
    await alert.present();
  }

  function _setupProfileSettings(localUser: UserCurrentModel): void {
    useAppStore().$patch((state: AppState) => {
      state.isLoading = true;

      state.theme = Object.hasOwn(localUser, 'themeStyle') ? localUser.themeStyle : state.theme;

      state.pagesBackground = Object.hasOwn(localUser, 'pagesBackground')
        ? localUser.pagesBackground
          ? PagesBackgroundEnum.CustomImage
          : PagesBackgroundEnum.Default
        : state.pagesBackground;

      state.locale = Object.hasOwn(localUser, 'language') ? localUser.language : state.locale;

      state.sendKey = Object.hasOwn(localUser, 'sendMode') ? localUser.sendMode : state.sendKey;

      state.localNotifications = Object.hasOwn(localUser, 'sound') ? localUser.sound : state.localNotifications;

      state.canPostOnBehalfMe = Object.hasOwn(localUser, 'canPostOnBehalfMe')
        ? localUser.canPostOnBehalfMe
        : state.canPostOnBehalfMe;
    });

    useAppStore().setTheme(useAppStore().theme);
  }

  async function _setupLocales(): Promise<void> {
    const companyResources = await useAppStore().getCompanyResources();
    const currentNetworkId = useNetworkStore().getNetwork.id;
    await loadLocaleMessages(useAppStore().locale, companyResources || {}, currentNetworkId);
  }

  async function _toApp(newWebSocket = true, newPush = true): Promise<void> {
    const route = router.currentRoute.value;
    if (!localUser) {
      await router?.push(ROUTES_NAME.LOGIN);
      return;
    }

    if (newWebSocket) {
      await useWebSockets().initWebSockets(useAuthStore().getWebSocketModel);
    }

    if (newPush || !isPushInitialized()) {
      await useNotifications().initPushNotifications();
    }

    useUserStore().$reset();
    useUserStore().setCurrentUser(localUser);
    useAppStore().setFeedType(localUser.defaultFeedType);

    /**
     * @note If initialization was caused by mobile local/push notification handling
     * we need to ignore following routing logic since final route is now dictated by push notification type/id
     */
    if (!useAppStore().fromMobilePush) {
      /** @note If the user was targeting specific page, we need to redirect back to it */
      const url = useAuthStore().redirectUrl;
      if (url) {
        await router.push(url);
        useAuthStore().setRedirectUrl(null);
        return;
      }

      /** @note If the user was not targeting specific page - default behavior */
      await HomePageHelper.setHomePage();
      if (route?.name === ROUTES_NAME.LOGIN || route?.name === ROUTES_NAME.HOME) {
        await router?.replace(useAppStore().homePage);
      } else {
        if (HomePageHelper.getHomePage()?.name === ROUTES_NAME.FEED) {
          await router?.replace({ name: ROUTES_NAME.FEED });
          usePostStore().setPostsMode(PostsModeEnum.Feed);
          usePostStore().setPostsGroupId(null);
          usePostStore().setPostsUserId(null);
        } else {
          await router?.replace(useAppStore().homePage);
        }
      }
    }

    if (isNativeMobile) {
      await updateApp();
    }

    await useMessengerStore().getChains();
    for (let i = 0; i < 10 && useMessengerStore().hasLoadMoreUrl; i++) {
      const url = useMessengerStore().hasLoadMoreUrl;
      if (url) await useMessengerStore().loadMore(url);
    }
  }

  async function _checkRequiredConditions(): Promise<void> {
    const result = await useRequirements().check(RequirementsEnum.All);
    if (result) {
      useAppStore().setIsLoading(false);
    }
  }

  /**
   * Redirects to custom domain from NetworkModel
   *
   *
   * @note After redirection, app will catch `/auth` in
   * @see src/router/index.ts - `_handleAuth()` (line 506)
   */
  async function _redirectToCustomDomain(network: NetworkModel): Promise<void> {
    window.location.replace(network.redirectUri);
  }
  //#endregion

  //#region Public methods
  async function setupApp(newWebSocket?: boolean, newPush?: boolean, cold?: boolean): Promise<void> {
    markStart('setupApp');
    clearCustomMenuTimer();

    resetAllStores(false);

    ColorGenerator.generateRandomShades();

    try {
      const _result = await _setupNetwork();
      if (!_result) throw new Error('Network error');

      localUser = await _setupUser();
      if (!localUser) throw new Error('Current user error');
    } catch (e) {
      console.error('[ERROR] Failed to setupApp', e);
      await _handleSetupAppError();
      return;
    }

    if (isSentryInitialized() && Sentry) {
      Sentry.setUser({
        email: localUser?.email,
        id: localUser?.id.toString(),
        username: localUser?.fullName,
      });
    }

    _setupProfileSettings(localUser);

    // await _setupLocales();
    // await _checkRequiredConditions(newWebSocket, newPush);
    // await useNotificationsStore().notifications();
    // await useGroupsStore().canPostGroups();
    // await useMenuStore().getMenu();
    // await useGroupsStore().myGroups();

    /**
     * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled#description
     */
    try {
      await Promise.allSettled([
        _setupLocales(),
        !cold && _checkRequiredConditions(),
        _toApp(newWebSocket, newPush),
        useNotificationsStore().notifications(),
        useGroupsStore().canPostGroups(),
        useMenuStore().getMenu(),
        useGroupsStore().myGroups(),
        !useBadgesStore().data.length && useBadgesStore().badgesAll(),
      ]);
    } catch (e: any) {
      console.error('[ERROR] Error during setupApp', e);
    }

    setCustomMenuTimer();
    markEnd('setupApp');
  }

  async function setNetwork(network: NetworkModel, userData?: AuthFormModel): Promise<void> {
    if (isRedirect(network)) {
      _redirectToCustomDomain(network);
      return;
    }

    // Only setting host if NOT in redirect mode
    // to leave current state clean
    // to be able to go back to it and use with correct host
    useAuthStore().setHost(network.host);

    // Setting network
    useNetworkStore().setSettingNetwork(true);
    useAuthStore().setNetwork(network.id, network.webSocket, true);

    if (userData && !useAuthStore().isAuth()) {
      await useAuthStore().homeCode(userData);
    }

    await useAuthStore().token({ force: true });

    if (!useAuthStore().isAuth()) {
      console.warn('[WARN] User is not authenticated');
      await logOut();
      return;
    }

    useNetworkStore().setNetwork(network);
    useNetworkStore().setSettingNetwork(false);

    // Setup app
    useAppStore().setIsLoading(true);
    await setupApp();
    useAppStore().setIsLoading(false);
  }

  async function changeNetwork(userNetworks: NetworkModel[], data?: AuthFormModel): Promise<void> {
    if (!userNetworks.length) {
      console.warn('[WARN] No network found');
      return;
    }

    if (userNetworks.length === 1) {
      await setNetwork(userNetworks[0], data);
      return;
    }

    const result = await openNetworkChooseModal(false);
    if (!result) {
      console.warn('[WARN] No network selected');
      return;
    }

    await setNetwork(result, data);
  }

  async function logOut(): Promise<void> {
    try {
      await useWebSockets().stopWebSockets();

      await useNotifications().resetBadgeCount();

      const currentAssistant = useAiAssistantStore().assistant;
      if (currentAssistant) {
        await useAiAssistant().deleteAssistant(currentAssistant);
      }

      if (isNativeMobile && useAuthStore().isAuth()) {
        await useNotifications().cancelPushNotifications();
      }

      clearCustomMenuTimer();

      resetAllStores(true);

      changeFavicon();

      await useTheme().clearTheme();

      useImages().clearImages();
    } catch (e) {
      console.error(e);
    }
  }

  function setCustomMenuTimer() {
    const customMenuTimerId = setInterval(
      async () => {
        await useMenuStore().getMenu();
      },
      15 * 60 * 1000
    );
    useMenuStore().setTimer(customMenuTimerId);
  }

  function clearCustomMenuTimer() {
    const customMenuTimerId = useMenuStore().timer;
    if (customMenuTimerId) {
      clearInterval(customMenuTimerId);
    }
    useMenuStore().setTimer(null);
  }
  //#endregion

  return {
    get localUser(): UserCurrentModel | null {
      return localUser;
    },
    setupApp,
    setNetwork,
    changeNetwork,
    logOut,
    clearCustomMenuTimer,
    setCustomMenuTimer,
    registrationEnabled,
  };
}
